import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { ErrorBoundary } from "./components/ErrorPage";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";
import { Amplify } from "aws-amplify";

import Layout from "./pages/layout/Layout";
import Bella from "./pages/Bella/Bella";

const userPoolId = import.meta.env.VITE_COGNITO_USER_POOL_ID;
const userPoolClientId = import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID;

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: userPoolId,
      userPoolClientId: userPoolClientId,
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code", // 'code' | 'link'
    },
  },
});

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#5457fc",
    },
    secondary: {
      main: "#9c27b0",
    },
  },
  typography: {
    fontFamily: "Ubuntu",
    h1: {
      fontFamily: "Comfortaa",
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.8rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.3rem",
    },
    h5: {
      fontSize: "1.2rem",
    },
    h6: {
      fontSize: "1.1rem",
    },
    body1: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "1.1rem",
    },
    subtitle2: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.8rem",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Customize the border radius here
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Apply border radius to the root element
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 8, // Apply border radius to the outline
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Apply border radius to the input element
        },
      },
    },
  },
});

const router = createHashRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <Bella />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </ThemeProvider>,
);
