import React from "react";
import { Slide, Stack, Chip, Tooltip } from "@mui/material";

export const ErrorChip: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <Slide in direction="down">
      <Tooltip title={error.message}>
        <Chip label="Something went wrong..." color="warning" />
      </Tooltip>
    </Slide>
  );
};

interface ErrorBoundaryState {
  errors: {
    error?: Error; // Store the error object
    errorInfo?: React.ErrorInfo; // Store additional error info
  }[];
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { errors: [], hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log("ErrorBoundary.getDerivedStateFromError", error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      errors: [...this.state.errors, { error, errorInfo }],
      hasError: true,
    });
    console.error("Caught an error:", error, errorInfo);
    setTimeout(() => {
      const newErrors = this.state.errors.slice(1);
      this.setState({
        errors: newErrors,
        hasError: newErrors.length > 0,
      });
    }, 3000);
  }

  render() {
    return (
      <>
        {this.state.hasError && (
          <Stack
            spacing={1}
            sx={{
              position: "absolute",
              left: "50%",
              top: "10px", // "20px" is a string, not a number
              transform: "translateX(-50%)",
              zIndex: 1000,
              display: "flex",
              width: "fit-content",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {this.state.errors.map(({ error }, index) => (
              <ErrorChip key={index} error={error} />
            ))}
          </Stack>
        )}
        {this.props.children}
      </>
    );
  }
}
