import React from "react";
import "./Support.module.scss";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";

const videoList = [
  {
    url: "https://www.loom.com/embed/1fbd698584d04d308e5b6596631ae244?sid=1bd92128-9239-4ef3-8349-9157ffae019a",
    title: "Creating Personalized Campaigns in Bella",
  },
];

const VideoTutorials: React.FC = () => {
  return (
    <Card sx={{ maxHeight: "80vh", overflow: "auto" }}>
      <CardHeader title="Please check the videos below for help. If you can't find what you're looking for, please submit an email inquiry through the 'Contact Us' above." />
      <Divider />
      <CardContent>
        <Box sx={{ padding: 3 }}>
          <Typography variant="h5" gutterBottom>
            Video Tutorials
          </Typography>
          <Grid container spacing={3}>
            {videoList.map((video, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                  }}
                >
                  <iframe
                    src={video.url}
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    title={video.title}
                  ></iframe>
                </Box>
                <Typography variant="h6" align="center">
                  {video.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VideoTutorials;
