import { useState } from "react";
import { callAPI } from "../../api";

import { Button, CircularProgress } from "@mui/material";

export const AuthButton = ({ provider, authorizedWith }) => {
  const [loading, setLoading] = useState(false);
  const handleAuthClick = async () => {
    setLoading(true);

    try {
      // Call the backend endpoint to initiate the authorization process
      const response = await callAPI("GET", "authorize", {
        credentials: "include",
        provider: provider, // salesforce or hubspot
      });
      // Open the authorization URL in a new tab
      const authWindow = window.open(response.authUrl, "_blank");

      // Focus the new tab if it's successfully opened
      if (authWindow) {
        authWindow.focus();
      }
    } catch (error) {
      console.error("Error during authorization:", error);
    } finally {
      setLoading(false);
    }
  };
  return loading ? (
    <CircularProgress />
  ) : (
    <Button onClick={handleAuthClick} variant="contained">
      {authorizedWith === provider ? `Refresh Authorization` : "Authorize"}
    </Button>
  );
};
