import { useState } from "react";
import { callExtension } from "../api";
import { useUser } from "../contexts/UserContext";
import {
  Box,
  Modal,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const scanMyLinkedInData = async (setUpdate) => {
  setUpdate("Scanning LinkedIn profile...");
  const data = await callExtension("LISN_fetchLists");
  const myUrn = data[0].creator;
  console.log("myUrn: ", myUrn);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const profile: any = await callExtension("LISN_fetchLead", {
    entityUrn: myUrn,
  });
  setUpdate(`Scanning activities...`);
  const activities = await callExtension("LISN_fetchLeadActivities", {
    entityUrn: myUrn,
  });
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    title: profile.companies.find(
      (c) => c.objectUrn === profile.defaultCompanyUrn,
    ).title,
    linkedinObjectUrn: profile.objectUrn,
    linkedin: {
      ...profile,
      activities: activities,
    },
  };
};

const scanMyCompanyLinkedInData = async (
  setUpdate,
  companyName,
  companyUrn,
) => {
  setUpdate(`Scanning ${companyName}...`);
  const companyData = await callExtension("LISN_fetchCompany", {
    entityUrn: companyUrn,
  });
  setUpdate(`Scanning company activities...`);
  const companyActivitiesData = await callExtension(
    "LISN_fetchCompanyActivities",
    { entityUrn: companyUrn },
  );
  setUpdate(`Scanning company job postings...`);
  const companyJobsData = await callExtension("LI_fetchCompanyJobs", {
    entityUrn: companyUrn,
  });
  return {
    name: companyData.name,
    website: companyData.website,
    location: companyData.location,
    linkedinObjectUrn: companyData.objectUrn,
    linkedin: {
      ...companyData,
      activities: companyActivitiesData,
      jobPostings: companyJobsData,
    },
  };
};

export const ProfileScanLinkedInModal = (props: {
  showModal;
  setShowModal;
}) => {
  const { updateUser } = useUser();
  const [userData, setUserData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [myCompanies, setMyCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{
    name: string;
    objectUrn: string;
  }>(null);

  const finish = async (userData, companyData) => {
    await updateUser({
      ...userData,
      company: companyData,
    });
    setUpdate(null);
    props.setShowModal(false);
  };

  const continueScanning = async () => {
    setLoading(true);
    const companyData = await scanMyCompanyLinkedInData(
      setUpdate,
      selectedCompany.name,
      selectedCompany.objectUrn,
    );
    setUpdate("Loading to Bella...");
    await finish(userData, companyData);
  };

  const scanLinkedIn = async () => {
    try {
      setLoading(true);
      const userData = await scanMyLinkedInData(setUpdate);
      setUserData(userData);
      const companies = userData.linkedin.companies.filter(
        (c) => !c.endDate && c.objectUrn,
      );
      if (companies.length > 1) {
        setMyCompanies(companies);
        setLoading(false);
      } else {
        setSelectedCompany(companies[0]);
        const companyData = await scanMyCompanyLinkedInData(
          setUpdate,
          companies[0].name,
          companies[0].objectUrn,
        );
        await finish(userData, companyData);
      }
    } catch (error) {
      console.log(error);
      setUpdate(error.message);
      setInterval(() => {
        setUpdate(null);
        props.setShowModal(false);
      }, 3000);
    }
  };

  // useEffect(() => {
  //   scanLinkedIn();
  // }, [props, scanMyLinkedInData, scanMyCompanyLinkedInData, updateUser]);

  return (
    <Modal
      open={props.showModal}
      onClose={() => {}}
      aria-labelledby="linkedin-load-modal-title"
      aria-describedby="linkedin-load-modal-description"
    >
      {myCompanies.length > 1 && !loading ? (
        <Box
          sx={{
            ...modalStyle,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            id="linkedin-load-modal-title"
            variant="h6"
            component="h2"
          >
            Found more than current company for your profile. Please select one.
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="company-label">Select Company</InputLabel>
            <Select
              labelId="company-label"
              id="company-select"
              label="Select Company"
              fullWidth
              value={selectedCompany?.name || ""}
              onChange={(e) =>
                setSelectedCompany(
                  myCompanies.find((s) => s.name === e.target.value),
                )
              }
            >
              {myCompanies.map((s) => {
                return (
                  <MenuItem key={s.objectUrn} value={s.name}>
                    {s.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Button variant="contained" onClick={() => continueScanning()}>
              Proceed
            </Button>
          </FormControl>
        </Box>
      ) : (
        <Box
          sx={{
            ...modalStyle,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            id="linkedin-load-modal-title"
            variant="h6"
            component="h2"
          >
            Scanning your LinkedIn
          </Typography>
          <Button onClick={() => scanLinkedIn()} disabled={loading}>
            Scan LinkedIn
          </Button>
          <Typography id="linkedin-load-modal-description" sx={{ mt: 2 }}>
            {update}
          </Typography>
        </Box>
      )}
    </Modal>
  );
};
