import { useCallback, useState, forwardRef, useRef, useEffect } from "react";
import { useThread } from "../../contexts/ThreadContext";
import { useUser } from "../../contexts/UserContext";

import {
  Box,
  Switch,
  CircularProgress,
  Button,
  ButtonGroup,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";

import { Biotech, AutoFixHigh, Send } from "@mui/icons-material";

import { MessagesNav } from "./MessagesNav";
import { AiAssistantDialog } from "./AiAssistant";
import styles from "./ThreadCard.module.scss";

export const Footer = () => {
  const {
    threadData,
    lead,
    currentMessage,
    // generateMessage,
    // sendCR,
    crSent,
    // sendMessage,
    generateMsg,
    sendMsg,
    // sendEmail,
    research,
    currentSteps,
    // crAccepted,
    // updateCurrentSteps,
  } = useThread();
  const { userData } = useUser();

  // const [loading, setLoading] = useState(false);

  const [showAI, setShowAI] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ActionButton = forwardRef((props: any, ref) => {
    const [btnLabel, setLabel] = useState<string>(props.label);
    const [loading, setLoading] = useState(false);
    const originalLabelRef = useRef(props.label);
    const confirmTimeoutRef = useRef<number | null>(null); // For storing the timeout

    // const confirm = () => {
    //   setLabel("Confirm");
    //   setTimeout(() => {
    //     setLabel(btnLabel);
    //   }, 3000);
    // };

    const confirm = () => {
      setLabel("Confirm");
      // Reset label back to original after 3 seconds if no further action
      confirmTimeoutRef.current = window.setTimeout(() => {
        setLabel(originalLabelRef.current);
        confirmTimeoutRef.current = null; // Reset the timeout reference
      }, 2500);
    };

    // const handleOnClick = async () => {
    //   if (btnLabel.startsWith("Send")) {
    //     confirm();
    //   } else {
    //     setLoading(true);
    //     await props.onClick();
    //     setLoading(false);
    //   }
    // };

    const handleOnClick = async () => {
      if (btnLabel === "Confirm") {
        if (confirmTimeoutRef.current) {
          clearTimeout(confirmTimeoutRef.current);
          confirmTimeoutRef.current = null;
        }
        setLoading(true);
        await props.onClick();
        setTimeout(() => {
          setLoading(false);
          setLabel(originalLabelRef.current); // Reset label after loading
        }, 3000);
      } else if (btnLabel.startsWith("Send")) {
        confirm();
      } else {
        setLoading(true);
        await props.onClick();
        setLoading(false);
      }
    };

    useEffect(() => {
      return () => {
        if (confirmTimeoutRef.current) {
          clearTimeout(confirmTimeoutRef.current);
        }
      };
    }, []);

    // const actButton = useCallback(
    //   () => (
    //     <Button
    //       color={btnLabel === "Confirm" ? "warning" : "primary"}
    //       size="small"
    //       id="threadActionButton"
    //       {...props}
    //       onClick={handleOnClick} // Important to keep it under {...props} to override onClick call with handleOnClick
    //     >
    //       {btnLabel}
    //     </Button>
    //   ),
    //   [btnLabel, props],
    // );

    const actButton = useCallback(
      () => (
        <Button
          color={btnLabel === "Confirm" ? "warning" : "primary"}
          size="small"
          id="threadActionButton"
          {...props}
          onClick={handleOnClick} // Important to keep it under {...props} to override onClick call with handleOnClick
          disabled={loading}
          startIcon={loading ? <CircularProgress size={14} /> : props.startIcon}
        >
          {btnLabel}
        </Button>
      ),
      [btnLabel, props, loading],
    );

    if (props.tooltip) {
      return (
        <Tooltip title={props.tooltip} ref={ref}>
          <span>{actButton()}</span>
        </Tooltip>
      );
    }
    return actButton();
  });

  console.log("currentSteps: ", currentSteps);
  const stepsInProgress = currentSteps.find(
    (s) => s.nodeType === "stepsInProgress",
  );
  console.log("stepsInProgress: ", stepsInProgress);
  const researchStep = currentSteps.find((s) => s.nodeType === "research");
  const crStep = currentSteps.find((s) => s.messageType === "cr");
  const linkedinOutreachStep = currentSteps.find(
    (s) => s.messageType === "outreach" && s.channel === "linkedin",
  );
  console.log("linkedinOutreachStep: ", linkedinOutreachStep);
  const emailOutreachStep = currentSteps.find(
    (s) => s.messageType === "outreach" && s.channel === "email",
  );
  console.log("emailOutreachStep: ", emailOutreachStep);

  // const researchStep = currentSteps.find((s) => s.nodeType === "research");
  // const crStep = currentSteps.find((s) => s.messageType === "cr");
  // const linkedinOutreachStep = currentSteps.find(
  //   (s) => s.messageType === "outreach" && s.channel === "linkedin",
  // );
  // const emailOutreachStep = currentSteps.find(
  //   (s) => s.messageType === "outreach" && s.channel === "email",
  // );

  // const steps = Array.isArray(currentSteps) ? currentSteps : [];
  // const researchStep = steps.find((s) => s.nodeType === "research");
  // const crStep = steps.find((s) => s.messageType === "cr");
  // const linkedinOutreachStep = steps.find(
  //   (s) => s.messageType === "outreach" && s.channel === "linkedin",
  // );
  // const emailOutreachStep = steps.find(
  //   (s) => s.messageType === "outreach" && s.channel === "email",
  // );

  const renderLinkedInActionButtons = () => {
    if (stepsInProgress) {
      return (
        <Box className={styles.sequenceInProgressText}>
          Sequence Steps in Progress...
        </Box>
      );
    }

    if (researchStep) {
      return (
        <ActionButton
          label="Research"
          variant="outlined"
          startIcon={<Biotech />}
          onClick={() => research(researchStep.pid)}
          key={"research-action"}
        />
      );
    }
    const actions = [];
    if (crStep && !crSent && lead.degree > 1) {
      actions.push(
        <ActionButton
          label="Generate CR"
          variant="outlined"
          startIcon={<AutoFixHigh />}
          // onClick={() => generateMessage("cr", "linkedin", crStep.pid)}
          onClick={() => generateMsg(crStep.pid)}
          key={"generate-cr-action"}
        />,
      );
      if (
        currentMessage?.type?.get() === "cr" &&
        currentMessage?.status?.get() === "created"
      ) {
        actions.push(
          <ActionButton
            label="Send CR"
            variant="contained"
            endIcon={<Send />}
            // onClick={() => sendCR()}

            onClick={() => sendMsg(crStep.pid)}
            key={"send-cr-action"}
          />,
        );
      }
    }
    // if (crAccepted && !linkedinOutreachStep) {
    //   actions.push(
    //     <ActionButton
    //       label="Update Action Buttons"
    //       variant="contained"
    //       startIcon={<AutoFixHigh />}
    //       onClick={() => updateCurrentSteps()}
    //       key={"update-cr-action"}
    //       tooltip="Use this button if CR has been accepted but linkedin Outreach Button has not yet appeared, and the time delay from the sequence has not elapsed."
    //     />,
    //   );
    // }
    if (linkedinOutreachStep) {
      actions.push(
        <ActionButton
          label={`Generate LinkedIn ${lead.degree > 1 ? "InMail" : "Outreach"}`}
          variant="outlined"
          startIcon={<AutoFixHigh />}
          onClick={() => generateMsg(linkedinOutreachStep.pid)}
          key={"generate-linkedin-outreach-action"}
        />,
      );
      if (
        currentMessage?.type?.get() === "outreach" &&
        currentMessage?.status?.get() === "created" &&
        currentMessage?.channel?.get() === "linkedin"
      ) {
        actions.push(
          <ActionButton
            label={`Send LinkedIn ${lead.degree > 1 ? "InMail" : "Outreach"}`}
            variant="contained"
            endIcon={<Send />}
            // onClick={() => sendMessage(linkedinOutreachStep.pid)}
            onClick={() => sendMsg(linkedinOutreachStep.pid)}
            key={"send-linkedin-outreach-action"}
          />,
        );
      }
    }
    return <ButtonGroup>{actions}</ButtonGroup>;
  };

  const renderEmailActionButtons = () => {
    const actions = [];
    if (emailOutreachStep) {
      actions.push(
        <ActionButton
          label="Generate Email Outreach"
          variant="outlined"
          startIcon={<AutoFixHigh />}
          // onClick={() =>
          //   generateMessage("outreach", "email", emailOutreachStep.pid)
          // }
          onClick={() => generateMsg(emailOutreachStep.pid)}
          tooltip={
            !userData.emailProvider
              ? "Please connect to your email provider first"
              : "Generate Email"
          }
          disabled={!userData.emailProvider}
          key={"generate-email-outreach-action"}
        />,
      );
      if (
        currentMessage?.type?.get() === "outreach" &&
        currentMessage?.status?.get() === "created" &&
        currentMessage?.channel?.get() === "email"
      ) {
        actions.push(
          <ActionButton
            label="Send Email Outreach"
            variant="contained"
            endIcon={<Send />}
            // onClick={() => sendEmail(emailOutreachStep.pid)}
            onClick={() => sendMsg(emailOutreachStep.pid)}
            tooltip={
              !lead.email
                ? "No email found"
                : !userData.emailProvider
                  ? "Please connect to your email provider first"
                  : "Send email"
            }
            disabled={!lead.email || !userData.emailProvider}
            key={"send-email-outreach-action"}
          />,
        );
      }
    }
    return <ButtonGroup>{actions}</ButtonGroup>;
  };
  // const renderCallActionButtons = () => {
  //   if (
  //     threadState === "research" ||
  //     !campaignData.sequence.find(
  //       (s) => s.step === "coldCallScript" && !s.template.startsWith("SKIP"),
  //     )
  //   )
  //     return;
  //   const actions = [];
  //   actions.push(
  //     <ActionButton
  //       label="Generate Cold Call Script"
  //       variant="outlined"
  //       startIcon={<AutoFixHigh />}
  //       onClick={() => generateMessage("coldCallScript", "call")}
  //       key={"generate-cold-call-action"}
  //     />,
  //   );
  //   if (
  //     thread.messages[msgIndex]?.type === "coldCallScript" &&
  //     thread.messages[msgIndex]?.status === "created"
  //   ) {
  //     actions.push(
  //       <ActionButton
  //         label="Archive Cold Call Script"
  //         variant="contained"
  //         endIcon={<Archive />}
  //         onClick={() => archiveCallScript()}
  //         key={"archive-cold-call-action"}
  //       />,
  //     );
  //   }
  //   return <ButtonGroup>{actions}</ButtonGroup>;
  // };

  const renderAIAssistant = useCallback(() => {
    if (
      currentMessage &&
      currentMessage.type &&
      currentMessage.type.get() === "outreach"
    )
      return (
        <Box className={styles.aiAssistantSwitch}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showAI}
                  onChange={() => {
                    setShowAI(!showAI);
                  }}
                  size="small"
                />
              }
              label="AI"
            />
          </FormGroup>
        </Box>
      );
  }, [currentMessage, showAI]);

  // return (
  //   <Box className={styles.footer}>
  //     <Box className={styles.innerFootContainer}>
  //       {!showAI ? (
  //         !loading ? (
  //           <Box className={styles.actionsContainer}>
  //             <Box key="linkedin-actions">{renderLinkedInActionButtons()}</Box>
  //             <Box key="email-actions">{renderEmailActionButtons()}</Box>
  //             {/* <Box key="call-actions">{renderCallActionButtons()}</Box> */}
  //           </Box>
  //         ) : (
  //           <CircularProgress />
  //         )
  //       ) : (
  //         <AiAssistantDialog />
  //       )}
  //       {renderAIAssistant()}
  //     </Box>
  //     {threadData.messages.length > 0 && <MessagesNav />}
  //   </Box>
  // );

  return (
    <Box className={styles.footer}>
      <Box className={styles.innerFootContainer}>
        {!showAI ? (
          <Box className={styles.actionsContainer}>
            <Box key="linkedin-actions">{renderLinkedInActionButtons()}</Box>
            <Box key="email-actions">{renderEmailActionButtons()}</Box>
          </Box>
        ) : (
          <AiAssistantDialog />
        )}
        {renderAIAssistant()}
      </Box>
      {threadData.messages.length > 0 && <MessagesNav />}
    </Box>
  );
};
