import { useState } from "react";
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import { logInUser } from "./LoginDialog";

import {
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  Link,
  TextField,
  FormControl,
  List,
  ListItem,
  Stack,
  CircularProgress,
} from "@mui/material";
import bellaIcon from "../../assets/bellaIcon.png";

export const PasswordResetDialog = (props: { close: () => void }) => {
  const [username, setUsername] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationCodeSent, setConfirmationCodeSent] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    setConfirmationCode(""); // Reset confirmation code state
    setNewPassword(""); // Reset new password state
    props.close();
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const output = await resetPassword({ username: username });
      // Assuming the resetPassword initiates the process successfully
      if (
        output.nextStep?.resetPasswordStep ===
        "CONFIRM_RESET_PASSWORD_WITH_CODE"
      ) {
        setConfirmationCodeSent(true);
      } else {
        throw new Error(
          "Unexpected response from resetPassword. Contact Bella Support team.",
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = () => {
    const errors = [];
    if (confirmationCode.length < 6) {
      errors.push("Confirmation code must be at least 6 characters long");
    }
    if (newPassword.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!newPassword.match(/[a-z]/)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!newPassword.match(/[A-Z]/)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!newPassword.match(/[0-9]/)) {
      errors.push("Password must contain at least one number");
    }
    if (!newPassword.match(/[^a-zA-Z0-9]/)) {
      errors.push("Password must contain at least one special character");
    }
    setErrors(errors);
    if (errors.length > 0) return false;
    return true;
  };

  const handleConfirmResetPassword = async () => {
    if (!validatePassword()) return;
    try {
      await confirmResetPassword({
        username: username,
        confirmationCode,
        newPassword,
      });
      // Assuming the password reset is successful
      await logInUser(username, newPassword);
    } catch (error) {
      console.log(error);
      setErrors([error.message]);
      // Optionally, handle errors, e.g., showing an error message in the modal
    } finally {
      setLoading(false);
      handleClose();
    }
  };
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src={bellaIcon} />
      <Typography component="h1" variant="h4">
        Password Reset
      </Typography>
      <Box sx={{ mt: 1, p: 4, width: "100%" }} className={"raised-tile"}>
        {confirmationCodeSent ? (
          <FormControl sx={{ gap: "1rem" }}>
            <Typography
              id="password-reset-confirm-modal-title"
              variant="h6"
              component="h2"
            >
              Enter New Details
            </Typography>
            <TextField
              type="text"
              label="Confirmation Code"
              fullWidth
              value={confirmationCode || ""}
              autoComplete="off"
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
            <TextField
              type="password"
              label="New Password"
              fullWidth
              autoComplete="new-password"
              value={newPassword || ""}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleConfirmResetPassword}
              >
                Confirm Reset
              </Button>
            )}
            {errors.length > 0 && (
              <Stack>
                {errors.map((error) => (
                  <Typography key={error} color="error" variant="body2">
                    - {error}
                  </Typography>
                ))}
              </Stack>
            )}
            <Box>
              <Typography variant="subtitle2">
                Password requirements:
              </Typography>
              <List dense={true}>
                <ListItem>Minimum length: 8 character(s)</ListItem>
                <ListItem>Contains at least 1 number</ListItem>
                <ListItem>Contains at least 1 special character</ListItem>
                <ListItem>Contains at least 1 uppercase letter</ListItem>
                <ListItem>Contains at least 1 lowercase letter</ListItem>
              </List>
            </Box>
          </FormControl>
        ) : (
          <Box display="flex" flexDirection="column" gap={3}>
            <Box>
              <Typography variant="h4" color="error">
                Forgot your password?
              </Typography>
              <Typography variant="subtitle2">
                Please enter your email to reset your password.
              </Typography>
            </Box>
            <TextField
              type="email"
              label="Your Email"
              id="email"
              name="email"
              autoComplete="email"
              fullWidth
              value={username || ""}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleResetPassword}
              >
                Send Confirmation Code
              </Button>
            )}
          </Box>
        )}
        <Grid container>
          <Grid item xs>
            <Link component="button" variant="body2" onClick={handleClose}>
              Back to Sign In
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
