import { useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { callAPI } from "../../api";
import { Helper } from "../../components/Helper";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Add, Delete, DeleteForever, Edit, Save } from "@mui/icons-material";
import styles from "./Profile.module.scss";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const CaseStudies = () => {
  const { userData, setUserData } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [newCaseStudy, setNewCaseStudy] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [confirmIdx, setConfirmIdx] = useState(null);
  const maxChars = 500;

  const [editIdx, setEditIdx] = useState<number | null>(null);
  const [editedCaseStudy, setEditedCaseStudy] = useState("");

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = (index) => {
    if (confirmIdx === index) {
      // Add your delete logic here
      callAPI("DELETE", `case_studies`, { index: index }).then((res) => {
        setUserData({ ...userData, caseStudies: res });
      });
    } else {
      setConfirmIdx(index);
      setTimeout(() => {
        setConfirmIdx(null);
      }, 2000);
    } // Close the overlay after deleting
  };

  const addCaseStudy = () => {
    if (newCaseStudy.length > maxChars) {
      setError("Description is too long");
      return;
    }

    callAPI("PUT", "case_studies", { caseStudy: newCaseStudy })
      .then((res) => {
        setUserData({ ...userData, caseStudies: res });
        setNewCaseStudy("");
        setShowModal(false);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const startEdit = (index: number) => {
    setEditIdx(index);
    setEditedCaseStudy(userData.caseStudies[index]);
  };

  const saveEdit = () => {
    if (editedCaseStudy.length > maxChars) {
      setError("Description is too long");
      return;
    }

    const case_studies = userData.caseStudies;
    console.log("case_studies: ", case_studies);

    const updatedCaseStudies = case_studies.map((study, i) =>
      i === editIdx ? editedCaseStudy : study,
    );
    console.log("updatedCaseStudies: ", updatedCaseStudies);

    callAPI("POST", "case_studies", { caseStudy: updatedCaseStudies })
      .then((res) => {
        setUserData({ ...userData, caseStudies: res });
        setEditIdx(null);
        setEditedCaseStudy("");
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <Box className={styles.displayContainer}>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="add-case-study-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography
            id="add-case-study-modal-title"
            variant="h6"
            component="h2"
          >
            Add a Case Study
          </Typography>
          <TextField
            id="add-case-study-modal-description"
            sx={{ mt: 2 }}
            placeholder="Describe your case study..."
            variant="outlined"
            multiline
            minRows={4}
            fullWidth
            error={newCaseStudy.length > maxChars}
            onChange={(e) => setNewCaseStudy(e.target.value)}
          />
          <Box className={styles.modalFooter}>
            <Typography variant="caption" gutterBottom>
              {newCaseStudy.length}/{maxChars} characters
            </Typography>
            {error && (
              <Typography variant="caption" color="error" gutterBottom>
                {error}
              </Typography>
            )}
            <IconButton
              color="primary"
              onClick={addCaseStudy}
              disabled={newCaseStudy.length > maxChars}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      </Modal>
      <Box className={styles.displayContainerHeader}>
        <Typography variant="subtitle1" gutterBottom>
          Our Case Studies
        </Typography>
        <Box className={styles.addButton}>
          <Helper
            title={
              userData.linkedinObjectUrn && userData?.caseStudies.length === 0
                ? `Add your first case study. It also helps Bella to generate better value proposition in the messages.`
                : null
            }
            open={true}
          >
            <IconButton color="primary" onClick={toggleShowModal}>
              <Add />
            </IconButton>
          </Helper>
        </Box>
      </Box>
      <Box>
        <List dense={true}>
          {userData.caseStudies?.map((study, i) => (
            <ListItem
              key={`caseStudy-${i}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              secondaryAction={
                editIdx === i ? (
                  <IconButton
                    edge="end"
                    aria-label="save"
                    color="primary"
                    onClick={saveEdit}
                    disabled={editedCaseStudy.length > maxChars}
                  >
                    <Save />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      color="primary"
                      onClick={() => startEdit(i)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color={confirmIdx === i ? "error" : "default"}
                      onClick={() => handleDelete(i)}
                    >
                      {confirmIdx === i ? <DeleteForever /> : <Delete />}
                    </IconButton>
                  </>
                )
              }
            >
              {editIdx === i ? (
                <TextField
                  value={editedCaseStudy}
                  onChange={(e) => setEditedCaseStudy(e.target.value)}
                  variant="outlined"
                  fullWidth
                  error={editedCaseStudy.length > maxChars}
                  sx={{ pr: 4 }}
                />
              ) : (
                <ListItemText primary={study} sx={{ pr: 4 }} />
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};
