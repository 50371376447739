import { callAPI } from "../../api";

export const refreshCampaignApiToken = async () => {
  try {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Token not found in local storage.");
    }

    const res = await callAPI("POST", "refresh_campaign_api_token", {
      token: token,
    });
    return res;
  } catch (error) {
    console.error("Error refreshing campaign API token:", error);
  }
};
