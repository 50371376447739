import { useMemo } from "react";
import { useThread } from "../../contexts/ThreadContext";
import {
  Box,
  Modal,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";

const style = {
  display: "flex",
  flexDirection: "row",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "auto",
};

const leadInsightsHeaders = [
  "Career progression",
  "Decision making power",
  "Challenges",
  "Post Summaries",
  "Personality",
  "Top Quotes",
];

const companyInsightsHeaders = [
  "Summary",
  "Achievements",
  "Job Openings",
  "Key Partnerships",
  "Case Studies",
  "Tech Stack",
];

const InsightItem = (props: {
  index: number;
  text: string;
  heading: string;
}) => {
  const parsedText = useMemo(() => {
    try {
      const parsed = JSON.parse(props.text);
      if (typeof parsed === "object") {
        return Object.values(parsed).join("; ");
      } else {
        return parsed;
      }
    } catch (e) {
      return props.text;
    }
  }, [props.text]);

  return (
    <ListItem key={props.index}>
      <ListItemText
        primary={props.heading}
        primaryTypographyProps={{
          fontSize: 13,
          fontStyle: "italic",
          fontWeight: "bold",
        }}
        secondary={props.text.length > 0 ? parsedText : "Not found..."}
        secondaryTypographyProps={{ fontSize: 12, color: "black" }}
      />
    </ListItem>
  );
};

export const InsightsModal = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { open, setOpen } = props;
  const { threadData } = useThread();

  const insights = threadData.insights.get();

  const leadInsights = useMemo(
    () => insights.find((i) => i.origin === "lead_linkedin"),
    [insights],
  );

  const companyInsights = useMemo(
    () => insights.find((i) => i.origin === "company_linkedin"),
    [insights],
  );

  const techStackInsights = useMemo(
    () => insights.find((i) => i.origin === "tech_stack"),
    [insights],
  );

  const industryInsights = useMemo(
    () => insights.find((i) => i.origin === "industry_news"),
    [insights],
  );

  const close = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {leadInsights && (
          <List dense={true} sx={{ flex: 1 }}>
            <ListSubheader>Lead Insights</ListSubheader>
            {leadInsights.content.map((i, index) => (
              <InsightItem
                key={index}
                index={index}
                text={i}
                heading={leadInsightsHeaders[index]}
              />
            ))}
          </List>
        )}

        {companyInsights && (
          <List dense={true} sx={{ flex: 1 }}>
            <ListSubheader>Company Insights</ListSubheader>
            {companyInsights.content.map((i, index) => (
              <InsightItem
                key={index}
                index={index}
                text={i}
                heading={companyInsightsHeaders[index]}
              />
            ))}
            {techStackInsights && (
              <InsightItem
                key={999}
                index={999}
                text={techStackInsights.content.join("; ")}
                heading={"Tech Stack"}
              />
            )}
          </List>
        )}

        {industryInsights && (
          <List dense={true} sx={{ flex: 1 }}>
            <ListSubheader>Industry Insights</ListSubheader>
            {industryInsights.content.map((i, index) => (
              <InsightItem key={index} index={index} text={i} heading={null} />
            ))}
          </List>
        )}
      </Box>
    </Modal>
  );
};
