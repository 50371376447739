import { useState } from "react";
import { callAPI } from "../api";
import {
  Box,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useThread } from "../contexts/ThreadContext";
import { useUser } from "../contexts/UserContext";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ImportLeadToCRMModal = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const { thread, lead } = useThread();
  const [message, setMessage] = useState<string | null>(null);

  const importLeadToCrm = async () => {
    setLoading(true);
    const res = await callAPI("POST", "/crm/lead", {
      leadPID: lead?.pid,
    });

    setLoading(false);

    if (res) {
      let action = "";
      if (userData.crmProvider === "salesforce") {
        action = lead?.salesforceLeadId ? "updated" : "imported";
      } else if (userData.crmProvider === "hubspot") {
        action = lead?.hubSpotLeadId ? "updated" : "imported";
      }

      setMessage(`Successfully ${action} Lead to ${userData.crmProvider}`);

      setTimeout(() => {
        props.setOpen(false);
        setMessage(null);
      }, 2000);
    } else {
      console.error("Failed to import lead");
    }
  };

  const renderContent = () => {
    const action = thread?.target.salesforceLeadId ? "update" : "import";
    const messageText = `${action} Lead data in ${userData.crmProvider}`;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
          {messageText}
        </Typography>
        <Button
          onClick={importLeadToCrm}
          variant="outlined"
          sx={{ textTransform: "capitalize" }}
        >
          {messageText}
        </Button>
      </Box>
    );
  };

  return (
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      <Box sx={modalStyle}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            {message ? <Typography>{message}</Typography> : renderContent()}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
