import { useState } from "react";
import { useThread } from "../../contexts/ThreadContext";
import { TextField, Box, IconButton, CircularProgress } from "@mui/material";
import {
  Email,
  LinkedIn,
  Phone,
  Delete,
  DeleteForever,
  Check,
  CheckCircleOutlined,
} from "@mui/icons-material";
import { blue, pink, green } from "@mui/material/colors";
import styles from "./ThreadCard.module.scss";
import { useUser } from "../../contexts/UserContext";

export const MessageBox = (props: { disabled }) => {
  const { disabled } = props;
  const { currentMessage } = useThread();

  return (
    <TextField
      variant="standard"
      area-label="Message"
      sx={{
        textarea: {
          lineHeight: 1.1,
          fontSize: "12px",
        },
      }}
      size="small"
      disabled={disabled}
      value={currentMessage.content.get() || ""}
      minRows={2}
      maxRows={10}
      onChange={(e) => currentMessage.content.set(e.target.value)}
      multiline
    />
  );
};

export const MessageBody = () => {
  const { userData } = useUser();
  const { currentSteps, currentMessage, putMessage, deleteMessage } =
    useThread();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSent, setConfirmSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const disabled = currentMessage?.status?.get() !== "created";

  const getChannelIcon = () => {
    switch (currentMessage.channel.get()) {
      case "linkedin":
        return <LinkedIn sx={{ color: blue[100] }} />;
      case "email":
        return <Email sx={{ color: pink[100] }} />;
      case "call":
        return <Phone sx={{ color: green[100] }} />;
    }
  };

  const handleDelete = async () => {
    if (confirmDelete) {
      setLoading(true);
      await deleteMessage(currentMessage.pid.get());
      setLoading(false);
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
      setTimeout(() => {
        setConfirmDelete(false);
      }, 2000);
    }
  };

  const handleSent = async () => {
    if (confirmSent) {
      setLoading(true);
      const emailOutreachStep = currentSteps.find(
        (s) => s.messageType === "outreach" && s.channel === "email",
      );
      await putMessage({ status: "sent" }, emailOutreachStep.pid);
      setLoading(false);
    } else {
      setConfirmSent(true);
      setTimeout(() => {
        setConfirmSent(false);
      }, 2000);
    }
  };
  if (!currentMessage?.content?.get()) return null;

  return (
    <Box
      className={`${styles.messageContainer} ${currentMessage.type.get()}-${currentMessage.status.get()}`}
      id={currentMessage.pid.get()}
    >
      {currentMessage.subject.get() && (
        <TextField
          variant="standard"
          area-label="Subject"
          sx={{ input: { lineHeight: 1.1, fontSize: "12px" } }}
          value={currentMessage.subject.get() || ""}
          size="small"
          onChange={(e) => currentMessage.subject.set(e.target.value)}
          disabled={disabled}
        />
      )}
      <Box className={styles.messageContentContainer}>
        <MessageBox disabled={disabled} />
        {currentMessage.status.get() === "created" && (
          <Box sx={{ position: "absolute", bottom: 5, right: 10 }}>
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {getChannelIcon()}
                {!userData.emailProvider && (
                  <IconButton
                    edge="end"
                    aria-label="sent"
                    color={confirmSent ? "error" : "default"}
                    onClick={handleSent}
                  >
                    {confirmSent ? <CheckCircleOutlined /> : <Check />}
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color={confirmDelete ? "error" : "default"}
                  onClick={handleDelete}
                >
                  {confirmDelete ? <DeleteForever /> : <Delete />}
                </IconButton>
              </>
            )}
          </Box>
        )}
        {currentMessage.type.get() === "cr" && (
          <span className={styles.messageContentLength}>
            {currentMessage.content.get().length}/300
          </span>
        )}
      </Box>
    </Box>
  );
};
