import React, { useState } from "react";
import { AppBar, Toolbar, Button, Card, Box } from "@mui/material";
import SupportForm from "./SupportForm";
import VideoTutorials from "./VideoTutorials";
// import DocsTutorials from "./DocsTutorials";

export const Support = () => {
  const [activeComponent, setActiveComponent] = useState("supportForm");

  return (
    <Card sx={{ borderRadius: "10px" }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("videoTutorials")}
          >
            Video Tutorials
          </Button>
          {/* <Button
            color="inherit"
            onClick={() => setActiveComponent("docsTutorials")}
          >
            Docs Tutorials
          </Button> */}
          <Button
            color="inherit"
            onClick={() => setActiveComponent("supportForm")}
          >
            Contact Us
          </Button>
        </Toolbar>
      </AppBar>
      <Box className="support-content">
        {activeComponent === "videoTutorials" && <VideoTutorials />}
        {/* {activeComponent === "docsTutorials" && <DocsTutorials />} */}
        {activeComponent === "supportForm" && <SupportForm />}
      </Box>
    </Card>
  );
};
