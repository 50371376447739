import { useState } from "react";
import { AppBar, Toolbar, Box, Card, Typography } from "@mui/material";
import LanguageSelector from "./LanguageSelector";

export const UserSettings = () => {
  const [activeComponent] = useState("updateUserDetails");

  return (
    <Card sx={{ borderRadius: "10px" }}>
      <AppBar position="static">
        <Toolbar>
          <Typography>User Settings</Typography>
        </Toolbar>
      </AppBar>
      <Box className="support-content">
        {activeComponent === "updateUserDetails" && <LanguageSelector />}
      </Box>
    </Card>
  );
};
