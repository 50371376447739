import { useThread } from "../../contexts/ThreadContext";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { IconButton, Box } from "@mui/material";

import styles from "./ThreadCard.module.scss";

export const MessagesNav = () => {
  const { threadData } = useThread();

  return (
    <Box className={styles.msgNavContainer}>
      <IconButton
        sx={{ padding: 0 }}
        onClick={() => threadData.msgIndex.set((i) => i - 1)}
        disabled={threadData.msgIndex.get() === 0}
      >
        <ArrowBackIos sx={{ fontSize: 10 }} />
      </IconButton>
      <span>{threadData.msgIndex.get() + 1}</span>
      <IconButton
        sx={{ padding: 0 }}
        onClick={() => threadData.msgIndex.set((i) => i + 1)}
        disabled={threadData.msgIndex.get() === threadData.messages.length - 1}
      >
        <ArrowForwardIos sx={{ fontSize: 10 }} />
      </IconButton>
    </Box>
  );
};
