import { useState, useEffect, FC } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Switch,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { HelpOutline, Add, Save, HourglassBottom } from "@mui/icons-material";
import { callAPI } from "../../api";
import styles from "./Chatbot.module.scss";
import NewConversationModal from "./NewConversationModal";
import ConversationAccordion from "./ConversationAccordion";
import ChatbotSimulator from "./ChatbotSimulator";
import StyledTextarea from "./StyledTextarea";
import { Helper } from "../../components/Helper";
import { IChatbotConfig, IMessage } from "./types";

const Chatbot: FC = () => {
  const [showNewConversationModal, setShowNewConversationModal] =
    useState(false);
  const [chatbotConfig, setChatbotConfig] = useState<IChatbotConfig>(
    {} as IChatbotConfig,
  );
  const [calendarLink, setCalendarLink] = useState("");
  const [calendarLinkLoading, setCalendarLinkLoading] = useState(false);
  const [linkCtaLoading, setLinkCtaLoading] = useState(false);
  const [linkCta, setLinkCta] = useState("");
  const [customInstructions, setCustomInstructions] = useState("");
  const [customInstructionsLoading, setCustomInstructionsLoading] =
    useState(false);
  const [
    chatbotSimulatorExampleOutreachMessage,
    setChatbotSimulatorExampleOutreachMessage,
  ] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await callAPI("GET", "chatbot_config");
      setChatbotConfig(res);
      setCalendarLink(res.calendarLink || "");
      setLinkCta(res.linkCta || "");

      setCustomInstructions(res.customInstructions || "");
      setChatbotSimulatorExampleOutreachMessage(
        res.chatbotSimulatorExampleOutreachMessage || "",
      );
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const setChatbotOn = async (on: boolean) => {
    const res = await callAPI("PUT", "chatbot_config", { chatbotOn: on });
    setChatbotConfig(res);
  };

  const verifyLink = (link: string) => {
    const regex = new RegExp("^(http|https)://[^\\s/$.?#].[^\\s]*$", "g");
    return regex.test(link);
  };

  const saveLinkDetails = async () => {
    if (verifyLink(calendarLink)) {
      setCalendarLinkLoading(true);
      const res = await callAPI("PUT", "chatbot_config", {
        calendarLink,
        linkCta,
      });
      setChatbotConfig(res);
      setCalendarLinkLoading(false);
      setLinkCtaLoading(false);
    } else {
      console.log("trying to save invalid link to chatbot config");
    }
  };

  const saveCustomInstructions = async () => {
    setCustomInstructionsLoading(true);
    const res = await callAPI("PUT", "chatbot_config", {
      customInstructions,
    });
    setChatbotConfig(res);
    setCustomInstructionsLoading(false);
  };

  const addConversation = async (conversation: IMessage[]) => {
    const res = await callAPI("PUT", "chatbot_config", {
      exampleConversations: [
        ...(chatbotConfig.exampleConversations || []),
        conversation,
      ],
    });
    setChatbotConfig(res);
  };

  const editConversation = async (conversation: IMessage[], idx: number) => {
    const res = await callAPI("PUT", "chatbot_config", {
      exampleConversations: chatbotConfig.exampleConversations.map((conv, i) =>
        i === idx ? conversation : conv,
      ),
    });
    setChatbotConfig(res);
  };

  const deleteConversation = async (idx: number) => {
    const conversations = chatbotConfig.exampleConversations.filter(
      (_, i) => i !== idx,
    );
    const res = await callAPI("PUT", "chatbot_config", {
      exampleConversations: conversations,
    });
    setChatbotConfig(res);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "calc(100vh - 150px)",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" p={1}>
        <Typography variant="h3">Bella ChatBot Settings</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            className={styles.raisedTile}
            sx={{ minHeight: "calc(10vh / 2)", pb: 6 }}
          >
            <Typography variant="subtitle1">Main Settings</Typography>
            <Typography variant="body2" p={1} color="primary">
              Configure your Bella Chatbot to handle replies on LinkedIn.
            </Typography>
            <Typography variant="body2" p={1} color="primary">
              When a prospect responds to your outreach sequence, the sequence
              stops and Bella continues the conversation. Bella will only engage
              with leads who have replied to one of your Bella sequences. You
              can toggle the chatbot on and off for all sequences.
            </Typography>
            <Typography variant="body2" p={1} color="primary">
              Get started by:
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body2" p={1} color="primary">
                      1. Providing your call-to-action link (e.g., calendar or
                      URL) and context for the link.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body2" p={1} color="primary">
                      2. Adding FAQs and Example Conversations to guide Bella’s
                      responses.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body2" p={1} color="primary">
                      3. Adding Custom Instructions (a "ChatGPT" prompt) for
                      Bella to follow in every interaction.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body2" p={1} color="primary">
                      4. Testing your setup with the Chatbot Simulator.
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography variant="body2" color="darkorange" p={1}>
              PLEASE NOTE: Bella is in development and requires some
              experimentation to achieve desired results. Test and adjust the
              configuration first. Once satisfied with the simulation responses,
              turn the chatbot ON.
            </Typography>
            <br></br>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              gap={2}
            >
              <Helper title="Turns the Chat Bot on and off">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={10}
                  fontWeight="bold"
                >
                  <span>{chatbotConfig.chatbotOn ? "On" : "Off"}</span>
                  <Switch
                    checked={chatbotConfig?.chatbotOn || false}
                    onChange={(e) => setChatbotOn(e.target.checked)}
                  />
                </Box>
              </Helper>
              <Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
                <Helper title="Enter your call to action link here, for example a calendly link. It will be sent to leads when they ask for a meeting.">
                  <TextField
                    label={
                      calendarLink.length > 0 && !verifyLink(calendarLink)
                        ? "Please insert link with HTTP://"
                        : "Your call to action link, eg a calendly link"
                    }
                    variant="outlined"
                    fullWidth
                    value={calendarLink || ""}
                    onChange={(e) => setCalendarLink(e.target.value)}
                    size="small"
                    error={calendarLink.length > 0 && !verifyLink(calendarLink)}
                  />
                </Helper>
                <TextField
                  label="When should this link be sent to a lead?"
                  variant="outlined"
                  fullWidth
                  value={linkCta || ""}
                  onChange={(e) => setLinkCta(e.target.value)}
                  size="small"
                />
                <Button
                  color="primary"
                  onClick={saveLinkDetails}
                  variant="contained"
                  disabled={calendarLinkLoading || linkCtaLoading}
                >
                  {calendarLink.length > 0 && !verifyLink(calendarLink)
                    ? "Please insert a valid link"
                    : "Save Link Details"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.raisedTile} height="100%">
            <Typography variant="subtitle1">Custom Instructions</Typography>
            <Typography variant="body2" p={1} color="primary">
              These instructions are essential for generating quality replies.
              They should serve as guidelines for the chatbot based on the
              conversation flow and define its communication style and tone.
            </Typography>
            <Box p={1} sx={{ position: "relative" }}>
              <StyledTextarea
                minRows={6}
                maxRows={20}
                placeholder="Enter your custom instructions to the Bella Chat Bot here..."
                value={customInstructions}
                onChange={(e) => setCustomInstructions(e.target.value)}
              />
              <Box sx={{ position: "absolute", right: 10, top: 10 }}></Box>
              <Helper
                title="Add custom rules for your Bella Chatbot to follow. Make sure to save after every change."
                placement="left-end"
              >
                <IconButton color="primary">
                  <HelpOutline />
                </IconButton>
              </Helper>
              <Box sx={{ position: "absolute", right: 10, bottom: 10 }}>
                <Tooltip title="Click this save button to save these instructions to your chatbot.">
                  <IconButton
                    color="primary"
                    onClick={saveCustomInstructions}
                    disabled={customInstructionsLoading}
                  >
                    {customInstructionsLoading ? <HourglassBottom /> : <Save />}
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.raisedTile} height="100%">
            {showNewConversationModal && (
              <NewConversationModal
                open={showNewConversationModal}
                setOpen={setShowNewConversationModal}
                onSave={addConversation}
              />
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">
                Example Conversations and FAQ's
              </Typography>
              <Helper title="Configure Bella to respond as desired by adding frequently asked questions/answers (FAQs) and example conversations. To create an example conversation, press the + icon in the top right corner of this panel.">
                <IconButton color="primary">
                  <HelpOutline />
                </IconButton>
              </Helper>
              <IconButton
                color="primary"
                onClick={() => setShowNewConversationModal(true)}
              >
                <Add />
              </IconButton>
            </Box>
            {chatbotConfig.exampleConversations?.map((items, index) => (
              <ConversationAccordion
                key={index}
                items={items}
                onDelete={() => deleteConversation(index)}
                onEdit={(conv) => editConversation(conv, index)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.raisedTile} height="100%">
            {!isLoading && (
              <ChatbotSimulator
                exampleOutreachMessage={chatbotSimulatorExampleOutreachMessage}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Chatbot;
