import { useState } from "react";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import {
  EmailConnection,
  EmailSignature,
  AIIntegration,
  CRMIntegration,
} from "../../components/Integrations";

import styles from "./Profile.module.scss";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

export const Integrations = () => {
  const [currentTabIdx, setCurrentTabTdx] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabTdx(newValue);
  };
  return (
    <Box className={styles.displayContainer}>
      <Box className={styles.displayContainerHeader}>
        <Typography variant="subtitle1" gutterBottom>
          Integrations
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTabIdx}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Email" {...a11yProps(0)} />
          <Tab label="AI" {...a11yProps(1)} />
          <Tab label="CRM" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={currentTabIdx} index={0}>
        <EmailConnection />
        <br />
        <EmailSignature />
      </CustomTabPanel>
      <CustomTabPanel value={currentTabIdx} index={1}>
        <AIIntegration />
      </CustomTabPanel>
      <CustomTabPanel value={currentTabIdx} index={2}>
        <CRMIntegration />
      </CustomTabPanel>
    </Box>
  );
};
