import { useState, useEffect } from "react";
import { NodeProps, Position, useEdges } from "reactflow";
import { Box } from "@mui/material";
import { NodeData } from "../types";
import { NodeHeader } from "../NodeHeader";
import { NodeSettings } from "../NodeSettings";
import { HandleWithTooltip } from "../components";
import styles from "../Sequences.module.scss";

export const OutreachNode = (props: NodeProps<NodeData>) => {
  const { data } = props;
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [inputConnected, setInputConnected] = useState(false);
  const edges = useEdges();

  useEffect(() => {
    const incomingEdges = edges.filter((edge) => edge.target === props.id);
    if (incomingEdges.length > 0) {
      setInputConnected(true);
    } else {
      setInputConnected(false);
    }
  }, [edges]);

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <Box
      className={inputConnected ? styles.nodeOk : styles.nodeError}
      border={props.selected ? "2px solid #000000" : null}
    >
      <NodeSettings open={settingsOpen} onClose={toggleSettings} {...props} />
      <NodeHeader openSettings={toggleSettings} {...data} />
      <HandleWithTooltip
        tooltip="Trigger"
        type="target"
        position={Position.Left}
        id="input"
        isConnectable={true}
        className={styles.targetHandle}
      />
      <HandleWithTooltip
        tooltip="Outreach ignored for X days"
        type="source"
        position={Position.Right}
        id="negative"
        isConnectable={true}
        className={styles.sourceHandleBad}
      />
    </Box>
  );
};
