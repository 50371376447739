import { Tooltip } from "@mui/material";
import { Email, LinkedIn, Phone } from "@mui/icons-material";
import { Handle, HandleProps } from "reactflow";

export const ChannelIcon = (props: { channel: string }) => {
  const { channel } = props;
  switch (channel) {
    case "linkedin":
      return <LinkedIn color="primary" />;
    case "email":
      return <Email color="error" />;
    case "phone":
      return <Phone color="success" />;
  }
};

export const HandleWithTooltip = (
  props: HandleProps & { tooltip: string; className: string },
) => {
  return (
    <Tooltip title={props.tooltip} arrow>
      <Handle
        type={props.type}
        position={props.position}
        id={props.id}
        isConnectable={true}
        className={props.className}
      />
    </Tooltip>
  );
};
