import { useUser } from "../contexts/UserContext";
import { Box, Modal } from "@mui/material";

const modalStyle = {
  position: "absolute" as const,
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  bgcolor: "rgba(255, 255, 255, 0.5)",
  border: "none",
  boxShadow: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: 4,
};

const contentStyle = {
  width: "90%",
  maxWidth: "1200px",
  borderRadius: "8px",
  padding: "20px",
  bgcolor: "rgba(255, 255, 255, 1)",
  overflowY: "auto",
};

export const ShopModal = (props: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}) => {
  const { userData } = useUser();
  const { showModal } = props;

  const handleOuterClick = () => {
    props.setShowModal(false);
  };

  const handleInnerClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Modal open={showModal} onClose={handleOuterClick}>
      <Box sx={modalStyle} onClick={handleOuterClick}>
        <Box sx={contentStyle} onClick={handleInnerClick}>
          {userData.isAdmin ? (
            <>
              <stripe-pricing-table
                pricing-table-id="prctbl_1PaWNmS63bcFdplCdugvpS0O"
                publishable-key="pk_test_51OoGa7S63bcFdplCqxngMqPQweAQ4HTyNf7K3aPzHSoy4hmNjvqWxsy5IlCN17gNC4TvwI8uRSfnQuHAJZhVajFa00MJa8yG27"
                client-reference-id={userData.pid}
              ></stripe-pricing-table>
            </>
          ) : (
            <>
              <stripe-pricing-table
                pricing-table-id="prctbl_1PaWSAS63bcFdplCYvLZqsnN"
                publishable-key="pk_live_51OoGa7S63bcFdplC9Fq85UJCmqTOQ0Cq6hUfvfAq7eaUo2qJxLLP6SGh6hGYFfLYUQh1L4SGJsWeQ6QPeW3SX3Pd00GeaK4I90"
                client-reference-id={userData.pid}
              ></stripe-pricing-table>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
