import { createContext, useContext, useState, useEffect } from "react";

import { User } from "../components/types";
import { callAPI } from "../api";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState<User>(null);
  const [activePage, setActivePage] = useState("profile");

  const fetchProfile = async () => {
    const newData = await callAPI("GET", "user");
    if (newData.error) {
      throw new Error(newData.error);
    }
    localStorage.setItem("userData", JSON.stringify(newData));
    setUserData({
      ...newData,
      threads: [],
    });
    if (
      !newData.isAdmin &&
      window.location.href !== "https://app.bellasales.io/" &&
      window.location.href !== "http://localhost:5173/"
    ) {
      console.log("redirecting to app.bellasales.io");
      window.location.href = "https://app.bellasales.io/";
    }
  };

  const updateUser = async (props) => {
    const res = await callAPI("PUT", "/user", props);
    setUserData(res);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        fetchProfile,
        activePage,
        setActivePage,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => useContext(UserContext);
