import { forwardRef } from "react";

import {
  Tooltip,
  styled,
  tooltipClasses,
  TooltipProps,
  Box,
} from "@mui/material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export const Helper = forwardRef(
  (props: { title; placement?; open?; children }, ref) => {
    if (props.title) {
      return (
        <LightTooltip
          title={props.title}
          placement={props.placement || "top"}
          open={props.open}
          PopperProps={{ style: { zIndex: 500 } }}
        >
          <Box ref={ref}>{props.children}</Box>
        </LightTooltip>
      );
    }
    return <Box ref={ref}>{props.children}</Box>;
  },
);
