import { useThread } from "../../contexts/ThreadContext";

import { Box, Avatar, Link } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import styles from "./ThreadCard.module.scss";

export const Header = () => {
  const { lead } = useThread();

  return (
    lead && (
      <Box display="flex" flexDirection="row" gap="20px">
        <Box className={styles.leadPic}>
          <Avatar
            sx={{ bgcolor: deepPurple[500] }}
            alt={lead.firstName + " " + lead.lastName}
            src={lead.picture}
            id={lead.pid}
          />
        </Box>
        <Link
          href={lead.linkedInUrl}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <Box className={styles.leadDetails} id={lead.pid}>
            <Box className={styles.leadName}>
              {lead.firstName + " " + lead.lastName}
            </Box>
            <Box className={styles.leadTitle}>
              {lead.title + " @ " + lead.companyName}
            </Box>
          </Box>
        </Link>
      </Box>
    )
  );
};
