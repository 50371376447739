import { useEffect, useState, forwardRef, Ref, ReactElement } from "react";
import { useUser } from "../contexts/UserContext";
import { callAPI, callExtension } from "../api";
import { BellaIcon } from "./Icons";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
  LinearProgress,
  FormControl,
  TextField,
  Slide,
  Skeleton,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { LeadsList, Sequence } from "./types";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const campaignNoteStyle = {
  borderLeft: "3px solid #3f51b5",
  backgroundColor: "#3f51b512",
  margin: "10px",
  paddingLeft: "10px",
};

export const NewCampaignDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setUserData, userData } = useUser();
  const { open, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [leadsLists, setLeadsLists] = useState<LeadsList[]>(null);
  const [campaignName, setCampaignName] = useState<string>(null);
  const [selectedLeadsList, setSelectedLeadsList] = useState<LeadsList | null>(
    null,
  );
  const [selectedSequence, setSelectedSequence] = useState<Sequence>(null);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    const loadSequences = async () => {
      setLoading(true);
      try {
        const sequences = await callAPI("GET", "/sequences");
        setUserData((prev) => ({ ...prev, sequences: sequences }));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    if (!userData.sequences) {
      loadSequences();
    }
  }, [userData.sequences]);

  useEffect(() => {
    if (!leadsLists) {
      const loadLeadLists = async () => {
        setLoading(true);
        try {
          const bellaLists = await callExtension("LISN_fetchLists");
          setLeadsLists(bellaLists);
        } catch (error) {
          console.log(
            "Error fetching LinkedIn sales lists. Loading existing",
            error,
          );
        } finally {
          setLoading(false);
        }
      };
      loadLeadLists();
    }
  }, []);

  const resetState = () => {
    setCampaignName(null);
    setSelectedLeadsList(null);
    setSelectedSequence(null);
    setErrors([]);
  };

  const validateForm = () => {
    const errors = [];
    if (!campaignName) {
      errors.push("Campaign name is required");
    }
    if (!selectedLeadsList) {
      errors.push("Leads list is required");
    }
    if (!selectedSequence) {
      errors.push("Sequence is required");
    }
    setErrors(errors);
    return errors.length === 0;
  };

  const createCampaign = async () => {
    const res = await callAPI("POST", "campaign", {
      title: campaignName,
      leadsList: selectedLeadsList,
      sequencePID: selectedSequence.pid,
    });
    console.log("createCampaign res: ", res);
    return res;
  };

  const submit = async () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      const res = await createCampaign();
      setUserData({ ...userData, campaigns: res });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    resetState();
    onClose();
  };

  return (
    <Dialog
      onClose={() => {
        resetState();
        onClose();
      }}
      open={open}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
        width: "auto",
      }}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle variant="h4">Create New Campaign</DialogTitle>
      <DialogContent>
        <Box sx={campaignNoteStyle}>
          <Typography variant="caption">
            Campaign is what defines the starting point for your outreach. To
            start a campaign you need to select a leads list and a sequence. All
            the leads in the selected list will be added to the campaign and the
            sequence will be used to generate and send messages to them.
          </Typography>
        </Box>
        {errors.length > 0 && (
          <Box>
            {errors.map((e) => (
              <Alert severity="error" key={e}>
                {e}
              </Alert>
            ))}
          </Box>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "10px",
              gap: "20px",
            }}
          >
            <FormControl sx={{ flex: 1 }}>
              <InputLabel id="leads-list-label" size="small">
                Select Leads List
              </InputLabel>
              <Select
                labelId="leads-list-label"
                id="leads-list"
                label="Select Leads List"
                fullWidth
                value={selectedLeadsList?.lisnId || ""}
                onChange={(e) => {
                  const ll = leadsLists.find(
                    (l) => l.lisnId === e.target.value,
                  );
                  setSelectedLeadsList(ll);
                  setCampaignName(ll.title);
                }}
                required
                size="small"
                disabled={loading}
              >
                {leadsLists ? (
                  leadsLists.map((l: LeadsList) => (
                    <MenuItem key={l.lisnId} value={l.lisnId}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        {l.title} ({l.leadsInBella} / {l.entityCount})
                      </Box>
                      {l.leadsInBella > 0 && (
                        <BellaIcon width={14} height={14} marginLeft={10} />
                      )}
                    </MenuItem>
                  ))
                ) : (
                  <Skeleton variant="text" width={200} />
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <InputLabel id="sequence-label" size="small">
                Select Sequence
              </InputLabel>
              <Select
                labelId="sequence-label"
                id="sequence"
                label="Select Sequence"
                fullWidth
                value={selectedSequence?.pid || ""}
                onChange={(e) =>
                  setSelectedSequence(
                    userData.sequences.find((s) => s.pid === e.target.value),
                  )
                }
                required
                size="small"
                disabled={loading}
              >
                {userData.sequences ? (
                  userData.sequences.map((s: Sequence) => (
                    <MenuItem key={s.pid} value={s.pid}>
                      {s.name}
                    </MenuItem>
                  ))
                ) : (
                  <Skeleton variant="text" width={200} />
                )}
              </Select>
            </FormControl>
            <TextField
              id="campaign-name"
              label="Campaign Name"
              variant="outlined"
              sx={{ flex: 1 }}
              value={campaignName || ""}
              onChange={(e) => setCampaignName(e.target.value)}
              fullWidth
              required
              size="small"
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={submit}
              variant="outlined"
              disabled={
                !campaignName || !selectedLeadsList || !selectedSequence
              }
              id="submitCampaign"
            >
              Create Campaign
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
