import { useEffect } from "react";
// import { useEffect, useState } from "react";

import { MyInfo } from "./MyInfo";
import { ProductsAndServices } from "./ProductsAndServices";
import { Integrations } from "./Integrations";
import { CaseStudies } from "./CaseStudies";
import { Box } from "@mui/material";
import { GettingStarted } from "./GettingStartedComponent/GettingStarted";

const row1 = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
  marginBottom: "20px",
  height: "35vh",
};

const column = {
  flex: 1,
  overflowY: "auto",
  borderRadius: "8px",
};

const row2 = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
  marginBottom: "100px",
  marginTop: "30px",
  paddingBottom: "120px",
};

const scrollContainer = {
  height: "100vh",
  padding: "10px",
};

export const Profile = () => {
  // const [open, setOpen] = useState(false);

  useEffect(() => {
    // const hasSeenVideo = localStorage.getItem("hasSeenVideo");
    // if (!hasSeenVideo) {
    //   setOpen(true);
    //   localStorage.setItem("hasSeenVideo", "true");
    // }
  }, []);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box sx={scrollContainer}>
      {/* <Modal open={open} onClose={handleClose} sx={modalStyle}>
        <iframe
          src="https://www.loom.com/embed/inser-video-here"
          style={iframeStyle}
          title="Loom Video"
        ></iframe>
      </Modal> */}
      <Box sx={row1}>
        <MyInfo />
        <Box sx={column}>
          <GettingStarted />
        </Box>
      </Box>
      <Box sx={row2}>
        <ProductsAndServices />
        <CaseStudies />
        <Integrations />
      </Box>
    </Box>
  );
};
