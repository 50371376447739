import { useMemo, forwardRef } from "react";
import { Box, Stack, Tooltip, Typography, colors } from "@mui/material";
import {
  Gauge,
  gaugeClasses,
  useGaugeState,
  GaugeValueArc,
  GaugeReferenceArc,
  GaugeValueText,
} from "@mui/x-charts/Gauge";

interface IThreadStats {
  pid: string;
  messages: { status: string; type: string }[];
}

const GaugePointer = () => {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    return null;
  }
  const target = {
    x: cx + outerRadius * Math.sin(valueAngle) || cx,
    y: cy - outerRadius * Math.cos(valueAngle) || cy,
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={2} fill="red" />
      {isNaN(target.x) || isNaN(target.y) ? null : (
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="red"
          strokeWidth={1}
        />
      )}
    </g>
  );
};

const GaugeTooltip = (props: { value; maxValue; tooltipTitle; color }) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: null,
        color: "black",
        padding: "0.5rem",
        margin: 0,
      }}
    >
      <Typography variant="subtitle2">{props.tooltipTitle}</Typography>
      <Typography variant="body1">
        {props.maxValue > 0
          ? `${props.value} / ${props.maxValue} (${Math.round(
              (props.value / props.maxValue) * 100,
            )}%)`
          : "0"}
      </Typography>
    </Box>
  );
};

const CampaignMeter = forwardRef(
  (props: { value; maxValue; tooltipTitle; color }, ref) => (
    <Tooltip title={<GaugeTooltip {...props} />} placement="top">
      <Box ref={ref} width="100%">
        <Gauge
          value={props.value}
          startAngle={-90}
          height={70}
          endAngle={90}
          valueMin={0}
          valueMax={props.maxValue}
          text={null}
          sx={(theme) => ({
            // [`& .${gaugeClasses.valueArc}`]: {
            // 	fill: props.color,
            // },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: theme.palette.text.disabled,
            },
          })}
        >
          <GaugePointer />
          <GaugeReferenceArc />
          {props.value > 0 && <GaugeValueArc style={{ fill: props.color }} />}
          <GaugeValueText fontSize="0.6rem" y="1rem" />
        </Gauge>
      </Box>
    </Tooltip>
  ),
);

export const CampaignStats = forwardRef(
  (props: { threads: IThreadStats[] }, ref) => {
    const { threads } = props;
    const crAccepted = useMemo(
      () =>
        threads.filter((t) =>
          t.messages.some((m) => m.type === "cr" && m.status === "accepted"),
        ).length,
      [threads],
    );

    const crSent = useMemo(
      () =>
        threads.filter((t) =>
          t.messages.some((m) => m.type === "cr" && m.status === "sent"),
        ).length + crAccepted,
      [threads, crAccepted],
    );

    const outreachReplied = useMemo(
      () =>
        threads.filter((t) =>
          t.messages.some((m) => m.type === "reply" && m.status === "received"),
        ).length,
      [threads],
    );

    const outreachSent = useMemo(
      () =>
        threads.filter((t) =>
          t.messages.some(
            (m) =>
              m.type === "outreach" && ["sent", "replied"].includes(m.status),
          ),
        ).length,
      [threads],
    );

    return (
      <Box ref={ref}>
        <Stack direction="row" sx={{ zIndex: 5000 }}>
          <CampaignMeter
            value={crSent}
            maxValue={threads.length}
            tooltipTitle="Connection Requests Sent"
            color={colors.indigo[300]}
          />
          <CampaignMeter
            value={crAccepted}
            maxValue={Math.max(crSent, crAccepted)}
            tooltipTitle="Connection Requests Accepted"
            color={colors.indigo[500]}
          />
          <CampaignMeter
            value={outreachSent}
            maxValue={threads.length}
            tooltipTitle="Outreach Sent"
            color={colors.green[200]}
          />
          <CampaignMeter
            value={outreachReplied}
            maxValue={outreachSent}
            tooltipTitle="Replies Received"
            color={colors.green[500]}
          />
        </Stack>
      </Box>
    );
  },
);
