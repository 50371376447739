import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { LoginDialog } from "../../components/LoginDialog";
import { Copyright } from "../../components/Copyright";
import { AppBar, Container, Box, Toolbar } from "@mui/material";
import { UserMenu } from "../../components/UserMenu";
import { useUser } from "../../contexts/UserContext";
import styles from "./Layout.module.scss";
import antlerLogo from "../../assets/Antler_logo.png";
import bella_logo from "../../assets/bella_logo_white.png";

const Layout = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const { fetchProfile } = useUser();

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (token) {
        try {
          await fetchProfile();
          setIsLogged(true);
        } catch (e) {
          console.log(e.message);
          setIsLogged(false);
          setShowLoginDialog(true);
        }
      } else {
        setIsLogged(false);
        setShowLoginDialog(true);
      }
    };
    fetchUserProfile();
  }, [token]);

  if (!isLogged || showLoginDialog) {
    return <LoginDialog close={() => setShowLoginDialog(false)} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "60px",
        height: "fit-content",
        overflow: "hidden",
      }}
      className={"ai-background"}
    >
      <header className={styles.header} role={"banner"}>
        <AppBar position="static" elevation={0}>
          <Container maxWidth="xl">
            <Toolbar disableGutters className={styles.headerContainer}>
              <img
                src={bella_logo}
                alt="bellasales.io"
                height="40px"
                className={styles.logo}
              />
              <UserMenu logout={() => setIsLogged(false)} />
            </Toolbar>
          </Container>
        </AppBar>
      </header>
      <Outlet />
      <footer style={{ width: "100%" }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="end"
        >
          <Box className={styles.antlerLogo}>
            Backed by
            <img
              src={antlerLogo}
              alt="Generaitive.ai"
              aria-label="Link to Generaitive.ai"
              height="20px"
            />
          </Box>
          <Copyright />
        </Box>
      </footer>
    </Box>
  );
};

export default Layout;
