import { ResponseCache } from "../components/CacheManagers";
import * as models from "./models";
import { v4 as uuid4 } from "uuid";

const apiCache = new ResponseCache(300000);

export const callAPI = async (method, endpoint, body = {}, cached = false) => {
  let url = `/api/v1/${endpoint}`;
  const token = localStorage.getItem("token");
  if (cached) {
    const cachedResponse = await apiCache.get({ method, endpoint, body });
    if (cachedResponse) {
      return cachedResponse;
    }
  }
  const options = {
    method: method,
    headers: {
      "Content-Type":
        body instanceof FormData
          ? "application/x-www-form-urlencoded"
          : "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  if (["POST", "PUT"].includes(method)) {
    if (body instanceof FormData) {
      options["body"] = body;
    } else options["body"] = JSON.stringify(body);
  }
  if (["GET", "DELETE"].includes(method)) {
    const params = new URLSearchParams(body).toString();
    url = `${url}?${params}`;
  }
  if (token) {
    options.headers["Authorization"] = `Bearer ${token}`;
  }
  const response = await fetch(url, options);
  if (!response.ok) {
    const htmlText = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");
    const pElement = doc.querySelector("p");
    const errorMessage = pElement ? pElement.textContent : "Unknown error";
    console.log(
      "Error when calling Bella API:",
      htmlText,
      "Request:",
      method,
      endpoint,
      body,
      "Response:",
      response,
    );
    throw new Error(errorMessage);
  }
  const parsedResponse = await response.json();
  if (cached) {
    apiCache.set({ method, endpoint, body }, parsedResponse);
  }
  return parsedResponse;
};

export async function loginAPI(
  request: models.ILoginRequest,
): Promise<models.ILoginResponse> {
  const formBody = [];
  formBody.push("grant_type=");
  formBody.push(
    encodeURIComponent("email") + "=" + encodeURIComponent(request.email),
  );
  formBody.push(
    encodeURIComponent("password") + "=" + encodeURIComponent(request.password),
  );
  formBody.push("scope=");
  formBody.push("client_id=");
  formBody.push("client_secret=");
  const response = await callAPI("POST", `login`, formBody.join("&"));
  if (response.accessToken) {
    localStorage.setItem("token", response.accessToken);
  }
  return response;
}

export async function logoutAPI(): Promise<Response> {
  const response = await callAPI("POST", `logout`, {});
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
  localStorage.removeItem("user_pid");
  return response;
}

export async function registerAPI(request): Promise<models.ILoginResponse> {
  const response = await callAPI("POST", "register", request);
  if (response.accessToken) {
    localStorage.setItem("token", response.accessToken);
  }
  return response;
}

export const downloadExtension = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No token found");
    }
    const response = await fetch("/download_extension", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); // Replace with your actual endpoint
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();

    // Create a link element, use it to download the blob, and then remove it
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "bella.zip"; // You can set a specific file name here
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed:", error);
  }
};

export const callExtension = async (message, metadata = {}, body = {}) => {
  try {
    const res = await callAPI("POST", "ext/send_message", {
      message: message,
      body: body,
      metadata: { rid: uuid4(), ...metadata },
    });

    if (!res.results) {
      return res;
    } else {
      return res.results;
    }
  } catch (error) {
    throw new Error(`API call failed: ${error.message}`);
  }
};
