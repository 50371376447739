import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { ChannelIcon } from "./components";
import { NodeHeaderProps } from "./types";

export const NodeHeader = (props: NodeHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <ChannelIcon channel={props.channel} />
        {props.icon}
        <Divider orientation="vertical" flexItem />
        <Typography variant="h6">{props.label}</Typography>
      </Box>
      <IconButton size="small" onClick={props.openSettings}>
        <Settings />
      </IconButton>
    </Box>
  );
};
