import { useEffect, useState } from "react";
import { useThread } from "../../contexts/ThreadContext";
import { Lead } from "../types";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import styles from "./EmailDialog.module.scss";

const AddEmailForm = (props: {
  lead: Lead;
  addMessage: (msg: IMessage) => void;
}) => {
  const [emailForm, setEmailForm] = useState({
    from: null,
    body: "",
  });

  const handleChange = (field) => (event) => {
    setEmailForm({ ...emailForm, [field]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle email sending logic here
    console.log("Email Data:", emailForm);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <FormControl fullWidth size="small">
        <InputLabel id="email-from-label">Coming from</InputLabel>
        <Select
          labelId="email-from-column-label"
          id="email-from-column"
          label="LinkedIn Urls Column"
          value={emailForm.from || ""}
          onChange={handleChange("from")}
          fullWidth
        >
          <MenuItem key={"me"} value={"Me"}>
            Me to {props.lead.firstName} {props.lead.lastName}
          </MenuItem>
          <MenuItem
            key={props.lead.pid}
            value={`${props.lead.firstName} ${props.lead.lastName}`}
          >
            {props.lead.firstName} {props.lead.lastName} to Me
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Body"
        variant="outlined"
        value={emailForm.body}
        onChange={handleChange("body")}
        required
        multiline
        minRows={4}
        fullWidth
        size="small"
        sx={{ textarea: { lineHeight: 1.1 }, height: "fit-content" }}
        className={styles.emailBody}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ alignSelf: "start", marginTop: "10px" }}
        disabled={emailForm.body?.length == 0 || !emailForm.from}
        onClick={() =>
          props.addMessage({
            from: emailForm.from === "Me" ? "me" : "lead",
            body: emailForm.body,
          })
        }
      >
        Add to the Thread
      </Button>
    </Box>
  );
};

interface IMessage {
  from: string;
  body: string;
}

export const NewEmailDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { threadData, postMessage } = useThread();

  const lead = threadData.target.get();

  const addMessage = async (msg: IMessage) => {
    await postMessage({
      type: "reply",
      status: msg.from === "me" ? "sent" : "received",
      content: msg.body,
      channel: "email",
    });
    onClose();
  };
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Emails Logging (in development)</DialogTitle>
      <div className={styles.root}>
        <Typography variant="caption" color={"primary"} className={styles.info}>
          Here you can log any emails you have sent to or received from{" "}
          {lead.firstName} via email or other channel outside of Bella. Logging
          these messages is useful for keeping track of your conversations with{" "}
          {lead.firstName} and for Bella to learn from your conversations.
        </Typography>
        <AddEmailForm lead={lead} addMessage={addMessage} />
      </div>
    </Dialog>
  );
};

export const ChangeEmailDialog = (props: {
  open: boolean;
  setOpen: (boolean) => void;
}) => {
  const { open, setOpen } = props;
  const { threadData, putLead } = useThread();
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const lead = threadData.target.get();

  useEffect(() => {
    setEmail(lead.email);
  }, [open, lead.email, setEmail]);

  const validateEmail = (email) => {
    if (!email) return true;
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const save = async () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    setLoading(true);
    await putLead({ email: email });
    setLoading(false);
    setOpen(false);
  };
  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
      <DialogTitle>
        <Typography>Add/Change email for the lead</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          error={!validateEmail(email)}
          label="Email"
          variant="outlined"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          size="small"
          margin="normal"
          helperText={
            lead.email && !email
              ? "Save will remove the email from the lead"
              : error
          }
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" color="primary" onClick={save}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
