// VirtualExtensionSettings.js

import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  CircularProgress,
  Divider,
  TextField,
} from "@mui/material";
import { callAPI } from "../../api"; // Ensure this path is correct based on your project structure

export const VirtualExtensionSettings = () => {
  // State to manage loading for each action
  const [actionLoading, setActionLoading] = useState({
    setWebSocketSession: false,
    setCookiesHeaders: false,
  });

  // State to manage success messages for each action
  const [actionSuccess, setActionSuccess] = useState({
    setWebSocketSession: "",
    setCookiesHeaders: "",
  });

  // State to manage error messages for each action
  const [actionError, setActionError] = useState({
    setWebSocketSession: "",
    setCookiesHeaders: "",
  });

  // State to manage URL inputs for each action
  const [webSocketURL, setWebSocketURL] = useState("");
  const [cookiesHeadersURL, setCookiesHeadersURL] = useState("");

  // Function to set WebSocket Session ID
  const setWebSocketSession = async () => {
    // Reset previous messages and set loading state
    setActionLoading((prev) => ({ ...prev, setWebSocketSession: true }));
    setActionError((prev) => ({ ...prev, setWebSocketSession: "" }));
    setActionSuccess((prev) => ({ ...prev, setWebSocketSession: "" }));

    try {
      // Payload includes the WebSocket Session URL
      const payload = {
        url: webSocketURL,
      };
      console.log("set_websocket_session_id payload: ", payload);

      const res = await callAPI("POST", "set_websocket_session_id", payload);

      // Check if the response is successful
      if (!res || res.ok === false) {
        throw new Error("Failed to set WebSocket session ID.");
      }

      console.log("WebSocket session set:", res);

      // Set success message
      setActionSuccess((prev) => ({
        ...prev,
        setWebSocketSession: "WebSocket session ID set successfully.",
      }));
    } catch (error) {
      // Set error message
      setActionError((prev) => ({
        ...prev,
        setWebSocketSession: error.message || "An error occurred.",
      }));
    } finally {
      // Reset loading state
      setActionLoading((prev) => ({
        ...prev,
        setWebSocketSession: false,
      }));
    }
  };

  // Function to send Cookies and Headers to Node.js
  const setCookiesHeaders = async () => {
    // Reset previous messages and set loading state
    setActionLoading((prev) => ({ ...prev, setCookiesHeaders: true }));
    setActionError((prev) => ({ ...prev, setCookiesHeaders: "" }));
    setActionSuccess((prev) => ({ ...prev, setCookiesHeaders: "" }));

    try {
      // Payload includes the Cookies and Headers URL
      const payload = {
        url: cookiesHeadersURL,
      };
      console.log("set_cookies_headers payload: ", payload);

      const res = await callAPI("POST", "set_cookies_headers", payload);

      // Check if the response is successful
      if (!res || res.ok === false) {
        throw new Error("Failed to send cookies and headers to Node.js.");
      }

      console.log("Cookies and headers sent:", res);

      // Set success message
      setActionSuccess((prev) => ({
        ...prev,
        setCookiesHeaders: "Cookies and headers sent successfully.",
      }));
    } catch (error) {
      // Set error message
      setActionError((prev) => ({
        ...prev,
        setCookiesHeaders: error.message || "An error occurred.",
      }));
    } finally {
      // Reset loading state
      setActionLoading((prev) => ({
        ...prev,
        setCookiesHeaders: false,
      }));
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 600,
        margin: "auto",
        borderRadius: "10px",
        padding: 2,
        marginTop: 4,
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Virtual Extension Actions
        </Typography>

        {/* Divider for visual separation */}
        <Divider sx={{ my: 2 }} />

        {/* Action Buttons */}
        <Box display="flex" flexDirection="column" gap={4}>
          {/* Set WebSocket Session Section */}
          <Box>
            <Typography variant="h6" gutterBottom>
              Set WebSocket Session ID
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="WebSocket Session URL"
                variant="outlined"
                fullWidth
                value={webSocketURL}
                onChange={(e) => setWebSocketURL(e.target.value)}
                required
                placeholder="Enter WebSocket Session URL"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={setWebSocketSession}
                disabled={
                  actionLoading.setWebSocketSession ||
                  webSocketURL.trim() === ""
                }
                startIcon={
                  actionLoading.setWebSocketSession && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
                fullWidth
              >
                {actionLoading.setWebSocketSession
                  ? "Setting WebSocket Session..."
                  : "Set WebSocket Session"}
              </Button>
              {/* Success and Error Messages */}
              {actionSuccess.setWebSocketSession && (
                <Alert severity="success">
                  {actionSuccess.setWebSocketSession}
                </Alert>
              )}
              {actionError.setWebSocketSession && (
                <Alert severity="error">
                  {actionError.setWebSocketSession}
                </Alert>
              )}
            </Box>
          </Box>

          {/* Set Cookies Headers Section */}
          <Box>
            <Typography variant="h6" gutterBottom>
              Send Cookies + Headers to Node.js
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Cookies and Headers URL"
                variant="outlined"
                fullWidth
                value={cookiesHeadersURL}
                onChange={(e) => setCookiesHeadersURL(e.target.value)}
                required
                placeholder="Enter Cookies and Headers URL"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={setCookiesHeaders}
                disabled={
                  actionLoading.setCookiesHeaders ||
                  cookiesHeadersURL.trim() === ""
                }
                startIcon={
                  actionLoading.setCookiesHeaders && (
                    <CircularProgress size={20} color="inherit" />
                  )
                }
                fullWidth
              >
                {actionLoading.setCookiesHeaders
                  ? "Sending Cookies + Headers..."
                  : "Send Cookies + Headers to Node.js"}
              </Button>
              {/* Success and Error Messages */}
              {actionSuccess.setCookiesHeaders && (
                <Alert severity="success">
                  {actionSuccess.setCookiesHeaders}
                </Alert>
              )}
              {actionError.setCookiesHeaders && (
                <Alert severity="error">{actionError.setCookiesHeaders}</Alert>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
