import React, { useCallback } from "react";
import { ThreadProvider, useThread } from "../../contexts/ThreadContext";

import { Box, Skeleton } from "@mui/material";
import { Counters } from "./Counters";
import { Footer } from "./Footer";
import { MessageBody } from "./MessageBody";
import { ThreadStatus } from "./ThreadStatus";
import { AutomationStatus } from "./AutomationStatus";
import { Header } from "./Header";
import styles from "./ThreadCard.module.scss";

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.threadPID === nextProps.threadPID &&
    prevProps.automationStatus === nextProps.automationStatus
  );
}

const ThreadCardContent = () => {
  const { threadData, currentSteps, automationStatus } = useThread();
  const threadStatus = useCallback(
    () => <ThreadStatus />,
    [threadData.insights, threadData.messages],
  );

  const footer = useCallback(
    () => currentSteps && <Footer />,
    [
      threadData.completedSteps,
      threadData.messages,
      threadData.msgIndex,
      currentSteps,
    ],
  );

  const messageBody = useCallback(
    () => (threadData.messages.length > 0 ? <MessageBody /> : null),
    [threadData.messages, threadData.msgIndex],
  );

  return threadData.pid.get() ? (
    <Box className={styles.threadCard} id={threadData.pid.get()} mb={2}>
      <Counters />
      <Box className={styles.threadHeader}>
        <Header />
      </Box>
      <Box className={styles.threadBody}>
        {threadStatus()}
        {messageBody()}
      </Box>
      {automationStatus && automationStatus.get() === "running" ? (
        <AutomationStatus />
      ) : (
        footer()
      )}
    </Box>
  ) : (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={"100px"}
      animation="wave"
      sx={{
        borderRadius: 8,
      }}
    />
  );
};

interface IThreadCardProps {
  threadPID: string;
  automationStatus?: string;
}

export const ThreadCard = React.memo<IThreadCardProps>(
  ({ threadPID, automationStatus }) => {
    return (
      threadPID && (
        <ThreadProvider
          children={<ThreadCardContent />}
          threadPID={threadPID}
          automationStatus={automationStatus}
        ></ThreadProvider>
      )
    );
  },
  arePropsEqual,
);
