import { useState, useEffect } from "react";
import { callAPI } from "../../../api";

export const useSequences = (userData, setUserData) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSequences = async () => {
      setLoading(true);
      try {
        const sequences = await callAPI("GET", "/sequences");
        setUserData((prev) => ({ ...prev, sequences }));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (!userData.sequences) {
      loadSequences();
    }
  }, [userData.sequences, setUserData]);

  return loading;
};
