import { styled, Theme } from "@mui/material/styles";
import { TextareaAutosize } from "@mui/material";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }: { theme: Theme }) => ({
    ...theme.typography.body1,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: "100%",
    maxHeight: "calc(100% - 600px)",
    fontFamily: theme.typography.fontFamily,
    overflowY: "auto",
    fontSize: theme.typography.fontSize,
    "&:focus": {
      outline: "none",
      borderColor: theme.palette.primary.main,
    },
  }),
);

export default StyledTextarea;
