import sanitizeHtml from "sanitize-html";

export const RichTextEditor = ({ contentRef, disabled = false, ...props }) => {
  const handleInput = (e) => {
    if (disabled) return;
    // // Sanitize the HTML before setting it
    const sanitizedHtml = sanitizeHtml(e.currentTarget.innerHTML, {
      // Options for what to allow; adjust as necessary
      allowedTags: false,
      allowedAttributes: false,
      allowVulnerableTags: true,
    });
    contentRef.current = sanitizedHtml;
  };

  return (
    <div
      contentEditable={!disabled}
      onInput={handleInput}
      dangerouslySetInnerHTML={{ __html: contentRef.current }}
      {...props}
    />
  );
};
