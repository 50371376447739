import { useCallback, useState } from "react";
import { callAPI } from "../../api";
import { useThread } from "../../contexts/ThreadContext";
import styles from "./ThreadCard.module.scss";
import { Box, CircularProgress, TextField, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";

export const AiAssistantDialog = () => {
  const [messageToAI, setMessageToAI] = useState(null);
  const [loading, setLoading] = useState(false);
  const { currentMessage, currentSteps, lead } = useThread();

  const parseHistory = (history) => {
    if (!history) {
      return [];
    }
    if (Array.isArray(history)) {
      return history;
    }
    return JSON.parse(history);
  };

  const sendMessageToAI = async () => {
    // Used for sending messages to AI
    if (!messageToAI) {
      return;
    }
    setLoading(true);
    const message = await callAPI("POST", "llm/improve-message", {
      leadPID: lead.pid,
      messagePID: currentMessage.pid.get(),
      userFeedback: messageToAI,
      stepPID: currentSteps.find(
        (step) => step.messageType === currentMessage.type.get(),
      ).pid,
    });
    console.log(message);
    currentMessage.set(message);
    setMessageToAI("");
    setLoading(false);
  };
  //Could you please add personalisation to this message and make it more resonating with Jordan?

  const renderHistory = useCallback(() => {
    if (!currentMessage.history.get()) return null;
    return (
      currentMessage.history.get().length > 0 && (
        <Box className={styles.messageHistoryContainer}>
          {parseHistory(currentMessage.history.get()).map((m, idx) => (
            <Box
              className={
                m.role === "user"
                  ? styles.aiUserMessage
                  : styles.aiAssistantMessage
              }
              key={idx}
            >
              <Box>
                {m.role === "user" ? (
                  <p>{m.content.split("\n\n---\n\n")[0]}</p>
                ) : (
                  <p>{JSON.parse(m.content).message}</p>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )
    );
  }, [currentMessage.history.get()]);

  return (
    <Box className={styles.aiAssistantContainer}>
      {renderHistory()}
      {!loading ? (
        <Box className={styles.aiAssistantInputContainer}>
          <TextField
            id="ai-chat-input"
            className={styles.aiAssistantInput}
            size="small"
            multiline
            sx={{ textarea: { lineHeight: 1.1, fontSize: "12px" } }}
            minRows={2}
            maxRows={3}
            value={messageToAI || ""}
            placeholder="Type your request to Bella here, and she will adjust the message accordingly. Turn the AI mode off to send the final message to the prospect."
            onChange={(e) => setMessageToAI(e.target.value)}
          />
          <IconButton
            className={styles.aiAssistantInputSend}
            color="primary"
            aria-label="send"
            size="small"
            onClick={() => sendMessageToAI()}
          >
            <Send />
          </IconButton>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
