import { useState, useEffect, useRef } from "react";
import { Box, Typography, List } from "@mui/material";
import styles from "../Profile.module.scss";
import { useUser } from "../../../contexts/UserContext";
import { useSequences } from "./useSequences";
import { useChatbotConfig } from "./useChatbotConfig";
import StepCheckbox from "./StepCheckbox";

export const GettingStarted = () => {
  const { userData, setUserData } = useUser();
  const chatbotConfig = useChatbotConfig();
  useSequences(userData, setUserData);

  const steps = [
    {
      text: "Install Browser Extension and Extract data from profile",
      url: "#",
      checked: !!userData.linkedinObjectUrn,
      tooltip:
        "This will be ticked once you have connected and extracted your profile data from LinkedIn and LinkedIn sales navigator.",
      videoUrl: "https://www.loom.com/embed/a6283085961842db809fb36bb5c4e011",
    },
    {
      text: "Connect email",
      url: "#",
      checked: !!userData.emailProvider,
      tooltip:
        "This will be ticked once you have connected to gmail or outlook in the integrations section.",
      videoUrl: "https://www.loom.com/embed/cdad66483c2f4e008bee6e6c455b75f3",
    },
    {
      text: "Add products & services, case studies",
      url: "#",
      checked:
        userData.services?.length > 0 && userData.caseStudies?.length > 0,
      tooltip:
        "This will be ticked once you have added in your case studies and description of products and services.",
      videoUrl: "https://www.loom.com/embed/03658751f71b4782ab7c9544d64046da",
    },
    {
      text: "Create a Sequence",
      url: "#",
      checked: userData.sequences?.length > 0,
      tooltip: "This will be ticked once you have created a sequence.",
      videoUrl: "https://www.loom.com/embed/6097c0d4395d4098bb4d9f64d1133cfa",
    },
    {
      text: "Create a Campaign",
      url: "#",
      checked: userData.campaigns?.length > 0,
      tooltip: "This will be ticked once you have created a campaign.",
      videoUrl: "https://www.loom.com/embed/8ab93b98a66d4b36a525f750ade4af04",
    },
    {
      text: "Configure your chatbot",
      url: "#",
      checked: !!chatbotConfig,
      tooltip:
        "This will be ticked once you have configured a chatbot in chatbot settings.",
      videoUrl: "https://www.loom.com/embed/7abb5cc4d4014fdeb7ca841e0defaacd",
    },
    {
      text: "Operation",
      url: "#",
      checked: userData.campaigns?.some(
        (campaign) => campaign.threads?.length > 0,
      ),
      tooltip:
        "This will be ticked once you have imported leads into a campaign and sent a connection request msg.",
    },
  ];

  const [checkedSteps, setCheckedSteps] = useState(
    steps.map((step) => step.checked),
  );

  const [showArrow, setShowArrow] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCheckedSteps(steps.map((step) => step.checked));
  }, [userData, chatbotConfig]);

  // Handle scroll event and hide arrow when user scrolls
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop } = containerRef.current;
      if (scrollTop > 0) {
        setShowArrow(false); // Hide arrow when user scrolls
      } else {
        setShowArrow(true); // Show arrow when at the top
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleToggle = (index) => {
    const newCheckedSteps = [...checkedSteps];
    newCheckedSteps[index] = !newCheckedSteps[index];
    setCheckedSteps(newCheckedSteps);
  };

  const completedSteps = checkedSteps.filter(Boolean).length;

  return (
    <Box className={styles.displayContainer} ref={containerRef}>
      <Box className={styles.displayContainerHeader}>
        <Typography variant="h6" gutterBottom>
          Getting started ({completedSteps}/{steps.length} complete):
        </Typography>
      </Box>
      <Box className={styles.displayContainerBody}>
        <List>
          {steps.map((step, index) => (
            <StepCheckbox
              key={index}
              checked={checkedSteps[index]}
              onToggle={() => handleToggle(index)}
              text={`${index + 1}. ${step.text}`}
              url={step.url}
              tooltip={step.tooltip}
              videoUrl={step.videoUrl}
              steps={steps}
            />
          ))}
        </List>
      </Box>
      {showArrow && <div className={styles.scrollDownArrow}></div>}
    </Box>
  );
};
