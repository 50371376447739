import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useHookstate } from "@hookstate/core";
import { callAPI, callExtension } from "../api";
import { useUser } from "./UserContext";
import { Sequence, Campaign } from "../components/types";
import { isEqual } from "lodash";

export const CampaignContext = createContext(null);

export const CampaignProvider = ({ children, campaignPID }) => {
  const { userData, setUserData } = useUser();
  const campaignData = useHookstate({} as Campaign);
  const [leadsList, setLeadsList] = useState(null);
  const [newLeads, setNewLeads] = useState([]);
  const [newLeadsLoading, setNewLeadsLoading] = useState(false);
  const sequence = useHookstate({} as Sequence);

  // const loadData = useCallback(async () => {
  //   const res = await callAPI("GET", "campaign", {
  //     campaignPID: campaignPID,
  //   });
  //   console.log("res loadData in campaignProvider: ", res);
  //   campaignData.set(res.campaign);
  //   sequence.set(res.sequence);
  //   console.log("campaignData after loadData: ", campaignData)
  // }, [campaignPID]);

  const loadData = useCallback(async () => {
    // Call API to get the new data
    const res = await callAPI("GET", "campaign", {
      campaignPID: campaignPID,
    });

    // Compare and update campaignData if different
    if (!isEqual(campaignData.get(), res.campaign)) {
      console.log("campaignData has changed, updating...");
      campaignData.set(res.campaign);
    }

    // Compare and update sequence if different
    if (!isEqual(sequence.get(), res.sequence)) {
      sequence.set(res.sequence);
    }
  }, [campaignPID, campaignData, sequence]);

  const getAutomationStatus = async () => {
    campaignPID = campaignData.pid.get();

    try {
      const res = await callAPI("POST", "automation_status", {
        campaignPID: campaignPID,
      });
      return res;
    } catch (error) {
      console.error("Error fetching automation status:", error);
    }
  };

  const fetchLeadListLimit = async () => {
    const leadsListPID = campaignData.leadsListPID.get();

    try {
      const res = await callAPI("POST", "leads/leadlist_automation_limit", {
        leadlist_pid: leadsListPID,
      });
      return res;
    } catch (error) {
      console.error("Error fetching automation status:", error);
    }
  };

  const fetchUserLimit = async () => {
    try {
      const res = await callAPI("GET", "/user_cr_automation_limit", {});
      return res;
    } catch (error) {
      console.error("Error fetching automation status:", error);
    }
  };

  const refreshCampaignApiToken = async () => {
    campaignPID = campaignData.pid.get();

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Token not found in local storage.");
      }

      const res = await callAPI("POST", "refresh_campaign_api_token", {
        // campaignPID: campaignPID,
        // token: token,
      });
      return res;
    } catch (error) {
      console.error("Error refreshing campaign API token:", error);
    }
  };

  useEffect(() => {
    if (!campaignData.leadsListPID.get()) return;
    const loadListProfiles = async () => {
      setNewLeadsLoading(true);
      const existingList = await callAPI("GET", "leads", {
        leadListPID: campaignData.leadsListPID.get(),
      });
      setLeadsList(existingList);
      if (!existingList?.lisnId) {
        setNewLeads([]);
        return;
      } else {
        const data = await callExtension("LISN_fetchListProfiles", {
          lisnId: existingList.lisnId,
        });
        setNewLeads(data);
      }
      setNewLeadsLoading(false);
    };
    loadListProfiles();
  }, [campaignData.pid]);

  useEffect(() => {
    if (!campaignPID) return; // needs to load data
    loadData();
  }, [campaignPID, loadData]);

  // !State
  const changeThreadVisibility = (threadPID, hidden) => {
    campaignData.threads
      .find((t) => t.pid.get() === threadPID)
      .hidden.set(hidden);
  };
  const updateCampaign = async (props) => {
    const res = await callAPI("PUT", "campaign", {
      campaignPID: campaignData.pid.get(),
      ...props,
    });
    campaignData.set(res.campaign);
    sequence.set(res.sequence);
    setUserData((prev) => ({
      ...prev,
      campaigns: prev.campaigns.map((c) => (c.pid === res.pid ? res : c)),
    }));
  };

  const postLead = async (leadData) => {
    const res = await callAPI("POST", "/lead", {
      ...leadData,
      leadsListPID: leadsList.pid,
    });
    if (res.success) {
      setUserData((prev) => ({
        ...prev,
        totalLeads: res.leadsCount,
        usedMonthlyCredits: res.usedMonthlyCredits,
      }));
    }

    const remaining_monthly_creds =
      userData.leadCredits - userData.usedMonthlyCredits;
    return remaining_monthly_creds;
  };

  // !Automation

  // const runAutomation = () => {
  //   // Triggers the automation
  //   console.log("Initiating automation");
  //   campaignData.automationRunning.set(true);
  //   campaignData.threads.set((threads) =>
  //     threads.map((t) => ({
  //       ...t,
  //       automationStatus: !t.hidden ? "queued" : "ignored",
  //     })),
  //   );
  //   nextAutomation();
  // };

  const runAutomation = async () => {
    const campaignPID = campaignData.pid.get();
    const token = localStorage.getItem("token");
    const automation_status = await getAutomationStatus();
    const newStatus =
      automation_status.status === "running" ? "paused" : "running";

    if (!campaignPID) {
      console.error("Campaign PID or Token is missing");
      return;
    }

    const res = await callAPI("POST", "start_pause_resume_automation", {
      campaignPID: campaignPID,
      token: token,
      status: automation_status.status,
    });

    if (res.success) {
      return { ...automation_status, status: newStatus };
    } else {
      console.error("Failed to update automation status");
      return automation_status; // Return the current status if the update fails
    }
  };

  const nextAutomation = () => {
    const nextThread = campaignData.threads.find(
      (t) => t.automationStatus.get() === "queued",
    );
    if (nextThread) {
      nextThread.automationStatus.set("running");
    } else {
      stopAutomation();
    }
  };

  const stopAutomation = () => {
    // Stops the automation
    campaignData.automationRunning.set(false);
  };

  const automationProgress =
    campaignData?.threads?.length > 0 &&
    campaignData.threads
      .get()
      .filter((t) => ["failed", "finished"].includes(t.automationStatus))
      .length / campaignData.threads.get().filter((t) => !t.hidden).length;

  return (
    <CampaignContext.Provider
      value={{
        campaignData,
        sequence,
        changeThreadVisibility,
        loadData,
        postLead,
        leadsList,
        newLeads,
        setNewLeads,
        newLeadsLoading,
        updateCampaign,
        runAutomation,
        stopAutomation,
        nextAutomation,
        automationProgress,
        getAutomationStatus,
        refreshCampaignApiToken,
        fetchUserLimit,
        fetchLeadListLimit,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaign = () => useContext(CampaignContext);
