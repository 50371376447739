import { useState, useEffect } from "react";
import { callAPI } from "../../api";
import { useUser } from "../../contexts/UserContext";
import { SequenceBuilder } from "./SequenceBuilder";
import { SidePanel } from "../../components/SidePanel";
import {
  Box,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";

import { KeyboardArrowLeft, Delete } from "@mui/icons-material";
import { Sequence } from "../../components/types";
import styles from "./Sequences.module.scss";

export const Sequences = () => {
  const { userData, setUserData } = useUser();
  const theme = useTheme();
  const [selectedSequence, setSelectedSequence] = useState<Sequence>(null);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const loadSequences = async () => {
      setLoading(true);
      try {
        const sequences = await callAPI("GET", "/sequences");
        setUserData((prev) => ({ ...prev, sequences: sequences }));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    if (!userData.sequences) {
      loadSequences();
    }
  }, [userData.sequences]);

  const deleteSequence = async (pid: string) => {
    setLoading(true);

    const sequenceToDelete = userData.sequences.find(
      (seq: Sequence) => seq.pid === pid,
    );

    if (sequenceToDelete.campaignsCount > 0) {
      console.log(
        "You cannot delete this sequence because it has campaigns associated with it.",
      );
      setSnackbarMessage(
        "You cannot delete this sequence because it has campaigns associated with it.",
      );
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    try {
      await callAPI("DELETE", `sequence`, { sequencePID: pid });
      setUserData((prev) => ({
        ...prev,
        sequences: prev.sequences.filter((seq: Sequence) => seq.pid !== pid),
      }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 150px)",
      }}
    >
      <SidePanel
        addButtonOnClick={
          selectedSequence ? null : () => setSelectedSequence({} as Sequence)
        }
      />
      <Box display="flex" flexDirection="row" alignItems="center" p={1}>
        {selectedSequence && (
          <IconButton onClick={() => setSelectedSequence(null)}>
            <KeyboardArrowLeft />
          </IconButton>
        )}
        <Typography variant="h3">
          {selectedSequence ? (
            <span>
              <b>Sequence Builder: </b>{" "}
              {selectedSequence?.name || "New Sequence"}
            </span>
          ) : (
            "Sequences"
          )}
        </Typography>
      </Box>

      {selectedSequence ? (
        <Box
          flexGrow={1}
          sx={{
            border: `3px ${theme.palette.primary.main} solid`,
            backgroundColor: theme.palette.background.paper,
            borderRadius: "5px",
          }}
        >
          <SequenceBuilder
            sequence={selectedSequence}
            exit={() => setSelectedSequence(null)}
          />
        </Box>
      ) : (
        <List>
          {!loading
            ? userData.sequences?.map((sequence: Sequence) => (
                <ListItem
                  key={sequence.pid}
                  disablePadding
                  onClick={() => setSelectedSequence(sequence)}
                  className={styles.sequenceListItem}
                  sx={{
                    marginBottom: "1rem",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ListItemText
                    primary={sequence.name}
                    sx={{ span: { fontSize: "1.2rem" }, flex: 2 }}
                  />
                  <ListItemText
                    primary={`${sequence.stepsCount} steps`}
                    sx={{ flex: 1 }}
                  />
                  <ListItemText
                    primary={`${sequence.campaignsCount} campaigns`}
                    sx={{ flex: 1 }}
                  />
                  <Typography></Typography>
                  <ListItemSecondaryAction sx={{ display: "flex", flex: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => setSelectedSequence(sequence)}
                      sx={{ marginRight: 2 }}
                    >
                      Modify
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteSequence(sequence.pid)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            : Array.from([1, 2]).map((i) => (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  width={"100%"}
                  height={"50px"}
                  animation="wave"
                  sx={{
                    borderRadius: 8,
                    marginBottom: "1rem",
                  }}
                />
              ))}
        </List>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
