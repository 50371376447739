import { useState, useEffect, FC } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { callAPI } from "../../api";
import { useUser } from "../../contexts/UserContext";
import { Lead, Thread, IChatbotConfig, IMessage } from "./types";
import { Helper } from "../../components/Helper";

interface ChatbotSimulatorProps {
  exampleOutreachMessage: string;
}

const ChatbotSimulator: FC<ChatbotSimulatorProps> = ({
  exampleOutreachMessage,
}) => {
  const [conversation, setConversation] = useState<IMessage[]>([]);
  const [inputText, setInputText] = useState("");
  const [simulationInput, setSimulationInput] = useState(
    exampleOutreachMessage,
  );
  const [selectedLead, setSelectedLead] = useState("");
  const [leads, setLeads] = useState<Lead[]>([]);
  const [aiChatbotReplyRequired, setAiChatbotReplyRequired] = useState(true);
  const [responseAggressiveness, setResponseAggressiveness] =
    useState("conservative");

  const { userData } = useUser();
  const [validator, setValidator] = useState(true);

  useEffect(() => {
    if (!aiChatbotReplyRequired) {
      setResponseAggressiveness("conservative");
    }
  }, [aiChatbotReplyRequired]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const res = await callAPI("GET", "all_user_leads");
        setLeads(
          res.map(
            ({
              lead,
              thread,
              chatbot_config,
            }: {
              lead: Lead;
              thread: Thread;
              chatbot_config: IChatbotConfig;
            }) => ({
              ...lead,
              thread,
              chatbot_config,
              name: `${lead.firstName} ${lead.lastName}`,
            }),
          ),
        );
      } catch (error) {
        console.error("Failed to fetch leads:", error);
      }
    };

    fetchLeads();
  }, []);

  const handleStartSimulation = async () => {
    const lead = leads.find((lead) => lead.pid === selectedLead);
    if (!lead) return;

    try {
      const res = await callAPI(
        "POST",
        `/llm/generate_outreach_message_for_chatbot_simulator`,
        {
          leadPID: lead.pid,
          type: "cr_chatbot_sim",
          exampleOutreachMessage: simulationInput,
          chatbotConfigPID: lead.chatbot_config.pid,
        },
      );

      setConversation([
        ...conversation,
        { idx: conversation.length, from: "bella", text: res.content },
      ]);
    } catch (error) {
      console.error("Error during simulation:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && inputText.trim() !== "") {
        event.preventDefault();
        handleSend();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputText]);

  const handleSend = async () => {
    setConversation([
      ...conversation,
      { idx: conversation.length, from: "me", text: inputText },
    ]);
    setInputText("");

    const lead = leads.find((lead) => lead.pid === selectedLead);
    if (!lead) return;

    // console.log(`
    //   Message: ${inputText},
    //   Thread PID: ${lead.thread.pid},
    //   Chatbot Config PID: ${lead.chatbot_config.pid},
    //   AI Chatbot Reply Required: ${aiChatbotReplyRequired ? "True" : "False"},
    //   Response Aggressiveness: ${responseAggressiveness},
    //   Validate: ${validator ? "True" : "False"}
    // `);

    try {
      const res = await callAPI(
        "POST",
        "llm/generate_reply_for_chatbot_simulator",
        {
          message: inputText,
          threadPID: lead.thread.pid,
          chatbotConfigPID: lead.chatbot_config.pid,
          aiChatbotReplyRequired: aiChatbotReplyRequired ? "True" : "False",
          response_aggressiveness: responseAggressiveness,
          validate: validator ? "True" : "False",
        },
      );

      if (res.reply_required === "False") {
        setAiChatbotReplyRequired(false);
        setConversation((prev) => [
          ...prev,
          {
            idx: prev.length,
            from: "bella",
            text: "SYSTEM NOTIFICATION: SMART AI REPLY Determined that a reply was not required for the previous msg",
          },
        ]);
      } else {
        setConversation((prev) => [
          ...prev,
          { idx: prev.length, from: "bella", text: res.content },
        ]);
      }
    } catch (error) {
      console.error("Failed to generate message:", error);
    }
  };

  const handleReset = async () => {
    const lead = leads.find((lead) => lead.pid === selectedLead);
    if (!lead) return;

    try {
      const res = await callAPI("POST", "thread/chatbot_simulator_messages", {
        threadPID: lead.thread.pid,
      });

      if (res.status == "success") {
        console.log("Conversation reset successfully");
      }
      setAiChatbotReplyRequired(true);
      setConversation([]);
      setInputText("");
      setSelectedLead("");
    } catch (error) {
      console.error("Failed to reset conversation:", error);
    }
  };

  // const handleResponseAggressivenessChange = () => {
  //   setResponseAggressiveness((prev) =>
  //     prev === "conservative" ? "aggressive" : "conservative",
  //   );
  // };

  return (
    <Box p={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">Chatbot Simulator</Typography>
        <FormControl component="fieldset">
          <Box display="flex" alignItems="center">
            <Tooltip
              title="Use this button to turn the Smart Chatbot reply on and off, if turned off, Bella Chatbot will continue to reply to a lead indefinitely.
            If turned on, then Bella will decide based on the conversation history, whether to reply or not."
            >
              <Typography variant="body2" color="textSecondary">
                Smart AI Reply on/off (BETA FEATURE)
              </Typography>
            </Tooltip>
            <Switch
              checked={aiChatbotReplyRequired}
              onChange={() => setAiChatbotReplyRequired((prev) => !prev)}
              color="primary"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Tooltip title="Use this button to turn on and off the smart validator function which double checks that the llm has followed the custom instructions.">
              <Typography variant="body2" color="textSecondary">
                Smart Validator (BETA FEATURE)
              </Typography>
            </Tooltip>
            <Switch
              checked={validator}
              onChange={() => setValidator((prev) => !prev)}
              color="primary"
            />
          </Box>
        </FormControl>
        {/* {aiChatbotReplyRequired && (
          <FormControl component="fieldset">
            <Box display="flex" alignItems="center" ml={2}>
              <Tooltip title="Use this switch to control how aggressive the AI powered reply decision maker behaves. Aggressive means that the chatbot will stay on for longer. Conservative means if in doubt, it will turn the chatbot off.">
                <Typography variant="body2" color="textSecondary">
                  Conservative
                </Typography>
              </Tooltip>
              <Switch
                checked={responseAggressiveness === "aggressive"}
                onChange={handleResponseAggressivenessChange}
                color="primary"
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#1976d2", // Same color for both states
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "#e0e0e0", // Same color for both states
                  },
                }}
              />
              <Typography variant="body2" color="textSecondary">
                Aggressive
              </Typography>
            </Box>
          </FormControl>
        )} */}
      </Box>
      <Typography variant="body2" p={1} color="primary">
        Test your chatbot setup by engaging with Bella, simulating a
        conversation as one of your leads.
      </Typography>
      <Typography variant="body2" p={1} color="primary">
        Instructions to simulate a conversation:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body2" p={1} color="primary">
                1. Start by selecting a lead from your campaign.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body2" p={1} color="primary">
                2. Click "Start Simulation."
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography variant="body2" p={1} color="primary">
                3. Act as if you are that lead, interacting with Bella as
                configured.
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Typography variant="body2" p={1} color="primary">
        Bella will respond as if she's representing your LinkedIn profile.
        Simulate typical questions or conversations you expect from leads.
      </Typography>
      <Typography variant="body2" p={1} color="primary">
        Continuously refine your custom instructions, FAQs, and example
        conversations based on your interactions.
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} mt={2}>
        <FormControl fullWidth>
          <InputLabel>Lead</InputLabel>
          <Helper title="Select a lead you wish to impersonate for this simulation.">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Select
                value={selectedLead}
                onChange={(e) => setSelectedLead(e.target.value)}
                label="Lead"
                fullWidth
              >
                {leads.map((lead, index) => (
                  <MenuItem key={`${lead.pid}-${index}`} value={lead.pid}>
                    {lead.name}
                  </MenuItem>
                ))}
              </Select>
              <IconButton color="primary" sx={{ marginLeft: 1 }}>
                <HelpOutline />
              </IconButton>
            </Box>
          </Helper>
        </FormControl>
        <Helper title="This is optional. This input is used as an example to Bella when generating an outreach message to send to lead. eg. Hi John, I wanted to connect because we are in similar industry. Hope you don't mind.">
          <TextField
            label="Example Outreach Message, when you START SIMULATION, this will be saved to your config."
            variant="outlined"
            fullWidth
            value={simulationInput || ""}
            onChange={(e) => setSimulationInput(e.target.value)}
          />
        </Helper>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStartSimulation}
            disabled={!selectedLead}
            fullWidth
          >
            Start Simulation
          </Button>
        </Box>
        <Box p={1} overflow="auto" flexGrow={1} maxHeight="1500px">
          {conversation.map((msg) => {
            const lead = leads.find((lead) => lead.pid === selectedLead);
            return (
              <Box
                key={msg.idx}
                display="flex"
                justifyContent={msg.from === "me" ? "flex-end" : "flex-start"}
                mb={1}
              >
                <Box
                  bgcolor={msg.from === "me" ? "#E3F2FD" : "#F1F8E9"} // Light blue for user, light green for chatbot
                  color={msg.from === "me" ? "#1A237E" : "#2E7D32"} // Darker blue for user text, green for chatbot text
                  p={1}
                  borderRadius={5}
                  maxWidth="70%"
                >
                  <Typography variant="body2" color="textSecondary">
                    {msg.from === "me"
                      ? `You, replying on behalf of lead: ${lead?.name}`
                      : `Bella Chatbot on behalf of user: ${userData?.firstName} ${userData?.lastName}`}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {msg.text}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Type your reply here, as if you are pretending to be the lead..."
          />
          <Helper title="Interact with Bella as if you were one of your leads.">
            <IconButton color="primary">
              <HelpOutline />
            </IconButton>
          </Helper>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              disabled={!inputText}
            >
              Send Reply
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="outlined" color="secondary" onClick={handleReset}>
              Reset Simulation
            </Button>
            <Helper title="Make sure you reset your simulation after every configuration change.">
              <IconButton color="primary" sx={{ marginRight: 1 }}>
                <HelpOutline />
              </IconButton>
            </Helper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatbotSimulator;
