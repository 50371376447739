import { Box, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import {
  BaseEdge,
  EdgeProps,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "reactflow";

export const daySelectorTooltipTitle = (sourceHandleId) => {
  switch (sourceHandleId) {
    case "crAccepted":
      return "Days to wait after connection request is accepted";
    case "crNotAccepted":
      return "Days to wait for the connection request to be accepted";
    case "outreachRelied":
      return "Days to wait after the outreach message is replied";
    case "outreachIgnored":
      return "Days to wait for a reply";
    default:
      return "Delay (days) after the previous step";
  }
};

export const EdgeWithDaysSelector = (props: EdgeProps) => {
  const {
    source,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
  } = props;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { getEdge, setEdges } = useReactFlow();

  const edge = getEdge(props.id);
  const [days, setDays] = useState(edge?.data?.days || 0);

  const handleDaysChange = (event) => {
    const value = event.target.value;
    setDays(value);
    setEdges((eds) =>
      eds.map((e) => {
        if (e.id === props.id) {
          e.data = {
            ...e.data,
            days: value,
          };
        }
        return e;
      }),
    );
  };

  return (
    <>
      <BaseEdge
        markerEnd={markerEnd}
        path={edgePath}
        style={{
          stroke: props.sourceHandleId === "positive" ? "#3f51b5" : "#f44336",
          strokeWidth: props.selected ? 3 : 1.5,
        }}
        label="abc"
        labelShowBg={true}
      />
      {!source.startsWith("research") && (
        <EdgeLabelRenderer>
          <Box
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "auto",
              backgroundColor: "white",
              border: `1px solid ${props.sourceHandleId === "positive" ? "#3f51b5" : "#f44336"}`,
              borderRadius: 5,
              zIndex: 1500,
            }}
            p={1}
            width={50}
            onMouseDown={(e) => e.stopPropagation()} // Prevent event propagation
          >
            <Tooltip
              title={daySelectorTooltipTitle(props.sourceHandleId)}
              arrow
            >
              <TextField
                type="number"
                label="Days"
                variant="standard"
                size="small"
                value={days}
                onChange={handleDaysChange}
                InputProps={{
                  inputProps: {
                    max: 7,
                    min: 0,
                  },
                }}
              />
            </Tooltip>
          </Box>
        </EdgeLabelRenderer>
      )}
    </>
  );
};
