import { useEffect, useMemo, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { CampaignStats } from "./CampaignStats";
import {
  Paper,
  Box,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tableCellClasses,
  TablePagination,
  TableSortLabel,
  Typography,
  IconButton,
  Input,
} from "@mui/material";

import { Search, Delete, DeleteForever } from "@mui/icons-material";

import { Campaign } from "../../components/types";

import styles from "./Campaigns.module.scss";

import { visuallyHidden } from "@mui/utils";

import { styled } from "@mui/material/styles";

import { refreshCampaignApiToken } from "../Campaigns/RefreshCampaignApiToken";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffffff00",
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#5457fc11",
    border: "none",
  },
  // hide last border
  " td, th": {
    border: "none",
  },
  "&:hover": {
    backgroundColor: "#5457fc22",
  },
}));

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof Campaign>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Campaign;
  label: string;
  numeric: boolean;
  align?: "right" | "left" | "center";
  width?: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "title",
    numeric: false,
    label: "Title",
    width: 300,
  },
  {
    id: "status",
    numeric: false,
    label: "Stats",
    width: 200,
    align: "center",
  },
  {
    id: "leadsCount",
    numeric: true,
    label: "Leads",
    align: "right",
    width: 40,
  },
  {
    id: "createdAt",
    numeric: true,
    label: "Created",
    align: "right",
    width: 50,
  },
  {
    id: "updatedAt",
    numeric: true,
    label: "Updated",
    align: "right",
    width: 50,
  },
];

const searchable = ["title"];
const filterable = ["leadsCount", "createdAt", "updatedAt"];

const DeleteCampaignButton = (props: {
  pid: string;
  deleteCampaign: (string) => void;
}) => {
  const [confirm, setConfirm] = useState(false);

  const handleDelete = async () => {
    if (confirm) {
      props.deleteCampaign(props.pid);
    } else {
      setConfirm(true);
    }
    setTimeout(() => {
      setConfirm(false);
    }, 2000);
  };

  return confirm ? (
    <IconButton onClick={handleDelete} color="error" sx={{ zIndex: 500 }}>
      <DeleteForever />
    </IconButton>
  ) : (
    <IconButton onClick={handleDelete} color="warning" sx={{ zIndex: 500 }}>
      <Delete />
    </IconButton>
  );
};

export const CampaignsTable = (props: {
  setSelectedCampaign;
  deleteCampaign;
}) => {
  const { userData } = useUser();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Campaign>("createdAt");
  const [searchIn, setSearchIn] = useState<string | null>(null);
  const [searchString, setSearchString] = useState<string | null>(null);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [userData.campaigns]);

  useEffect(() => {
    refreshCampaignApiToken();
  }, []);

  const dateToString = (date: number) => {
    const d = new Date(date * 1000);
    return d.toLocaleDateString();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleRequestSort = (property: keyof Campaign) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Campaign) => () => {
    setSearchString(null);
    setSearchIn(null);
    handleRequestSort(property);
  };

  const emptyRows = useMemo(
    () =>
      page > 0
        ? Math.max(0, (1 + page) * rowsPerPage - userData.campaigns.length)
        : 0,
    [page, rowsPerPage, userData.campaigns?.length],
  );

  const filteredRows = useMemo(() => {
    if (!userData.campaigns) return [];
    if (searchIn && searchString?.length > 0) {
      return userData.campaigns.filter((c) =>
        c[searchIn].includes(searchString),
      );
    } else {
      return userData.campaigns;
    }
  }, [userData.campaigns, searchIn, searchString]);

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, filteredRows],
  );

  const selectCampaign = (pid: string) => {
    props.setSelectedCampaign(userData.campaigns.find((c) => c.pid === pid));
  };

  return (
    <Box className={styles.campaignsTable}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Campaigns
        </Typography>

        <TablePagination
          component="div"
          count={filteredRows.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ maxHeight: "70vh" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <StyledTableCell
                  key={headCell.id}
                  align={headCell.align || "left"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    width: headCell.width,
                    alignItems: "center",
                  }}
                >
                  {filterable.includes(headCell.id) ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}

                  {searchIn === headCell.id ? (
                    <Input
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontStyle: "italic",
                      }}
                      value={searchString || ""}
                      onChange={(e) => setSearchString(e.target.value)}
                      onBlur={() => {
                        if (searchString?.length === 0) setSearchIn(null);
                      }}
                      autoFocus
                    />
                  ) : (
                    searchable.includes(headCell.id) && (
                      <IconButton onClick={() => setSearchIn(headCell.id)}>
                        <Search
                          sx={{
                            color: "white",
                            fontSize: "14px",
                          }}
                        />
                      </IconButton>
                    )
                  )}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center" sx={{ width: 50 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <StyledTableRow key={row.pid}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  onClick={() => selectCampaign(row.pid)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="body1">{row.title}</Typography>
                </StyledTableCell>

                <StyledTableCell
                  sx={{
                    padding: 0,
                    zIndex: 5000,
                  }}
                >
                  <CampaignStats
                    threads={
                      row.threads ? row.threads.filter((t) => t.pid) : []
                    }
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.threads?.length || 0}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {dateToString(row.createdAt)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {dateToString(row.updatedAt)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <DeleteCampaignButton
                    pid={row.pid}
                    deleteCampaign={props.deleteCampaign}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
