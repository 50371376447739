import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Card,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { callAPI } from "../api";
import { useUser } from "../contexts/UserContext";

interface CouponDetails {
  percent_off?: number;
  amount_off?: number;
  duration?: string;
  duration_in_months?: number;
}

export const AffiliateModal = () => {
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [couponDetails, setCouponDetails] = useState<CouponDetails | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);
  const { userData } = useUser();

  useEffect(() => {
    if (userData.affiliateCode) {
      setPromoCode(userData.affiliateCode);
    }
    if (userData.couponDetails) {
      setCouponDetails(userData.couponDetails);
    }
  }, []);

  const requestAffiliateCode = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await callAPI("POST", "create_stripe_promo_code");

      const newPromoCode = response.promo_code.code;
      const newCouponDetails: CouponDetails = response.promo_code.coupon;

      setPromoCode(newPromoCode);
      setCouponDetails(newCouponDetails);
    } catch (error) {
      console.error("Error generating promo code:", error);
      setError(
        "An error occurred while generating your promo code. Please try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: "32px",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <AppBar position="static" sx={{ marginBottom: "16px" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Affiliate Program
          </Typography>
        </Toolbar>
      </AppBar>

      <Typography variant="body1" gutterBottom>
        Welcome to our Affiliate Program! By joining, you can earn commissions
        by sharing your unique promo code with others. Click the button below to
        generate your exclusive promo code.
      </Typography>

      <Button
        color="primary"
        variant="contained"
        onClick={requestAffiliateCode}
        disabled={loading || !!promoCode}
        fullWidth
        sx={{ marginBottom: "16px" }}
      >
        {loading ? <CircularProgress size={24} /> : "Generate Promo Code"}
      </Button>

      {promoCode && (
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "#f4f4f4",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Your Promo Code:
          </Typography>
          <Typography variant="h5" color="primary" gutterBottom>
            {promoCode}
          </Typography>
          {couponDetails && (
            <>
              <Typography variant="body2" gutterBottom>
                Discount:{" "}
                {couponDetails.percent_off
                  ? `${couponDetails.percent_off}% off`
                  : couponDetails.amount_off
                    ? `$${couponDetails.amount_off / 100} off`
                    : "No discount available"}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Validity:{" "}
                {couponDetails.duration === "forever"
                  ? "Forever"
                  : `${couponDetails.duration_in_months} months`}
              </Typography>
            </>
          )}
          <Typography variant="body2" sx={{ marginTop: "8px" }}>
            Share this code to earn commissions on each successful referral!
          </Typography>
        </Box>
      )}

      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{ marginTop: "16px", textAlign: "center" }}
        >
          {error}
        </Typography>
      )}
    </Card>
  );
};
