import { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { CampaignProvider } from "../../contexts/CampaignContext";
import { callAPI } from "../../api";
import { CampaignDisplay } from "./CampaignDisplay";
import { SidePanel } from "../../components/SidePanel";
import { NewCampaignDialog } from "../../components/NewCampaignDialog";
import { ImportLeadsToNavDialog } from "../../components/ImportLeadsToNav";
import { Helper } from "../../components/Helper";
import { CampaignsTable } from "./CampaignsTable";

import { Box, Skeleton } from "@mui/material";

import { Campaign } from "../../components/types";

export const Campaigns = () => {
  const { userData, setUserData } = useUser();
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>(null);
  const [loading, setLoading] = useState(true);
  const [showNewCampaignDialog, setShowNewCampaignDialog] = useState(false);
  const [showImportLeadsDialog, setShowImportLeadsDialog] = useState(false);

  const deleteCampaign = async (pid: string) => {
    const res = await callAPI("DELETE", "campaign", { campaignPID: pid });
    setUserData((prevUserData) => ({ ...prevUserData, campaigns: res }));
  };

  useEffect(() => {
    const loadCampaigns = async () => {
      setLoading(true);
      try {
        const res = await callAPI("GET", "campaigns");
        setUserData((prevUserData) => ({
          ...prevUserData,
          campaigns: res,
        }));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    loadCampaigns();
    setLoading(false);
  }, [setUserData]);

  return (
    <Box>
      {showNewCampaignDialog && (
        <NewCampaignDialog
          open={showNewCampaignDialog}
          onClose={() => setShowNewCampaignDialog(false)}
        />
      )}

      {showImportLeadsDialog && (
        <ImportLeadsToNavDialog
          open={showImportLeadsDialog}
          setOpen={setShowImportLeadsDialog}
        />
      )}

      <Helper
        title={userData.campaigns?.length === 0 && "Create your first campaign"}
        placement="left-start"
      >
        <SidePanel
          addButtonOnClick={
            selectedCampaign ? null : () => setShowNewCampaignDialog(true)
          }
          refreshButton={false}
          fileButtonOnClick={
            selectedCampaign ? null : () => setShowImportLeadsDialog(true)
          }
          linkedInButton={true}
        />
      </Helper>

      {selectedCampaign ? (
        <CampaignProvider campaignPID={selectedCampaign.pid}>
          <CampaignDisplay closeCampaign={() => setSelectedCampaign(null)} />
        </CampaignProvider>
      ) : !loading ? (
        <CampaignsTable
          setSelectedCampaign={setSelectedCampaign}
          deleteCampaign={deleteCampaign}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "3rem",
          }}
        >
          {Array.from([1, 2, 3]).map((i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              width={"100%"}
              height={"50px"}
              animation="wave"
              sx={{
                borderRadius: 8,
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
