import { useState } from "react";
import { callAPI } from "../../api";
import { useUser } from "../../contexts/UserContext";
import { Box, Radio, Divider, CircularProgress } from "@mui/material";
import { GeminiIcon, OpenAIIcon, AnthropicIcon } from "../Icons";

export const AIIntegration = () => {
  const { userData } = useUser();
  const [model, setModel] = useState(userData?.defaultModel);
  const [loading, setLoading] = useState(false);

  const changeModel = async (e) => {
    setLoading(true);
    try {
      const res = await callAPI("PUT", "ai_model", {
        model: e.target.value,
      });
      setModel(res.model);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    model && (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box>
              <Radio
                checked={model === "OPENAI"}
                onChange={changeModel}
                value="OPENAI"
                name="OPENAI-radio"
                inputProps={{ "aria-label": "OPENAI" }}
              />
              <OpenAIIcon style={{ width: "3rem" }} />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
              <Radio
                checked={model === "GOOGLE"}
                onChange={changeModel}
                value="GOOGLE"
                name="GOOGLE-radio"
                inputProps={{ "aria-label": "GOOGLE" }}
              />
              <GeminiIcon style={{ width: "3rem" }} />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
              <Radio
                checked={model === "CLAUDE"}
                onChange={changeModel}
                value="CLAUDE"
                name="CLAUDE-radio"
                inputProps={{ "aria-label": "CLAUDE" }}
              />
              <AnthropicIcon
                style={{
                  width: "48px",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    )
  );
};
