import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import "./Support.module.scss";
import { callAPI } from "../../api";

const UpdateUserDetails = () => {
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [userData, setUserData] = useState(null);
  const [pid, setPid] = useState(""); // State to store the pid
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleFetchUserData = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);
    try {
      console.log("calling api with: ", email, lastName);
      const res = await callAPI("POST", "update_user", { email, lastName });
      console.log(res);
      if (!res || res.ok === false) {
        if (res.status === 403) {
          throw new Error(
            "Unauthorized access: You do not have permission to use this feature, please contact support for assistance.",
          );
        }
        throw new Error("Failed to fetch user data.");
      }

      setUserData(res);
      setPid(res.pid);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);
    try {
      const res = await callAPI("PUT", "update_user", { ...userData, pid });
      if (!res || res.ok === false) {
        if (res.status === 403) {
          throw new Error(
            "Unauthorized access: You do not have permission to use this feature, please contact support for assistance.",
          );
        }
        throw new Error("Failed to fetch user data.");
      }
      console.log("updated user details: ", res);
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (email && lastName) {
      handleFetchUserData();
    }
  }, []);

  return (
    <Card
      sx={{ maxHeight: "80vh", overflow: "auto", padding: 2, marginTop: 2 }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Enter User Details to Load User
        </Typography>
        {success && (
          <Alert severity="success">
            Thank you! The User has been successfully updated, see console for
            details.
          </Alert>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          {!userData && (
            <>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleFetchUserData}
                >
                  {loading ? <CircularProgress size={20} /> : "Load User"}
                </Button>
              </Box>
            </>
          )}

          {userData && (
            <>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={userData.firstName || ""}
                  onChange={handleInputChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={userData.lastName || ""}
                  onChange={handleInputChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  name="email"
                  value={userData.email || ""}
                  onChange={handleInputChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Lead Credits"
                  variant="outlined"
                  name="leadCredits"
                  type="number"
                  value={userData.leadCredits || ""}
                  onChange={handleInputChange}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : "Save"}
                </Button>
              </Box>
            </>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default UpdateUserDetails;
