import { useState, FC } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import { Delete, DeleteForever, Edit } from "@mui/icons-material";
import NewConversationModal from "./NewConversationModal";

interface IMessage {
  idx: number;
  from: "me" | "prospect" | "bella";
  text: string;
}

interface ConversationAccordionProps {
  items: IMessage[];
  onDelete: () => void;
  onEdit: (conversation: IMessage[]) => Promise<void>;
}

const ConversationAccordion: FC<ConversationAccordionProps> = ({
  items,
  onDelete,
  onEdit,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [showEditConversationModal, setShowEditConversationModal] =
    useState(false);

  const handleMouseEnter = (index: number) => setExpandedIndex(index);
  const handleMouseLeave = () => setExpandedIndex(0);

  const handleDelete = () => {
    if (confirm) {
      onDelete();
    } else {
      setConfirm(true); // Set confirmation state

      setTimeout(() => setConfirm(false), 3000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      p={1}
    >
      {showEditConversationModal && (
        <NewConversationModal
          open={showEditConversationModal}
          setOpen={setShowEditConversationModal}
          onSave={onEdit}
          editConversation={items}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "row", width: "90%" }}>
        {items.map((item: IMessage) => (
          <Card
            key={item.idx}
            onMouseEnter={() => handleMouseEnter(item.idx)}
            onMouseLeave={handleMouseLeave}
            sx={{
              transition: "width 1s",
              width: expandedIndex === item.idx ? "100%" : 50,
              height: "80px",
              overflow: "hidden",
              bgcolor: item.from === "me" ? "#5457FC33" : "#1C602133",
            }}
          >
            <CardContent>
              {expandedIndex === item.idx && (
                <Typography variant="body2">
                  {item.text.length > 300
                    ? `${item.text.slice(0, 300)}...`
                    : item.text}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box display="flex" alignItems="center">
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <IconButton
            onClick={handleDelete}
            color={confirm ? "error" : "warning"}
          >
            {confirm ? <DeleteForever /> : <Delete />}
          </IconButton>
          <IconButton onClick={() => setShowEditConversationModal(true)}>
            <Edit />
          </IconButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default ConversationAccordion;
