import { Node, Edge, MarkerType } from "reactflow";
import { nodesInitData, generateNode } from "./types";

export const defaultNodes: Node[] = [
  {
    ...generateNode("research", 0, nodesInitData.research, {
      x: -900,
      y: -100,
    }),
    deletable: false,
  },
  generateNode("linkedinCR", 1, nodesInitData.linkedinCR, {
    x: -350,
    y: -250,
  }),
  generateNode("linkedinOutreach", 2, nodesInitData.linkedinOutreach, {
    x: 250,
    y: -250,
  }),
  generateNode("linkedinInMail", 3, nodesInitData.linkedinInMail, {
    x: 0,
    y: -100,
  }),
  generateNode("emailOutreach", 4, nodesInitData.emailOutreach, {
    x: -350,
    y: 50,
  }),
];

export const defaultEdges: Edge[] = [
  {
    source: "research-0",
    sourceHandle: "positive",
    target: "linkedinCR-1",
    targetHandle: "input",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#3f51b5",
    },
    id: "reactflow__edge-research-0researchComplete-linkedinCR-1input",
  },
  {
    source: "research-0",
    sourceHandle: "positive",
    target: "emailOutreach-4",
    targetHandle: "input",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#3f51b5",
    },
    id: "reactflow__edge-research-0researchComplete-emailOutreach-4input",
    data: {
      days: "1",
    },
    selected: false,
  },
  {
    source: "linkedinCR-1",
    sourceHandle: "negative",
    target: "linkedinInMail-3",
    targetHandle: "input",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#f44336",
    },
    id: "reactflow__edge-linkedinCR-1crNotAccepted-linkedinInMail-3input",
    data: {
      days: "3",
    },
    selected: false,
  },
  {
    source: "linkedinCR-1",
    sourceHandle: "positive",
    target: "linkedinOutreach-2",
    targetHandle: "input",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#3f51b5",
    },
    id: "reactflow__edge-linkedinCR-1crAccepted-linkedinOutreach-2input",
    data: {
      days: "1",
    },
    selected: false,
  },
];
