import { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useHookstate } from "@hookstate/core";
import { useUser } from "../../contexts/UserContext";
import { useThread } from "../../contexts/ThreadContext";
import { useCampaign } from "../../contexts/CampaignContext";

export const AutomationStatus = () => {
  const {
    lead,
    currentSteps,
    automationStatus,
    research,
    generateMessage,
    sendCR,
    sendMessage,
    sendEmail,
    crSent,
  } = useThread();
  const { userData } = useUser();
  const { nextAutomation } = useCampaign();
  const automationMessage = useHookstate("");

  useEffect(() => {
    const runAutomation = async () => {
      try {
        automationMessage.set("Starting Automation");
        console.log(
          `Starting automation for ${lead.firstName} ${lead.lastName}. Steps: ${currentSteps.map((s) => s.nodeType)}`,
        );

        const researchStep = currentSteps.find(
          (s) => s.nodeType === "research",
        );
        if (researchStep) {
          console.log("Running Research");
          automationMessage.set("Running Research");
          await research(researchStep.pid);
        }

        const crStep = currentSteps.find((s) => s.nodeType === "linkedinCR");
        if (crStep && !crSent) {
          automationMessage.set(`Generating CR`);
          if (crStep.autoGenerate) {
            await generateMessage(
              crStep.messageType,
              crStep.channel,
              crStep.pid,
            );
            if (crStep.autoSend) {
              console.log("Sending CR");
              automationMessage.set(`Sending CR`);
              await sendCR();
            }
          }
        }

        const linkedinOutreachStep = currentSteps.find(
          (s) => s.nodeType === "linkedinOutreach",
        );
        if (linkedinOutreachStep) {
          automationMessage.set(
            `Generating LinkedIn ${lead.degree > 1 ? "InMail" : "Outreach"}`,
          );
          if (linkedinOutreachStep.autoGenerate) {
            await generateMessage(
              linkedinOutreachStep.messageType,
              linkedinOutreachStep.channel,
              linkedinOutreachStep.pid,
            );
            if (linkedinOutreachStep.autoSend) {
              console.log("Sending LinkedIn Outreach");
              automationMessage.set(`Sending LinkedIn Outreach`);
              await sendMessage(linkedinOutreachStep.pid);
            }
          }
        }

        const emailOutreachStep = currentSteps.find(
          (s) => s.nodeType === "emailOutreach",
        );
        if (emailOutreachStep && lead.email && userData.emailProvider) {
          automationMessage.set(`Generating Email Outreach`);
          if (emailOutreachStep.autoGenerate) {
            await generateMessage(
              emailOutreachStep.messageType,
              emailOutreachStep.channel,
              emailOutreachStep.pid,
            );
            if (emailOutreachStep.autoSend) {
              console.log("Sending Email Outreach");
              automationMessage.set(`Sending Email Outreach`);
              await sendEmail(emailOutreachStep.pid);
            }
          }
        }
      } catch (e) {
        console.error(e);
        automationStatus.set("failed");
      } finally {
        automationStatus.set("finished");
        automationMessage.set(null);
        nextAutomation();
      }
    };
    const executeAutomation = async () => {
      automationStatus.set("running");
      await runAutomation();
    };
    if (!automationMessage.value) executeAutomation();
  }, [currentSteps, automationMessage.value]);

  return (
    <Box display="flex" flexDirection="row" gap={1} p={1}>
      <CircularProgress size={20} />
      <Typography variant="body2">{automationMessage.value}</Typography>
    </Box>
  );
};
