// AdminTools.js

import React, { useState } from "react";
import { AppBar, Toolbar, Box, Card, Typography, Button } from "@mui/material";
import UpdateUserDetails from "./UpdateUserDetails"; // Ensure this path is correct
import { VirtualExtensionSettings } from "./VirtualExtensionSettings"; // Updated import with PascalCase

export const AdminTools = () => {
  const [activeComponent, setActiveComponent] = useState(
    "virtualExtensionSettings",
  );

  return (
    <Card
      sx={{
        borderRadius: "10px",
        maxWidth: 800,
        margin: "auto",
        marginTop: 4,
      }}
    >
      <AppBar position="static" color="primary">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" component="div">
              Admin Tools
            </Typography>
          </Box>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("updateUserDetails")}
            sx={{
              textDecoration:
                activeComponent === "updateUserDetails" ? "underline" : "none",
            }}
          >
            Update User Details
          </Button>
          <Button
            color="inherit"
            onClick={() => setActiveComponent("virtualExtensionSettings")}
            sx={{
              textDecoration:
                activeComponent === "virtualExtensionSettings"
                  ? "underline"
                  : "none",
            }}
          >
            Virtual Extension Actions
          </Button>
        </Toolbar>
      </AppBar>
      <Box className="support-content" sx={{ padding: 2 }}>
        {activeComponent === "updateUserDetails" && <UpdateUserDetails />}
        {activeComponent === "virtualExtensionSettings" && (
          <VirtualExtensionSettings />
        )}
      </Box>
    </Card>
  );
};
