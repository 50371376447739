import { useState } from "react";
import {
  signIn,
  signOut,
  SignInOutput,
  AuthSession,
  fetchAuthSession,
} from "@aws-amplify/auth";
import { Copyright } from "../../components/Copyright";
import {
  Alert,
  Button,
  TextField,
  Paper,
  CircularProgress,
  Box,
  Avatar,
  Typography,
  Grid,
  Link,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  useTheme,
  OutlinedInput,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from "@mui/material";

import { VisibilityOff, Visibility } from "@mui/icons-material";

import { PasswordResetDialog } from "./PasswordResetDialog";
import { RegisterDialog } from "./RegisterDialog";
import { callAPI } from "../../api";
import bellaIcon from "../../assets/bellaIcon.png";
import bellaLogo from "../../assets/bella_logo_white.png";

export async function logInUser(email, password) {
  await signOut();
  // Authenticate with AWS Cognito
  const signInResponse: SignInOutput = await signIn({
    username: email,
    password: password,
  });
  if (!signInResponse || !signInResponse.isSignedIn) {
    throw new Error("Invalid email or password.");
  }
  const session: AuthSession = await fetchAuthSession(); // Get the current session
  const idToken: string = session.tokens.idToken.toString();
  const accessToken: string = session.tokens.accessToken.toString();
  if (!idToken || !accessToken) {
    throw new Error("Error retrieving user session.");
  }
  const response = await callAPI("POST", "login", {
    idToken: idToken,
  });
  if (response.success) {
    localStorage.setItem("token", accessToken);
    if (
      !response.is_admin &&
      window.location.hostname === "dev.bellasales.io"
    ) {
      window.location.href = "http://app.bellasales.io/";
    }

    return;
  } else {
    throw new Error("Error logging in: " + response.error);
  }
}

export function LoginDialog(props: { close }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState([]);

  const validateForm = () => {
    const formErrors = [];
    if (!email) {
      formErrors.push("Email is required");
    }
    if (!password) {
      formErrors.push("Password is required");
    }
    if (formErrors.length > 0) {
      setErrors(formErrors);
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setEmail(null);
    setPassword(null);
    setErrors([]);
    setLoading(false);
    setShowPasswordResetDialog(false);
    props.close();
  };

  const handleLogin = async () => {
    if (!validateForm()) return;
    setLoading(true);
    setErrors([]);
    try {
      await logInUser(email, password);
      handleClose();
    } catch (error) {
      console.error("Error during login:", error);
      setErrors([error.message]);
      setLoading(false);
    }
  };

  const loginDialog = () => {
    return (
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src={bellaIcon} />
        <Typography component="h1" variant="h4">
          Sign in
        </Typography>
        <Box
          sx={{ mt: 1, p: 4, width: "100%" }}
          className={"raised-tile"}
          component="form"
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email || ""}
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              value={password || ""}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {errors.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {errors.map((error, index) => (
                <Alert key={index} severity="error">
                  {error}
                </Alert>
              ))}
            </Box>
          )}
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                m: 2,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Button
            onClick={handleLogin}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowPasswordResetDialog(true)}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  handleClose();
                  setShowRegisterDialog(true);
                }}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderForm = () => {
    if (showRegisterDialog)
      return <RegisterDialog close={() => setShowRegisterDialog(false)} />;
    if (showPasswordResetDialog)
      return (
        <PasswordResetDialog close={() => setShowPasswordResetDialog(false)} />
      );
    return loginDialog();
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid item xs={false} sm={4} md={7} p={6} className="blue-background">
        <Box mb={10}>
          <img src={bellaLogo} alt="bellasales.io" height="40px" />
        </Box>
        <Box>
          <Typography
            variant="h3"
            sx={{
              color: theme.palette.common.white,
              textAlign: "center",
              mt: 8,
              mb: 4,
            }}
          >
            10X Your Leads with Conversational Sales AI for LinkedIn & Email
          </Typography>
          <List>
            <ListItem>
              <Typography variant="subtitle1" color="white">
                🤖 Schedule appointments on autopilot
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" color="white">
                ✉️ Send messages personalised to your LinkedIn chat history,
                profile and each prospect{" "}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1" color="white">
                🦾 Scale conversations and convert more leads into customers
                without adding headcount
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className="ai-background"
      >
        {renderForm()}
        <Copyright />
      </Grid>
    </Grid>
  );
}
