import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import "./Support.module.scss";
import { callAPI } from "../../api";

const SupportForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const res = await callAPI("POST", "submit_support_form", {
        name,
        email,
        subject,
        message,
      });

      if (!res.success === true) {
        throw new Error("Failed to submit support inquiry.");
      }

      setSuccess(true);
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      sx={{ maxHeight: "80vh", overflow: "auto", padding: 2, marginTop: 2 }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Submit a Support Inquiry
        </Typography>
        {success && (
          <Alert severity="success">
            Thank you! Your inquiry has been submitted.
          </Alert>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Subject"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              endIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default SupportForm;
