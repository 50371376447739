import { useMemo, useCallback, useRef, useEffect, useState } from "react";
import { useCampaign } from "../../contexts/CampaignContext";
import { ThreadCard } from "../../components/ThreadCard";
import { CampaignToolbar } from "./CampaignToolbar";
import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  // Skeleton,
  CircularProgress,
} from "@mui/material";
import { Thread } from "../../components/types";
import styles from "./Campaigns.module.scss";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useHookstate } from "@hookstate/core";

const InfiniteThreadsLoader = (props: { threads: Thread[] }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<Map<number, number>>(new Map());

  const virtualizer = useVirtualizer({
    count: props.threads.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => measureRef.current.get(index) || 160, // Default estimate size
    overscan: 10,
    measureElement: (el) => {
      const index = Number(el.getAttribute("data-index"));
      const height = el.getBoundingClientRect().height;
      measureRef.current.set(index, height);
      return height;
    },
  });

  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    const scrollableElement = parentRef.current;
    if (!scrollableElement) return;

    const handleScroll = (event) => {
      event.preventDefault();
      const delta = event.deltaY * 0.5;
      scrollableElement.scrollTop += delta;
    };

    scrollableElement.addEventListener("wheel", handleScroll, {
      passive: false,
    });

    return () => {
      scrollableElement.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <>
      {/* The scrollable element for your list */}
      <Box
        ref={parentRef}
        style={{
          height: "80vh",
          width: "100%",
          contain: "strict",
          overflowY: "auto",
        }}
      >
        {/* The large inner element to hold all of the items */}
        <Box
          style={{
            height: `${virtualizer.getTotalSize() + 75}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {items.map((virtualItem) => (
            <Box
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={(el: HTMLDivElement | null) => {
                if (el) {
                  virtualizer.measureElement(el);
                }
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${virtualItem.start}px)`,
              }}
              pl={2}
              pr={2}
              pt={2}
            >
              <ThreadCard
                key={props.threads[virtualItem.index].pid}
                threadPID={props.threads[virtualItem.index].pid}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

interface IToolbarSearchProps {
  fullName: string | null;
  companyName: string | null;
  title: string | null;
}

export interface IToolbarProps {
  showHidden: boolean;
  order: string;
  orderBy: string;
  search: IToolbarSearchProps;
}

export const CampaignDisplay = (props: { closeCampaign }) => {
  const { campaignData } = useCampaign();
  const { loadData } = useCampaign();
  const [showLoading, setShowLoading] = useState(false);

  const toolbarProps = useHookstate<IToolbarProps>({
    showHidden: false,
    order: "desc",
    orderBy: "updatedAt",
    search: {
      fullName: null,
      companyName: null,
      title: null,
    },
  });

  // uncomment the below to turn on an auto campaign data fetch feature every 10 seconds

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, 10000);

    return () => clearInterval(interval);
  }, [loadData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        !campaignData ||
        !campaignData.pid.get() ||
        campaignData.totalThreads.get() == 0
      ) {
        setShowLoading(true);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [campaignData]);

  const orderedThreads = useMemo(() => {
    const threads = campaignData.threads.get();
    if (!threads) return [];
    const sortBy = toolbarProps.orderBy.get();
    return toolbarProps.order.get() === "asc"
      ? threads.toSorted((a, b) => a[sortBy] - b[sortBy])
      : threads.toSorted((a, b) => b[sortBy] - a[sortBy]);
  }, [toolbarProps, campaignData.threads]);

  const visibleThreads = useMemo(() => {
    if (!orderedThreads) return [];
    if (toolbarProps.showHidden.get()) return orderedThreads;
    return orderedThreads.filter((t) => !t.hidden);
  }, [orderedThreads, toolbarProps]);

  const searchedThreads = useMemo(() => {
    if (!visibleThreads) return [];
    const search: IToolbarSearchProps = toolbarProps.search.get();
    return visibleThreads.filter((t) => {
      const fullNameMatch = t.fullName
        ?.toLowerCase()
        .includes(search.fullName?.toLowerCase() || "");
      const companyNameMatch = t.companyName
        ?.toLowerCase()
        .includes(search.companyName?.toLowerCase() || "");
      const titleMatch = t.title
        ?.toLowerCase()
        .includes(search.title?.toLowerCase() || "");
      return fullNameMatch && companyNameMatch && titleMatch;
    });
  }, [visibleThreads, toolbarProps]);

  const threadsContainer = useCallback(() => {
    return (
      <Box className={styles.threadsContainer}>
        {campaignData.automationRunning.get() ? (
          campaignData.threads.map((t) => {
            return t.automationStatus.get() === "running" ? (
              <ThreadCard
                key={t.pid.get()}
                threadPID={t.pid.get()}
                automationStatus={t.automationStatus}
              />
            ) : null;
          })
        ) : (
          <InfiniteThreadsLoader threads={searchedThreads} />
        )}
      </Box>
    );
  }, [searchedThreads, toolbarProps, campaignData.automationRunning.get()]);

  return campaignData &&
    campaignData.pid.get() &&
    campaignData.totalThreads.get() !== 0 ? (
    <Box className={styles.campaignContainer}>
      <Box className={styles.campaignHeader}>
        <IconButton aria-label="close" onClick={props.closeCampaign}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant="h4">
          <b>Campaign:</b> {campaignData.title.get()}
        </Typography>
      </Box>
      <CampaignToolbar toolbarProps={toolbarProps} />
      {threadsContainer()}
    </Box>
  ) : showLoading ? (
    <Box className={styles.loadingContainer}>
      <CircularProgress />
      <Typography variant="h6" mt={2}>
        Campaign data is loading...
      </Typography>
    </Box>
  ) : null;
};
