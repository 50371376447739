import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  CircularProgress,
  TextField, // Import TextField for custom language input
} from "@mui/material";
import { callAPI } from "../../api"; // Ensure this path is correct based on your project structure

// Define the top languages
const TOP_LANGUAGES = [
  { code: "en", label: "English" },
  { code: "es", label: "Spanish" },
  { code: "zh", label: "Chinese" },
  { code: "fr", label: "French" },
  { code: "de", label: "German" },
  { code: "ja", label: "Japanese" },
  { code: "hi", label: "Hindi" },
  { code: "ar", label: "Arabic" },
  { code: "pt", label: "Portuguese" },
  { code: "ru", label: "Russian" },
  { code: "other", label: "Other" },
];

const LanguageSelector = () => {
  const [language, setLanguage] = useState("en"); // Holds the language code
  const [originalLanguage, setOriginalLanguage] = useState("en"); // To track changes
  const [customLanguage, setCustomLanguage] = useState(""); // Holds the custom language input
  const [originalCustomLanguage, setOriginalCustomLanguage] = useState(""); // Stores original custom language
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true); // To handle initial fetch
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Fetch current user language on mount
  useEffect(() => {
    const fetchUserLanguage = async () => {
      setFetching(true);
      setError("");
      try {
        const res = await callAPI("GET", "user");
        if (!res || res.ok === false) {
          if (res.status === 403) {
            throw new Error(
              "Unauthorized access: You do not have permission to access this feature.",
            );
          }
          throw new Error("Failed to fetch user data.");
        }

        const userLanguageLabel = res.messageLanguage || "English"; // Default to "English" if not set

        // Find the corresponding code for the label
        const selectedLanguage = TOP_LANGUAGES.find(
          (lang) =>
            lang.label.toLowerCase() === userLanguageLabel.toLowerCase(),
        );

        if (selectedLanguage) {
          setLanguage(selectedLanguage.code);
          setOriginalLanguage(selectedLanguage.code);
          setCustomLanguage(""); // Clear custom language if any
          setOriginalCustomLanguage(""); // Clear original custom language
        } else {
          // Language not in the predefined list
          setLanguage("other");
          setOriginalLanguage("other");
          setCustomLanguage(userLanguageLabel); // Set the custom language
          setOriginalCustomLanguage(userLanguageLabel); // Store original custom language
        }
      } catch (err) {
        console.error("Error fetching user language:", err);
        setError(err.message || "An error occurred while fetching user data.");
      } finally {
        setFetching(false);
      }
    };

    fetchUserLanguage();
  }, []);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    // If user selects a predefined language, clear the custom language input
    if (e.target.value !== "other") {
      setCustomLanguage("");
    }
  };

  const handleSave = async () => {
    // Determine if a custom language is being used
    const isCustom = language === "other";

    // Validate custom language input if "Other" is selected
    if (isCustom && customLanguage.trim() === "") {
      setSuccess(false);
      setError("Please enter your preferred language.");
      return;
    }

    // Prevent saving if the language hasn't changed
    if (
      (!isCustom && language === originalLanguage) ||
      (isCustom &&
        customLanguage.trim().toLowerCase() ===
          originalCustomLanguage.trim().toLowerCase())
    ) {
      setSuccess(false);
      setError("No changes detected.");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      let payload;

      if (isCustom) {
        payload = { messageLanguage: customLanguage.trim() };
      } else {
        // Find the label corresponding to the selected code
        const selectedLanguage = TOP_LANGUAGES.find(
          (lang) => lang.code === language,
        );

        if (!selectedLanguage) {
          throw new Error("Selected language is not valid.");
        }

        payload = { messageLanguage: selectedLanguage.label };
      }

      console.log("Updating language with payload:", payload);

      const res = await callAPI("POST", "update_user_language", payload); // Adjust endpoint if necessary

      if (!res || res.ok === false) {
        if (res.status === 403) {
          throw new Error(
            "Unauthorized access: You do not have permission to update this feature.",
          );
        }
        throw new Error("Failed to update language preference.");
      }

      console.log("res: ", res);

      const updatedLanguageLabel =
        res.messageLanguage || payload.messageLanguage;

      // Find the code for the updated label
      const updatedLanguage = TOP_LANGUAGES.find(
        (lang) =>
          lang.label.toLowerCase() === updatedLanguageLabel.toLowerCase(),
      );

      if (updatedLanguage) {
        setOriginalLanguage(updatedLanguage.code);
        setLanguage(updatedLanguage.code);
        setCustomLanguage(""); // Clear custom language if it's now a predefined language
        setOriginalCustomLanguage(""); // Clear original custom language
      } else {
        // Updated language is custom
        setOriginalLanguage("other");
        setLanguage("other");
        setCustomLanguage(updatedLanguageLabel);
        setOriginalCustomLanguage(updatedLanguageLabel); // Update original custom language
      }

      setSuccess(true);
    } catch (err) {
      console.error("Error updating language:", err);
      setError(err.message || "An error occurred while updating the language.");
    } finally {
      setLoading(false);
      // Hide success message after a short delay
      setTimeout(() => setSuccess(false), 3000);
    }
  };

  // Helper function to get the original language label
  const getOriginalLanguageLabel = () => {
    if (originalLanguage === "other") {
      return originalCustomLanguage;
    }
    const lang = TOP_LANGUAGES.find((lang) => lang.code === originalLanguage);
    return lang ? lang.label : "English"; // Default to "English" if not found
  };

  if (fetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "0 auto",
        padding: 4,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Select Message Language (BETA FEATURE - ONLY EFFECTS CHATBOT SIM)
      </Typography>

      {/* Description Paragraph with Specified Styling */}
      <Typography variant="body2" p={1} color="primary">
        This setting is used to change the language that is used when generating
        outreach/reply msgs to leads.
      </Typography>

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Language preference updated successfully!
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <InputLabel id="language-select-label">Language</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={language}
          label="Language"
          onChange={handleLanguageChange}
        >
          {TOP_LANGUAGES.map((lang) => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Conditional Rendering of Custom Language TextBox */}
      {language === "other" && (
        <TextField
          label="Enter your language"
          variant="outlined"
          fullWidth
          value={customLanguage}
          onChange={(e) => setCustomLanguage(e.target.value)}
          sx={{ mb: 3 }}
        />
      )}

      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={
            loading ||
            (language === "other" && customLanguage.trim() === "") ||
            (language !== "other" && language === originalLanguage) ||
            (language === "other" &&
              customLanguage.trim().toLowerCase() ===
                getOriginalLanguageLabel().toLowerCase())
          }
        >
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default LanguageSelector;
