import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { callExtension } from "../../api";
import {
  Dialog,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  AppBar,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Papa from "papaparse";
import styles from "./ImportLeadsToNavDialog.module.scss";
// import { Profile } from "../../pages/Profile";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export type FileWithBuffer = {
  file: File;
  arrayBuffer: ArrayBuffer;
};

export interface ImportLeadsToNavDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ImportLeadsToNavDialog = (props: ImportLeadsToNavDialogProps) => {
  const [processing, setProcessing] = useState<boolean | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [records, setRecords] = useState<string[][]>([]);
  const [filename, setFilename] = useState<string | null>(null);
  const [columnNames, setColumnNames] = useState<
    { name: string; id: number }[]
  >([]);
  const [linkedInColumn, setLinkedInColumn] = useState<string | null>(null);

  const [urls, setUrls] = useState<string[]>([]);
  const [startTimestamp, setStartTimestamp] = useState<number>(
    new Date().getTime(),
  );
  const [listName, setListName] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [postUrl, setPostUrl] = useState<string>("");
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  // const [uniqueUrls, setUniqueUrls] = useState<string[]>([]);
  const [results, setResults] = useState<
    {
      linkedInUrl: string;
      firstName: string;
      lastName: string;
      email: string;
      companyName: string;
      jobTitle: string;
      source: string;
      pid: string;
    }[]
  >([]);

  const onClose = () => {
    setProcessing(null);
    setErrors([]);
    setRecords([]);
    setColumnNames([]);
    setLinkedInColumn(null);
    setMessages([]);
    setResults([]);
    setFilename(null);
    setStartTimestamp(new Date().getTime());
    setProgress(0);
    setPostUrl("");
    setEmbedUrl("");
    // setUniqueUrls([]);
    props.setOpen(false);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFilename(file?.name || null);
    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.data[0];
          setColumnNames(
            headers.map((header: string, index: number) => ({
              name: header,
              id: index,
            })),
          );
          setRecords(result.data.slice(1));
        },
        header: false,
        skipEmptyLines: true,
      });
    }
  };

  const readFile = (linkedinCol: string) => {
    if (!linkedinCol) {
      return;
    }
    const result = records
      .map((record) => {
        const linkedin = record[
          columnNames.find((n) => n.name === linkedinCol)?.id || 0
        ].replace("http:", "https:");
        return {
          linkedIn: linkedin.endsWith("/") ? linkedin.slice(0, -1) : linkedin,
        };
      })
      .filter((record) => record.linkedIn);
    setUrls(result.map((r) => r.linkedIn));
    setMessages([...messages, `Found ${result.length} valid records`]);
  };

  const validateForm = () => {
    const errors: string[] = [];
    if (urls.length < 1) {
      errors.push("No valid data found in the file.");
    }
    if (!listName) {
      errors.push("Please provide a correct name for the new list.");
    }
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const fetchLinkedInUrns = async (urls: string[]) => {
    const urnsFromUrls: string[] = [];
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      const urlSuffix = url.split(".com/")[1];
      try {
        const res = await callExtension("LI_fetchLinkedInUrl", {
          urlSuffix: urlSuffix,
        });
        const htmlContent = JSON.stringify(res);
        if (typeof htmlContent === "string") {
          const idx = htmlContent.indexOf("fsd_profileCard") + 17;
          urnsFromUrls.push(htmlContent.slice(idx).split(",")[0]);
        } else {
          throw new Error(
            "Expected a string in 'res.html' but got: " + typeof htmlContent,
          );
        }
      } catch (error) {
        console.error("Error fetching LinkedIn URN:", error);
      }
      setProgress(((i + 1) / urls.length) * 50);
      await delay(2000);
    }
    return urnsFromUrls;
  };

  const addToSalesNav = async (urnsFromUrls: string[]) => {
    const results: (string | null)[] = [];
    for (let i = 0; i < urnsFromUrls.length; i++) {
      const urn = urnsFromUrls[i];
      try {
        const res = await callExtension("LI_addLeadToLisn", {
          linkedinUrn: urn,
        });
        results.push(res);
      } catch (e) {
        results.push(null);
      }
      setProgress(50 + ((i + 1) / urnsFromUrls.length) * 50);
      await delay(2000);
    }
    return results;
  };

  const getLeadLists = async () => {
    const res = await callExtension("LISN_fetchLists");
    return res.map((e: { lisnId: string; title: string }) => ({
      lisnId: e.lisnId,
      title: e.title,
    }));
  };

  const getLeadProfiles = async (lisnId: string) => {
    const res = await callExtension("LISN_fetchListProfiles", {
      lisnId: lisnId,
    });
    return res
      .filter(
        (e: { dateAddedToListAt: number }) =>
          e.dateAddedToListAt > startTimestamp,
      )
      .map((e: { entityUrn: string }) => e.entityUrn);
  };

  const handleProcess = async () => {
    if (!validateForm()) {
      return;
    }
    setProcessing(true);
    setMessages([...messages, "Extracting LinkedIn Profiles..."]);

    const urnsFromUrls = await fetchLinkedInUrns(urls);

    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg !== "Processing..."),
    );
    setMessages([...messages, "Adding to Sales Navigator..."]);

    await addToSalesNav(urnsFromUrls);

    setMessages([...messages, "Reading existing lead lists..."]);
    const lists = await getLeadLists();
    const newLeads = await getLeadProfiles(
      lists.find((l) => l.title === "Saved on LinkedIn.com")?.lisnId || "",
    );

    setMessages([...messages, "Creating a new lead list..."]);
    const list = await callExtension("LISN_createLeadsList", {
      name: listName,
    });

    setMessages([...messages, "Adding leads to the new list..."]);

    const res = await callExtension("LISN_addLeadsToList", {
      leadUrns: newLeads,
      listId: list.result.id,
    });

    const successful = res.result.value.filter(
      (e: { status: string }) => e.status === "SUCCESS",
    );

    setMessages([
      ...messages,
      `Done! Successfully added ${successful.length} out of ${urls.length} leads to Bella.`,
      "Import successful, please proceed to add a campaign using the Add Campaign button.",
    ]);

    setProgress(100);
    setProcessing(false);
  };

  // const handleProcessPostReactions = async () => {
  //   if (!postUrl) {
  //     setMessages(["Please enter a valid LinkedIn Post URL."]);
  //     return;
  //   }
  //   setProcessing(true);
  //   setMessages(["Fetching reactions for the LinkedIn post..."]);
  //   try {
  //     const res = await callExtension("LI_fetchLinkedInPostReactions", {
  //       url: postUrl,
  //     });
  //     console.log("res done, going onto res_urls_from_urns");
  //     const res_urls_from_urns = await callExtension(
  //       "LI_fetchLinkedInPostReactionsProfileUrl",
  //       {
  //         urns: res.unique_urns,
  //       },
  //     );
  //     setMessages(["Fetched reactions successfully."]);
  //     console.log("res: ", res);
  //     console.log("res_urls_from_urns: ", res_urls_from_urns);

  //     setUniqueUrls(res_urls_from_urns.unique_urls || []);
  //   } catch (error) {
  //     setMessages(["Error fetching reactions from LinkedIn post."]);
  //     console.error("Error:", error);
  //   }
  //   setProcessing(false);
  // };

  // const handleProcessPostReactions = async () => {
  //   if (!postUrl) {
  //     setMessages(["Please enter a valid LinkedIn Post URL."]);
  //     return;
  //   }

  //   setProcessing(true);
  //   setMessages(["Fetching reactions for the LinkedIn post..."]);

  //   try {
  //     const res = await callExtension("LI_fetchLinkedInPostReactions", {
  //       url: postUrl,
  //     });
  //     const uniqueUrns = res.unique_urns || [];
  //     const allProfiles: {
  //       linkedInUrl: string;
  //       firstName: string;
  //       lastName: string;
  //       email: string;
  //       companyName: string;
  //       jobTitle: string;
  //     }[] = [];

  //     const metadata = res.metadata
  //     console.log("metadata: ", metadata)

  //     for (const urn of uniqueUrns) {
  //       const NAME_SEARCH = "NAME_SEARCH";
  //       const tmZp = "tmZp";
  //       const leadEntityUrn = `(${urn},${NAME_SEARCH},${tmZp})`;

  //       console.log("Fetching profile for leadEntityUrn:", leadEntityUrn);

  //       try {
  //         const profile = await callExtension("LISN_fetchLead", {
  //           entityUrn: leadEntityUrn,
  //         });
  //         console.log("profile: ", profile);

  //         if (profile) {
  //           allProfiles.push({
  //             linkedInUrl: profile.linkedInUrl || "N/A", // You can replace with a valid fallback
  //             firstName: profile.firstName || "",
  //             lastName: profile.lastName || "",
  //             email: profile.email || "N/A", // Replace or set it based on your data
  //             companyName: profile.companies?.[0]?.name || "Unknown",
  //             jobTitle: profile.companies?.[0]?.title || "Unknown",
  //           });
  //         }
  //       } catch (error) {
  //         console.error(`Error fetching profile for URN ${urn}:`, error);
  //       }
  //     }

  //     setResults(allProfiles);

  //     try {
  //       console.log("metadata.ugcPost_id: ", metadata.ugcPost_id)
  //       const postLikes = await callExtension("LI_fetchLinkedinPostLikes", {
  //         activityId: metadata.ugcPost_id
  //       })
  //       console.log("res from fetch likes: ", postLikes)
  //     }

  //     if (postLikes):
  //       for urn for postLikes.unique_urns from likes:
  //       try {
  //           const profile = await callExtension("LISN_fetchLead", {
  //             entityUrn: leadEntityUrn,
  //           });
  //           console.log("profile: ", profile);
  //     catch (error) {
  //       console.error(`Error fetching profile for URN ${urn}:`, error);
  //     }

  //     catch (error) {
  //       console.error("Error fetching reactions from LinkedIn post.", error);
  //     }

  //     setMessages(["Fetched all profiles successfully."]);
  //   } catch (error) {
  //     setMessages([
  //       "Error fetching reactions or profiles from LinkedIn post.",
  //     ]);
  //     console.error("Error:", error);
  //   }

  //   setProcessing(false);
  // };

  const handleProcessPostReactions = async () => {
    if (!postUrl) {
      setMessages(["Please enter a valid LinkedIn Post URL."]);
      return;
    }

    setProcessing(true);
    setMessages(["Fetching reactions for the LinkedIn post..."]);
    setProgress(0);
    setResults([]); // Reset results before processing

    try {
      // Fetch reactions for the LinkedIn post
      const res = await callExtension("LI_fetchLinkedInPostReactions", {
        postUrl: postUrl,
        embedUrl: embedUrl,
      });
      const uniqueReactionUrns = res.unique_urns || [];
      const metadata = res.metadata;

      // **Added Code Start: Fetch likes for the LinkedIn post**
      let uniqueLikeUrns: { urn: string; source: string }[] = [];
      try {
        const postLikes = await callExtension("LI_fetchLinkedinPostLikes", {
          activityId: metadata.activityId,
          postType: metadata.postType,
        });

        if (postLikes) {
          uniqueLikeUrns =
            postLikes.unique_urns_from_likes.map((urn: string) => ({
              urn,
              source: "reaction",
            })) || [];
        }
      } catch (error) {
        console.error("Error fetching likes from LinkedIn post.", error);
      }
      // **Added Code End**

      const allProfiles = [];
      // **Modified Code Start: Combine URNs from reactions and likes**
      const allUrns = [
        ...uniqueReactionUrns.map((urn: string) => ({
          urn,
          source: "comment",
        })),
        ...uniqueLikeUrns,
      ];

      const totalUrns = allUrns.length;
      let processedUrns = 0;
      // **Modified Code End**

      // Function to fetch profiles based on URNs and source
      const fetchProfiles = async (
        urnsWithSource: { urn: string; source: string }[],
      ) => {
        const sleep = (ms: number) =>
          new Promise((resolve) => setTimeout(resolve, ms));
        const MAX_RETRIES = 3;

        for (const { urn, source } of urnsWithSource) {
          const NAME_SEARCH = "NAME_SEARCH";
          const tmZp = "tmZp";
          const leadEntityUrn = `(${urn},${NAME_SEARCH},${tmZp})`;

          let retries = 0;
          let profile;
          let fetched = false;

          while (retries < MAX_RETRIES && !fetched) {
            try {
              profile = await callExtension("LISN_fetchLead", {
                entityUrn: leadEntityUrn,
              });

              if (profile && profile.firstName) {
                const existingProfileIndex = allProfiles.findIndex(
                  (p) => p.linkedInUrl === profile.linkedInUrl,
                );
                if (existingProfileIndex === -1) {
                  const newProfile = {
                    linkedInUrl: profile.linkedInUrl || "N/A",
                    firstName: profile.firstName || "",
                    lastName: profile.lastName || "",
                    email: profile.email || "N/A",
                    companyName: profile.companies?.[0]?.name || "Unknown",
                    jobTitle: profile.companies?.[0]?.title || "Unknown",
                    source: source,
                    pid: profile.pid || "",
                  };
                  allProfiles.push(newProfile);
                  setResults((prevResults) => [...prevResults, newProfile]); // **Display results as they're returned**
                } else {
                  const existingProfile = allProfiles[existingProfileIndex];
                  if (!existingProfile.source.includes(source)) {
                    existingProfile.source += `, ${source}`;
                    // **Update existing profile in results**
                    setResults((prevResults) =>
                      prevResults.map((p, idx) =>
                        idx === existingProfileIndex ? existingProfile : p,
                      ),
                    );
                  }
                }
                fetched = true;
              } else {
                retries++;
                if (retries < MAX_RETRIES) {
                  console.log(
                    `First name is empty. Retrying (${retries}/${MAX_RETRIES})...`,
                  );
                  await sleep(3000); // Wait 3 seconds before retrying
                } else {
                  console.log(
                    `Max retries reached for URN ${urn}. Moving to next URN.`,
                  );
                }
              }
            } catch (error) {
              console.error(`Error fetching profile for URN ${urn}:`, error);
              break;
            }
          }

          processedUrns++;
          setProgress((processedUrns / totalUrns) * 100); // **Update loading bar progress**

          await sleep(3000); // Wait between requests to prevent rate limiting
        }
      };

      await fetchProfiles(allUrns);

      setMessages(["Fetched all profiles successfully."]);
    } catch (error) {
      setMessages(["Error fetching reactions or profiles from LinkedIn post."]);
      console.error("Error:", error);
    } finally {
      setProcessing(false);
    }
  };

  const handleSaveToCsv = () => {
    const csvData = Papa.unparse(
      results.map((profile) => ({
        linkedinUrl: profile.linkedInUrl,
        firstName: profile.firstName,
        lastName: profile.lastName,
        companyName: profile.companyName,
        jobTitle: profile.jobTitle,
        source: profile.source,
      })),
      { header: true },
    );
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "linkedin_profiles.csv");
    link.click();
  };

  return (
    <Dialog open={props.open} onClose={onClose} fullWidth>
      <AppBar position="static">
        <Box display="flex" justifyContent="space-between" p={2}>
          <Typography
            variant="h6"
            component="div"
            style={{ cursor: "pointer" }}
            onClick={() => setActiveTab(0)}
          >
            Upload CSV File
          </Typography>
          <Typography
            variant="h6"
            component="div"
            style={{ cursor: "pointer" }}
            onClick={() => setActiveTab(1)}
          >
            Extract LinkedIn Reactions to CSV
          </Typography>
        </Box>
      </AppBar>
      <Box p={2}>
        {activeTab === 0 && (
          <>
            <Typography
              variant="caption"
              color={"primary"}
              className={styles.info}
            >
              This dialog creates a leads list in your LinkedIn Sales Nav from
              the file containing LinkedIn urls. The file must be CSV and it
              must contain full LinkedIn urls in one of the columns. After
              uploading the file, please, make sure to select the right column
              name.
            </Typography>
            <Box>
              {errors.length > 0 &&
                errors.map((error, idx) => (
                  <Typography
                    key={idx}
                    variant="caption"
                    color={"error"}
                    className={styles.error}
                  >
                    {error}
                  </Typography>
                ))}
              <Box display="flex" flexDirection="row" p={1} gap={2}>
                <TextField
                  value={listName || ""}
                  onChange={(e) => setListName(e.target.value)}
                  placeholder="Name of the list"
                  size="small"
                  disabled={
                    processing ||
                    messages.includes(
                      "Import successful, please proceed to add a campaign using the Add Campaign button.",
                    )
                  }
                />
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUpload />}
                  disabled={
                    processing ||
                    messages.includes(
                      "Import successful, please proceed to add a campaign using the Add Campaign button.",
                    )
                  }
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    id="fileInput"
                    accept=".csv"
                    style={{ display: "none" }}
                    onChange={onFileChange}
                    required={true}
                  />
                </Button>
                <TextField
                  variant="standard"
                  value={filename || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                  size="small"
                />
              </Box>
              {columnNames.length >= 2 && (
                <div className={styles.columnSelectors}>
                  <FormControl
                    fullWidth
                    className={styles.columnSelector}
                    size="small"
                  >
                    <InputLabel id="select-linkedin-column-label">
                      LinkedIn Urls Column
                    </InputLabel>
                    <Select
                      labelId="select-linkedin-column-label"
                      id="select-linkedin-column"
                      label="LinkedIn Urls Column"
                      value={linkedInColumn || ""}
                      onChange={(e) => {
                        setLinkedInColumn(e.target.value);
                        readFile(e.target.value);
                      }}
                      fullWidth
                      disabled={
                        processing ||
                        messages.includes(
                          "Import successful, please proceed to add a campaign using the Add Campaign button.",
                        )
                      }
                    >
                      {columnNames.map((s, idx) => {
                        return (
                          <MenuItem key={idx} value={s.name}>
                            {s.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}
              {processing && <LinearProgressWithLabel value={progress} />}
              {messages && (
                <div>
                  <Box display="flex" flexDirection="column">
                    {messages.map((m, idx) => (
                      <Typography
                        key={idx}
                        variant="caption"
                        color={"success"}
                        className={styles.foundInfo}
                      >
                        {m}
                      </Typography>
                    ))}
                  </Box>
                  <div className={styles.processButton}>
                    {!messages.includes(
                      "Import successful, please proceed to add a campaign using the Add Campaign button.",
                    ) &&
                      (processing ? (
                        <div>
                          <CircularProgress size="small" />
                          Processing...
                        </div>
                      ) : (
                        <Button
                          onClick={handleProcess}
                          variant="contained"
                          disabled={
                            urls.length < 1 ||
                            listName === null ||
                            listName === "" ||
                            processing
                          }
                        >
                          Process
                        </Button>
                      ))}
                  </div>
                  {results && results.length > 0 && (
                    <div className={styles.resultsContainer}>
                      Successfully updated {results.length} emails for:
                      {results.map((result) => (
                        <div key={result.pid}>
                          <b>
                            {result.firstName} {result.lastName}
                          </b>{" "}
                          ({result.email})
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Box>
          </>
        )}
        {activeTab === 1 && (
          <>
            <Typography
              variant="caption"
              color={"primary"}
              className={styles.info}
            >
              This dialog allows you to extract the profiles of people who
              comment and like a LinkedIn post. Please enter a valid LinkedIn
              Post URL along with the embed details. Once processed, you can
              download the reactions as a CSV file. Example of post url:
              https://www.linkedin.com/posts/danpod_heres-how-i-4xd-my-responses-and-meetings-ugcPost-7232280986481217536-uoc2?utm_source=share&utm_medium=member_desktop
            </Typography>

            <TextField
              value={postUrl}
              onChange={(e) => setPostUrl(e.target.value)}
              placeholder="LinkedIn Post URL"
              fullWidth
              size="small"
              margin="normal"
            />
            <TextField
              value={embedUrl}
              onChange={(e) => setEmbedUrl(e.target.value)}
              placeholder="LinkedIn embed URL"
              fullWidth
              size="small"
              margin="normal"
            />
            <Button variant="contained" onClick={handleProcessPostReactions}>
              Submit LinkedIn Post Details
            </Button>
            {/* Display the loading bar during processing */}
            {processing && (
              <Box sx={{ width: "100%", mt: 2 }}>
                <LinearProgressWithLabel value={progress} />
              </Box>
            )}

            {/* Display results as they're returned */}
            {results.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle1">
                  Fetched LinkedIn Reactions:
                </Typography>
                {results.map((profile, idx) => (
                  <Typography key={idx} variant="body2">
                    {profile.firstName} {profile.lastName} - Source:{" "}
                    {profile.source}
                  </Typography>
                ))}
                <Button
                  variant="contained"
                  onClick={handleSaveToCsv}
                  sx={{ mt: 2 }}
                >
                  Download to CSV
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};
