import { useState } from "react";
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import { logInUser } from "./LoginDialog";

import {
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  FormControl,
  List,
  ListItem,
  Stack,
  CircularProgress,
} from "@mui/material";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export const PasswordResetModal = ({ email, open, setOpen }) => {
  const [username, setUsername] = useState(email);
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationCodeSent, setConfirmationCodeSent] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    setConfirmationCode(""); // Reset confirmation code state
    setNewPassword(""); // Reset new password state
    setOpen(false);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const output = await resetPassword({ username: username });
      // Assuming the resetPassword initiates the process successfully
      if (
        output.nextStep?.resetPasswordStep ===
        "CONFIRM_RESET_PASSWORD_WITH_CODE"
      ) {
        setConfirmationCodeSent(true);
      } else {
        throw new Error(
          "Unexpected response from resetPassword. Contact Bella Support team.",
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = () => {
    const errors = [];
    if (confirmationCode.length < 6) {
      errors.push("Confirmation code must be at least 6 characters long");
    }
    if (newPassword.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!newPassword.match(/[a-z]/)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!newPassword.match(/[A-Z]/)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!newPassword.match(/[0-9]/)) {
      errors.push("Password must contain at least one number");
    }
    if (!newPassword.match(/[^a-zA-Z0-9]/)) {
      errors.push("Password must contain at least one special character");
    }
    setErrors(errors);
    if (errors.length > 0) return false;
    return true;
  };

  const handleConfirmResetPassword = async () => {
    if (!validatePassword()) return;
    try {
      await confirmResetPassword({
        username: username,
        confirmationCode,
        newPassword,
      });
      // Assuming the password reset is successful
      await logInUser(username, newPassword);
    } catch (error) {
      console.log(error);
      setErrors([error.message]);
      // Optionally, handle errors, e.g., showing an error message in the modal
    } finally {
      setLoading(false);
      handleClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="password-reset-confirm-modal-title"
      aria-describedby="password-reset-confirm-modal-description"
    >
      <Box sx={modalStyle}>
        {confirmationCodeSent ? (
          <FormControl sx={{ gap: "1rem" }}>
            <Typography
              id="password-reset-confirm-modal-title"
              variant="h6"
              component="h2"
            >
              Enter New Details
            </Typography>
            <TextField
              type="text"
              label="Confirmation Code"
              value={confirmationCode || ""}
              autoComplete="off"
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
            <TextField
              type="password"
              label="New Password"
              autoComplete="new-password"
              value={newPassword || ""}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={handleConfirmResetPassword}>
                Confirm Reset
              </Button>
            )}
            {errors.length > 0 ? (
              <Stack>
                {errors.map((error) => (
                  <Typography key={error} color="error" variant="body2">
                    - {error}
                  </Typography>
                ))}
              </Stack>
            ) : (
              <br />
            )}
            <Box>
              <Typography variant="subtitle2">
                Password requirements:
              </Typography>
              <List dense={true}>
                <ListItem>Minimum length: 8 character(s)</ListItem>
                <ListItem>Contains at least 1 number</ListItem>
                <ListItem>Contains at least 1 special character</ListItem>
                <ListItem>Contains at least 1 uppercase letter</ListItem>
                <ListItem>Contains at least 1 lowercase letter</ListItem>
              </List>
            </Box>
          </FormControl>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "2rem",
            }}
          >
            <Typography variant="h6" color="error">
              You are about to reset your password!
            </Typography>
            {email ? (
              <Typography variant="subtitle2">
                A confirmation code will be sent to your email:{" "}
                {<b>{username}</b>}
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle2">
                  Please enter your email for the confirmation code:
                </Typography>
                <TextField
                  type="email"
                  label="Your Email"
                  value={username || ""}
                  autoComplete="off"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  fullWidth
                />
              </>
            )}
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={handleResetPassword}
                fullWidth
              >
                Send Confirmation Code
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
