export class ResponseCache {
    private timeout: number;

    constructor(timeout: number) {
        this.timeout = timeout;
    }

    generateKey(params) {
        return JSON.stringify(params);
    }

    get(params) {
        const key = this.generateKey(params);
        const itemString = sessionStorage.getItem(key);
        if (itemString) {
            const item = JSON.parse(itemString);
            const now = new Date();
            if (
                now.getTime() - new Date(item.timestamp).getTime() <
                this.timeout
            ) {
                return item.data;
            } else {
                sessionStorage.removeItem(key);
            }
        }
        return null; // Not found or expired
    }

    set(params, data) {
        const key = this.generateKey(params);
        const item = {
            data: data,
            timestamp: new Date().toISOString(), // Store date as string
        };
        sessionStorage.setItem(key, JSON.stringify(item));
    }
}
