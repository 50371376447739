import { useEffect, useState, useMemo, useRef } from "react";
import { useThread } from "../../contexts/ThreadContext";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Select,
  MenuItem,
  IconButton,
  ListItemIcon,
  CircularProgress,
  Modal,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Message } from "../../components/types";
import { Send, LinkedIn, Email, Close } from "@mui/icons-material";
import { formatRelative } from "date-fns";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #5457fc",
  borderRadius: "8px",
};

const headerStyle = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "#5457fcff",
  borderRadius: "5px 5px 0 0",
};

const bodyStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  maxHeight: "600px",
  position: "relative",
};

const actionsStyle = {
  p: 1,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const msgStyle = {
  padding: "10px",
  borderRadius: "10px",
  margin: "5px",
  maxWidth: "60%",
  wordWrap: "break-word",
  display: "flex",
  flexDirection: "column",
};

const receivedMsgStyle = {
  ...msgStyle,
  backgroundColor: "#5457fc44",
  justifyContent: "flex-start",
  textAlign: "left",
};

const sentMsgStyle = {
  ...msgStyle,
  backgroundColor: "#54fc5744",
  justifyContent: "flex-end",
  textAlign: "right",
};

const MessageComponent = ({ idx, msg }) => {
  const dt = new Date(msg.createdAt * 1000);
  const dtString = formatRelative(dt, new Date());
  const Channel = () => (
    <ListItemIcon
      sx={{
        display: "flex",
        minWidth: "fit-content",
      }}
    >
      {msg.channel === "linkedin" ? (
        <LinkedIn color="primary" />
      ) : (
        <Email color="secondary" />
      )}
    </ListItemIcon>
  );
  const DateTime = () => (
    <ListItemText
      secondary={dtString}
      sx={{
        position: "absolute",
        top: -10,
        marginLeft: 2,
        marginRight: 2,
      }}
    />
  );
  const Content = () => (
    <ListItemText
      primary={msg.subject}
      secondary={msg.content}
      sx={msg.status === "sent" ? sentMsgStyle : receivedMsgStyle}
    />
  );
  return (
    <ListItem
      key={idx}
      sx={{
        display: "flex",
        justifyContent: msg.status === "sent" ? "flex-end" : "flex-start",
        position: "relative",
      }}
      id={msg.pid}
    >
      {msg.status === "received" ? (
        <>
          <DateTime />
          <Content />
          <Channel />
        </>
      ) : (
        <>
          <Channel />
          <Content />
          <DateTime />
        </>
      )}
    </ListItem>
  );
};

export const LeadConversationBox = (props: { open; setOpen }) => {
  const { open, setOpen } = props;
  // A chat window for the lead
  const { threadData, postMessage, sendMessage, sendEmail } = useThread();
  const [newMessage, setNewMessage] = useState<Message>({
    channel: "linkedin",
  });
  const [aiMode, setAiMode] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const listEndRef = useRef(null);
  const lead = threadData.target.get();
  const replyReceived = threadData.messages.some(
    (m) => m.status.get() === "received",
  );

  const subjectRequired =
    (newMessage?.channel === "email" || !lead.lisnThreadId) && !aiMode;

  const newMsgType = useMemo(() => {
    if (newMessage?.channel === "linkedin" && lead.degree > 1) {
      return "cr";
    }
    if (replyReceived) {
      return "reply";
    } else {
      return "outreach";
    }
  }, [newMessage, replyReceived, lead.degree]);

  const validateMessage = () => {
    const errors = [];
    if (!newMessage?.content) {
      errors.push("Message is required");
    }
    if (subjectRequired && !newMessage?.subject) {
      errors.push("Subject is required");
    }
    if (newMessage?.channel === "email" && !lead.email) {
      errors.push("The prospect has no email address");
    }
    if (newMsgType === "cr" && newMessage.content.length > 300) {
      errors.push("CR messages must be less than 300 characters");
    }
    setErrors(errors);
    return errors;
  };

  const sendMsg = async () => {
    const errors = validateMessage();
    if (errors.length > 0) {
      return;
    }
    if (aiMode) {
      console.log("NOT IMPLEMENTED");
    } else if (confirmSend) {
      setLoading(true);
      try {
        await postMessage({ ...newMessage, type: newMsgType });
        if (newMessage.channel === "linkedin") {
          if (lead.degree === 1) {
            await sendMessage();
          } else {
            throw "Cannot send a message to a 2nd degree connection.";
          }
        } else {
          await sendEmail();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setNewMessage({ ...newMessage, content: "", subject: "" });
      }
    } else {
      setConfirmSend(true);
      setTimeout(() => {
        setConfirmSend(false);
      }, 2000);
    }
  };

  const scrollToBottom = () => {
    listEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [threadData.messages.length]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={modalStyle}>
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1000,
          }}
          onClick={() => setOpen(false)}
        >
          <Close />
        </IconButton>
        <Box sx={headerStyle}>
          <Typography variant="subtitle2" sx={{ p: 2, color: "white" }}>
            Conversation with{" "}
            <b>
              {lead.firstName} {lead.lastName}
            </b>
          </Typography>
          <FormControlLabel
            sx={{ marginRight: "50px" }}
            control={
              <Switch
                checked={aiMode}
                onChange={() => {
                  setAiMode(!aiMode);
                }}
                name="AI Switch"
                disabled={true}
              />
            }
            label="AI Mode"
          />
        </Box>
        <Box sx={bodyStyle}>
          <List sx={{ flexGrow: 1, p: 1, overflow: "auto" }}>
            {threadData.messages
              .get()
              .filter((m) => ["received", "sent"].includes(m.status))
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((message, index) => (
                <MessageComponent key={index} idx={index} msg={message} />
              ))}
            <div ref={listEndRef} />
          </List>

          {aiMode && (
            <List
              sx={{
                flexGrow: 1,
                p: 1,
                overflow: "auto",
                backgroundColor: "#5457fc88",
                position: "absolute",
                zIndex: 100,
                width: "100%",
                height: "100%",
              }}
            ></List>
          )}
        </Box>
        <Box sx={actionsStyle}>
          <Box
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              gap: "10px",
              width: "100%",
            }}
          >
            {errors?.length > 0 &&
              errors.map((error, index) => (
                <Typography key={index} color="error" variant="subtitle2">
                  {error}
                </Typography>
              ))}
            {subjectRequired && (
              <TextField
                fullWidth
                size="small"
                label="Subject"
                variant="outlined"
                value={newMessage?.subject || ""}
                onChange={(e) =>
                  setNewMessage({
                    ...newMessage,
                    subject: e.target.value,
                  })
                }
                sx={{ mr: 1 }}
              />
            )}
            <TextField
              multiline
              minRows={3}
              fullWidth
              size="small"
              label="Type a message..."
              variant="outlined"
              value={newMessage?.content || ""}
              onChange={(e) =>
                setNewMessage({
                  ...newMessage,
                  content: e.target.value,
                })
              }
              onKeyDown={(e) => e.key === "Enter" && sendMsg()}
              sx={{ mr: 1 }}
              required
            />
          </Box>
          <Box
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              justifyContent: "center",
            }}
          >
            {!aiMode && (
              <Select
                labelId="channel-select-label"
                id="leads-list"
                fullWidth
                value={newMessage?.channel || "linkedin"}
                onChange={(e) =>
                  setNewMessage({
                    ...newMessage,
                    channel: e.target.value,
                  })
                }
                variant="standard"
                required
                size="small"
                sx={{
                  color: "#5457fc",
                }}
              >
                <MenuItem key={"linkedin-channel"} value={"linkedin"}>
                  <LinkedIn color="primary" />
                </MenuItem>
                <MenuItem key={"email-channel"} value={"email"}>
                  <Email color="secondary" />
                </MenuItem>
              </Select>
            )}
            {!loading ? (
              <Button variant="text" color="primary" onClick={sendMessage}>
                <Send color={confirmSend ? "warning" : "primary"} />
              </Button>
            ) : (
              <Box
                sx={{
                  padding: "8px",
                  minWidth: "64px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
