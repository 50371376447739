import { useState, useMemo, FC } from "react";
import { useThread } from "../../contexts/ThreadContext";
import { LeadConversationBox } from "../LeadConversationBox";
import { InsightsModal } from "./InsightsModal";
import { ChangeEmailDialog } from "../EmailDialog";
import { HubSpotIcon, SalesforceIcon } from "../Icons";
import { useUser } from "../../contexts/UserContext";
import ChatbotEnableDisable from "../ChatbotEnableDisable";
import { ImportLeadToCRMModal } from "../ImportLeadToCRMModal";
import {
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import {
  Insights,
  Forum,
  AccountTree,
  Email,
  LinkedIn,
  Visibility,
  VisibilityOff,
  Phone,
  SmartToy as SmartToyIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

const Counter = ({
  icon,
  value = null,
  onClick = null,
  tooltipTitle = null,
}) => {
  const innerComponent = (
    <Box
      onClick={onClick}
      sx={{
        cursor: onClick ? "pointer" : "default",
      }}
    >
      {icon}
      {value}
    </Box>
  );
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>{innerComponent}</Tooltip>
  ) : (
    innerComponent
  );
};

interface DeleteLeadCounterProps {
  lead: {
    pid: string;
  };
  deleteLead: (pid: string) => void;
}

const DeleteLeadCounter: FC<DeleteLeadCounterProps> = ({
  lead,
  deleteLead,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteLead = () => {
    deleteLead(lead.pid);
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Counter
        icon={<CloseIcon color="error" />}
        tooltipTitle={"Delete this lead"}
        onClick={() => setDeleteDialogOpen(true)}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this lead? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteLead} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const Counters = () => {
  const { userData } = useUser();
  const [showInsights, setShowInsights] = useState<boolean>(false);
  const { threadData, lead, changeVisibility, deleteLead } = useThread();
  const [showHubSpotDialog, setShowHubSpotDialog] = useState<boolean>(false);
  const [showSalesforceDialog, setShowSalesforceDialog] =
    useState<boolean>(false);

  const [conversationOpen, setConversationOpen] = useState(false);
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);
  const [chatBotEnabled, setChatBotEnabled] = useState<boolean>(
    lead.botStatus === null ? true : lead.botStatus,
  );

  const insights = threadData.insights.get();
  const insightsOk = useMemo(() => {
    if (!insights) return false;
    const keys = insights.map((i) => i.origin);
    return (
      (keys.includes("lead_linkedin") && keys.includes("company_linkedin")) ||
      keys.includes("industry_news")
    );
  }, [insights]);

  const InsightsCounter = () => (
    <Counter
      tooltipTitle="Click to read insights about the prospect"
      value={insightsOk ? 3 : 0}
      icon={<Insights color={insightsOk ? "success" : "warning"} />}
      onClick={insightsOk ? () => setShowInsights(true) : null}
    />
  );

  const MessagesCounter = () => (
    <Counter
      value={
        threadData.messages?.filter((m) =>
          ["sent", "replied", "received"].includes(m.status.get()),
        ).length
      }
      tooltipTitle="Number of Sent/Received Messages"
      icon={<Forum />}
    />
  );

  const LISNDegreeCounter = () => (
    <Counter
      tooltipTitle="The connection degree with the lead on LinkedIn"
      value={lead.degree}
      icon={<AccountTree color={lead.degree == 1 ? "success" : "warning"} />}
    />
  );

  const LISNOpenProfileCounter = () => (
    <Counter
      icon={<LinkedIn color={lead.openProfile ? "success" : "warning"} />}
      tooltipTitle={
        lead.openProfile
          ? "The lead has an open LinkedIn profile meaning you can send them outreach without an accepted connection request. But it is a good practice to send CR first."
          : "The lead has a closed LinkedIn profile meaning you cannot send them messages without accepted connection request."
      }
    />
  );

  const HubSpotDialogCounter = () => {
    const tooltip = lead.hubSpotLeadId
      ? lead.firstName + " is in your HubSpot. Click to Update Hubspot Lead."
      : lead.firstName +
        " is not in your HubSpot." +
        " Click to Import Lead into HubSpot.";
    return (
      <Counter
        icon={
          <HubSpotIcon
            style={{
              width: "18px",
              ...(lead.hubSpotLeadId && {
                filter:
                  "invert(20%) sepia(30%) saturate(80%) hue-rotate(100deg) contrast(100%) brightness(80%)",
              }),
            }}
          />
        }
        tooltipTitle={tooltip}
        onClick={() => setShowHubSpotDialog(true)}
      />
    );
  };

  const SalesforceDialogCounter = () => {
    const { threadData } = useThread();
    const tooltip = threadData?.target.salesforceLeadId.get()
      ? threadData?.target?.firstName.get() +
        " is in your Salesforce. Click to Update Salesforce Lead."
      : threadData?.target?.firstName.get() +
        " is not in your Salesforce. Click to Import Lead into Salesforce.";
    return (
      <Counter
        icon={
          <SalesforceIcon
            style={{
              width: "28px",
              ...(threadData?.target.salesforceLeadId.get() && {
                filter:
                  "invert(20%) sepia(50%) saturate(200%) hue-rotate(180deg) contrast(90%) brightness(90%)",
              }),
            }}
          />
        }
        tooltipTitle={tooltip}
        onClick={() => setShowSalesforceDialog(true)}
      />
    );
  };

  const EmailCounter = () => (
    <Counter
      icon={<Email color={lead.email ? "success" : "error"} />}
      tooltipTitle={
        lead.email
          ? `Lead's email is identified: ${lead.email}.`
          : "Lead has no email. Click to manually enter their email."
      }
      onClick={() => setChangeEmailDialogOpen(true)}
    />
  );

  const VisibilityCounter = () => (
    <Counter
      icon={
        threadData.hidden.get() ? (
          <VisibilityOff color="warning" />
        ) : (
          <Visibility color="primary" />
        )
      }
      tooltipTitle={threadData.hidden.get() ? "Unhide thread" : "Hide thread"}
      onClick={() => changeVisibility()}
    />
  );

  const PhoneCounter = () => {
    let phoneNumbers = lead.phoneNumbers || [];
    if (!Array.isArray(phoneNumbers)) {
      phoneNumbers = JSON.parse(phoneNumbers.replace(/'/g, '"'));
    }
    return (
      <Counter
        icon={<Phone color={phoneNumbers?.length > 0 ? "success" : "error"} />}
        tooltipTitle={
          phoneNumbers?.length > 0
            ? `Found lead's phone number(s): ${phoneNumbers.join("; ")}.`
            : "No phone number was found for the lead."
        }
      />
    );
  };

  const ChatBotCounter = () => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <Counter
          icon={
            <SmartToyIcon
              style={{
                filter: chatBotEnabled
                  ? "invert(20%) sepia(10%) saturate(100%) hue-rotate(0deg) contrast(100%) brightness(80%)"
                  : "invert(100%) sepia(50%) saturate(75%) hue-rotate(0deg) contrast(100%) brightness(175%)",
              }}
            />
          }
          tooltipTitle={
            chatBotEnabled
              ? "Chatbot is enabled on this lead. Click to turn off."
              : "Chatbot is disabled on this lead. Click to turn on."
          }
          onClick={() => setOpen(true)}
        />
        <ChatbotEnableDisable
          open={open}
          setOpen={setOpen}
          lead={lead}
          chatBotEnabled={chatBotEnabled}
          setChatBotEnabled={setChatBotEnabled}
        />
      </>
    );
  };

  // const DeleteLeadCounter = () => (
  //   <Counter
  //     icon={<CloseIcon color="error" />}
  //     tooltipTitle={"Delete this lead"}
  //     onClick={() => deleteLead(lead.pid)}
  //   />
  // );

  return (
    <Box
      sx={{
        fontSize: "0.8rem",
        fontWeight: "bold",
        color: "#6089d1",
        position: "absolute",
        top: "5px",
        right: "5px",
        display: "flex",
        flexDirection: "row",
        gap: "5px",
      }}
    >
      {lead && (
        <LeadConversationBox
          open={conversationOpen}
          setOpen={setConversationOpen}
        />
      )}
      {insightsOk && showInsights && (
        <InsightsModal open={showInsights} setOpen={setShowInsights} />
      )}
      {lead && (
        <ChangeEmailDialog
          open={changeEmailDialogOpen}
          setOpen={setChangeEmailDialogOpen}
        />
      )}
      {showHubSpotDialog && (
        <ImportLeadToCRMModal
          open={showHubSpotDialog}
          setOpen={setShowHubSpotDialog}
        />
      )}
      {showSalesforceDialog && (
        <ImportLeadToCRMModal
          open={showSalesforceDialog}
          setOpen={setShowSalesforceDialog}
        />
      )}

      <InsightsCounter />
      <MessagesCounter />
      <LISNDegreeCounter />
      <LISNOpenProfileCounter />
      {userData.crmProvider === "hubspot" && <HubSpotDialogCounter />}
      {userData.crmProvider === "salesforce" && <SalesforceDialogCounter />}

      <EmailCounter />
      <PhoneCounter />
      <VisibilityCounter />
      <ChatBotCounter />
      <DeleteLeadCounter lead={lead} deleteLead={deleteLead} />
    </Box>
  );
};
