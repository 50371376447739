import { useState, useEffect, FC } from "react";
import {
  Box,
  Typography,
  TextField,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import { Add, SwapHoriz, Delete } from "@mui/icons-material";

interface IMessage {
  idx: number;
  from: "me" | "prospect" | "bella";
  text: string;
}

interface NewConversationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: (conversation: IMessage[]) => Promise<void>;
  editConversation?: IMessage[];
}

const NewConversationModal: FC<NewConversationModalProps> = ({
  open,
  setOpen,
  onSave,
  editConversation = [],
}) => {
  const [conversation, setConversation] =
    useState<IMessage[]>(editConversation);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) setConversation(editConversation);
  }, [open, editConversation]);

  const handleSave = async () => {
    setLoading(true);
    await onSave(conversation);
    setOpen(false);
    setLoading(false);
  };

  const addMessage = () => {
    const lastMessage = conversation[conversation.length - 1];
    setConversation([
      ...conversation,
      {
        idx: conversation.length,
        from: lastMessage?.from === "me" ? "prospect" : "me",
        text: "",
      },
    ]);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-conversation-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography id="add-conversation-modal-title" variant="h6">
          Add an Example Conversation
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {conversation.map((c: IMessage) => (
            <Box
              key={c.idx}
              display="flex"
              justifyContent={c.from === "me" ? "end" : "start"}
            >
              <Box
                bgcolor={c.from === "me" ? "#5457FC33" : "#1C602133"}
                color="white"
                p={1}
                borderRadius={5}
                width="70%"
              >
                <Box
                  color="black"
                  display="flex"
                  justifyContent={c.from === "me" ? "flex-end" : "flex-start"}
                >
                  {c.from === "me" ? "You" : "Lead"}
                </Box>
                <TextField
                  id={`message-${c.idx}`}
                  variant="standard"
                  multiline
                  fullWidth
                  value={c.text}
                  onChange={(e) =>
                    setConversation(
                      conversation.map((conv) =>
                        conv.idx === c.idx
                          ? { ...conv, text: e.target.value }
                          : conv,
                      ),
                    )
                  }
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="start">
          <IconButton
            color="success"
            onClick={addMessage}
            disabled={conversation.length === 6}
          >
            <Add />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() =>
              setConversation(
                conversation.map((c: IMessage) => ({
                  ...c,
                  from: c.from === "me" ? "prospect" : "me",
                })),
              )
            }
            disabled={conversation.length === 6}
          >
            <SwapHoriz />
          </IconButton>
          <IconButton
            color="warning"
            onClick={() =>
              setConversation(conversation.slice(0, conversation.length - 1))
            }
            disabled={conversation.length === 0}
          >
            <Delete />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="end">
          <Button
            color="primary"
            onClick={handleSave}
            disabled={
              conversation.some((c) => !c.text) ||
              conversation.length < 2 ||
              loading
            }
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewConversationModal;
