import { useState, Dispatch, SetStateAction } from "react";
import { callAPI } from "../api";
import {
  Box,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Lead } from "./types";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ChatbotEnableDisableProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  lead: Lead;
  chatBotEnabled: boolean;
  setChatBotEnabled: Dispatch<SetStateAction<boolean>>;
}

const ChatbotEnableDisable = ({
  open,
  setOpen,
  lead,
  chatBotEnabled,
  setChatBotEnabled,
}: ChatbotEnableDisableProps) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const toggleChatBot = async () => {
    setLoading(true);
    const res = await callAPI("PUT", "/lead", {
      leadPID: lead.pid,
      botStatus: !chatBotEnabled,
    });

    setLoading(false);

    if (res) {
      setMessage(
        !chatBotEnabled
          ? "Chatbot enabled for this lead"
          : "Chatbot disabled for this lead",
      );
      setChatBotEnabled(!chatBotEnabled); // Update parent state
      setTimeout(() => {
        setOpen(false);
        setMessage(null);
      }, 2000);
    } else {
      console.error("Failed to toggle chatbot");
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={modalStyle}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            {message ? (
              <Typography>{message}</Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {chatBotEnabled
                    ? `Disable Chatbot for ${lead.firstName} ${lead.lastName}`
                    : `Enable Chatbot for ${lead.firstName} ${lead.lastName}`}
                </Typography>
                <Button onClick={toggleChatBot} variant="outlined">
                  {chatBotEnabled ? "Disable Chatbot" : "Enable Chatbot"}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ChatbotEnableDisable;
