import React, { useState } from "react";
import {
  ListItem,
  ListItemButton,
  Checkbox,
  ListItemText,
  Link,
  Tooltip,
  Modal,
  Box,
  Button,
} from "@mui/material";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const iframeStyle = {
  width: "75vw",
  height: "75vh",
  border: "none",
  borderRadius: "8px",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",
  width: "75vw",
};

const StepCheckbox = ({
  checked,
  onToggle,
  text,
  url,
  tooltip,
  videoUrl,
  steps,
}) => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(
    steps.findIndex((step) => step.videoUrl === videoUrl),
  );

  const handleOpen = (e) => {
    e.stopPropagation(); // Prevents checkbox toggle when clicking the text
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(steps.findIndex((step) => step.videoUrl === videoUrl));
  };

  const handleNext = () => {
    if (currentIndex < steps.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={onToggle}>
          <Checkbox
            edge="start"
            checked={checked}
            tabIndex={-1}
            disableRipple
          />
          <Tooltip title={tooltip} arrow>
            <ListItemText
              primary={
                <Link href={url} underline="hover" onClick={handleOpen}>
                  {videoUrl && (
                    <VideoCameraBackIcon
                      sx={{
                        mr: 1,
                        verticalAlign: "middle",
                        position: "relative",
                        top: "0px",
                      }}
                    />
                  )}
                  {text}
                </Link>
              }
            />
          </Tooltip>
        </ListItemButton>
      </ListItem>

      <Modal open={open} onClose={handleClose} sx={modalStyle}>
        <Box>
          <iframe
            src={steps[currentIndex].videoUrl}
            style={iframeStyle}
            allow="autoplay; fullscreen"
            allowFullScreen
            title={steps[currentIndex].text}
          ></iframe>
          <Box sx={buttonContainerStyle}>
            <Button
              variant="contained"
              onClick={handlePrevious}
              disabled={currentIndex === 0}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={currentIndex === steps.length - 2}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default StepCheckbox;
