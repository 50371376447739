import { useState } from "react";
import { NodeProps, Position } from "reactflow";
import { Box } from "@mui/material";
import { NodeData } from "../types";
import { NodeHeader } from "../NodeHeader";
import { NodeSettings } from "../NodeSettings";
import { HandleWithTooltip } from "../components";
import styles from "../Sequences.module.scss";

export const ResearchNode = (props: NodeProps<NodeData>) => {
  const { data } = props;
  const [settingsOpen, setSettingsOpen] = useState(false);

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <Box
      className={styles.nodeOk}
      border={props.selected ? "2px solid #000000" : null}
    >
      <NodeSettings open={settingsOpen} onClose={toggleSettings} {...props} />
      <NodeHeader openSettings={toggleSettings} {...data} />
      <HandleWithTooltip
        tooltip="Research Complete"
        type="source"
        position={Position.Right}
        id="positive"
        isConnectable={true}
        className={styles.sourceHandleBad}
      />
    </Box>
  );
};
