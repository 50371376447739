import { useMemo, useState, useEffect, forwardRef } from "react";
import { useUser } from "../../contexts/UserContext";
import { callAPI } from "../../api";
import { ShopModal } from "../ShopModal";
import {
  IconButton,
  Tooltip,
  Box,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import {
  Add,
  UploadFile,
  Refresh,
  HourglassBottom,
  LinkedIn,
  Save,
  RestartAlt,
  Settings,
} from "@mui/icons-material";
import styles from "./SidePanel.module.scss";

interface onClickProps {
  onClick: () => void;
}

export const AddButton = (props: onClickProps) => {
  return (
    <IconButton
      aria-label="Add"
      onClick={props.onClick}
      className={styles.addButton}
    >
      <Add />
    </IconButton>
  );
};

export const FileButton = (props: onClickProps) => {
  return (
    <IconButton
      aria-label="Upload"
      onClick={props.onClick}
      className={styles.fileButton}
    >
      <UploadFile />
    </IconButton>
  );
};

export const SaveButton = (props: onClickProps) => {
  return (
    <IconButton
      aria-label="Save"
      onClick={props.onClick}
      className={styles.addButton}
    >
      <Save />
    </IconButton>
  );
};

export const ResetButton = (props: onClickProps) => {
  return (
    <IconButton
      aria-label="Reset"
      onClick={props.onClick}
      className={styles.fileButton}
    >
      <RestartAlt />
    </IconButton>
  );
};

export const SettingsButton = (props: onClickProps) => {
  return (
    <IconButton
      aria-label="Settings"
      onClick={props.onClick}
      className={styles.fileButton}
    >
      <Settings />
    </IconButton>
  );
};

export const RefreshButton = () => {
  const { setUserData } = useUser();
  const [loading, setLoading] = useState(false);
  const refreshData = async () => {
    try {
      setLoading(true);
      const data = await callAPI("GET", "user");
      setUserData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <IconButton
      aria-label="Refresh"
      onClick={refreshData}
      disabled={loading}
      className={styles.refreshButton}
    >
      {loading ? <HourglassBottom /> : <Refresh />}
    </IconButton>
  );
};

export const LinkedInButton = () => {
  const { setUserData, setActivePage } = useUser();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkStatus = async () => {
    setLoading(true);
    const extSession = await callAPI("GET", "ext/session_status");
    setUserData((prev) => ({ ...prev, extensionState: extSession }));
    setStatus(extSession);
    setLoading(false);
  };

  useEffect(() => {
    checkStatus();
  }, []);

  if (!status) return null;
  if (status.version < 0.7)
    return (
      <Modal open={true}>
        <Box
          display="flex"
          justifyContent="center"
          p={2}
          textAlign="center"
          flexDirection="column"
          alignItems={"center"}
        >
          <Typography variant="subtitle1" color="error">
            You are running an older version of the extension. Please update
            your Bella extension from your profile page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActivePage("profile")}
          >
            Return to My Profile
          </Button>
        </Box>
      </Modal>
    );
  return (
    <Tooltip
      title={
        <>
          {status?.linkedinSalesNav
            ? "LinkedIn SalesNav: connected"
            : "LinkedIn SalesNav: not connected"}
          <br />
          {status?.linkedin ? "LinkedIn: connected" : "LinkedIn: not connected"}
        </>
      }
    >
      <IconButton
        className={styles.linkedInButton}
        color={status?.linkedinSalesNav ? "success" : "error"}
        onClick={checkStatus}
      >
        {loading ? <HourglassBottom /> : <LinkedIn />}
      </IconButton>
    </Tooltip>
  );
};

export const LeadCreditsButton = () => {
  const { userData } = useUser();
  const [showShop, setShowShop] = useState(false);

  const totalLeads = userData.totalLeads ?? 0;
  const leadCredits = userData.leadCredits ?? 0;
  const monthlyCredits = userData.usedMonthlyCredits ?? 0;

  const remaining_monthly_creds = leadCredits - monthlyCredits;

  const round = (count) => {
    if (count < 1000) return count.toString();
    else return (count / 1000).toString().slice(0, 2) + "K";
  };
  const color = useMemo(() => {
    if (totalLeads / leadCredits < 0.8) return "success";
    else if (totalLeads / leadCredits < 1) return "warning";
    else return "error";
  }, [totalLeads, leadCredits]);
  return showShop ? (
    <ShopModal showModal={showShop} setShowModal={setShowShop} />
  ) : (
    <Tooltip
      title={`Credits remaining this month: ${remaining_monthly_creds}.`}
    >
      <IconButton
        aria-label="LinkedInRefresh"
        onClick={() => setShowShop(true)}
        className={styles.linkedInButton}
        color={color}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: "0.8rem" }}>
            {round(remaining_monthly_creds)}{" "}
          </span>
          <span style={{ fontSize: "0.5rem" }}>Credits</span>
          {/* <span style={{ fontSize: "0.6rem", textAlign: "right" }}>
            /{round(leadCredits)}
          </span> */}
        </div>
      </IconButton>
    </Tooltip>
  );
};

interface ISideButtonsProps {
  addButtonOnClick?: () => void;
  settingsButtonOnClick?: () => void;
  fileButtonOnClick?: () => void;
  refreshButton?: boolean;
  linkedInButton?: boolean;
  saveButtonOnClick?: () => void;
  resetButtonOnClick?: () => void;
}

export const SidePanel = forwardRef((props: ISideButtonsProps, ref) => {
  return (
    <Box className={styles.container} ref={ref}>
      {props.refreshButton ? <RefreshButton /> : null}
      {props.addButtonOnClick ? (
        <AddButton onClick={props.addButtonOnClick} />
      ) : null}
      {props.settingsButtonOnClick ? (
        <SettingsButton onClick={props.settingsButtonOnClick} />
      ) : null}
      {props.fileButtonOnClick ? (
        <FileButton onClick={props.fileButtonOnClick} />
      ) : null}
      {props.linkedInButton ? <LinkedInButton /> : null}
      {props.saveButtonOnClick ? (
        <SaveButton onClick={props.saveButtonOnClick} />
      ) : null}
      {props.resetButtonOnClick ? (
        <ResetButton onClick={props.resetButtonOnClick} />
      ) : null}
      <LeadCreditsButton />
    </Box>
  );
});
