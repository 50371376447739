import { useState, useEffect } from "react";
import { callAPI } from "../../../api";

export const useChatbotConfig = () => {
  const [chatbotConfig, setChatbotConfig] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await callAPI("GET", "chatbot_config");
      setChatbotConfig(res);
    };
    fetchData();
  }, []);

  return chatbotConfig;
};
