import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { callAPI } from "../../api";
import { HubSpotLogoIcon, SalesforceLogoIcon } from "../Icons";
import { AuthButton } from "./AuthButton";
import {
  Typography,
  ButtonGroup,
  Box,
  CircularProgress,
  Radio,
} from "@mui/material";

export const CRMIntegration: React.FC = () => {
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState<"hubspot" | "salesforce">(
    userData.crmProvider,
  );
  const [crmUser, setCrmUser] = useState(null);

  const changeProvider = (newProvider: "hubspot" | "salesforce") => {
    setProvider(newProvider);
  };

  useEffect(() => {
    const fetchCRMProfile = async () => {
      if (userData.crmProvider) {
        setLoading(true);
        const res = await callAPI("GET", "crm/profile");
        setCrmUser(res.user);
        setLoading(false);
      }
    };

    fetchCRMProfile();
  }, [userData.crmProvider]);

  const renderCRMInfo = (
    currentProvider: "hubspot" | "salesforce",
    IconComponent: React.ElementType,
  ) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Radio
        checked={provider === currentProvider}
        onChange={() => changeProvider(currentProvider)}
        value={currentProvider}
        name={`${currentProvider.toUpperCase()}-radio`}
        inputProps={{ "aria-label": currentProvider.toUpperCase() }}
      />
      <IconComponent style={{ width: "4rem" }} />
      {userData.crmProvider === currentProvider && crmUser ? (
        <Typography variant="body2">
          You are authorized with{" "}
          {currentProvider.charAt(0).toUpperCase() + currentProvider.slice(1)}{" "}
          as <b>{crmUser}</b>
        </Typography>
      ) : (
        <Typography variant="body2">
          You are not authorized with{" "}
          {currentProvider.charAt(0).toUpperCase() + currentProvider.slice(1)}.
        </Typography>
      )}
    </Box>
  );

  return (
    <Box>
      {renderCRMInfo("hubspot", HubSpotLogoIcon)}
      {renderCRMInfo("salesforce", SalesforceLogoIcon)}
      <br />
      {loading ? (
        <CircularProgress />
      ) : (
        <ButtonGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={loading || !provider}
        >
          <AuthButton
            provider={provider}
            authorizedWith={userData.crmProvider}
          />
        </ButtonGroup>
      )}
    </Box>
  );
};
